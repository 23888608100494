import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
} from '@angular/core';
import { UserDisplayDto } from '@core/models/user.model';
import { UsersService } from '@shared/services/users/users.service';
import { ToastrService } from 'ngx-toastr';
import { Emoji } from '@ctrl/ngx-emoji-mart/ngx-emoji/emoji.component';
import { DarkModeService } from '@shared/services/dark-mode.service';
import { ProfileImageComponent } from '@shared/components/profile-image/profile-image.component';
import { UserNameComponent } from '@shared/components/user-name/user-name.component';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-follower-preview',
  standalone: true,
  templateUrl: './follower-preview.component.html',
  styleUrls: ['./follower-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ProfileImageComponent, UserNameComponent, NgClass, NgIf, AsyncPipe, MatIconModule],
})
export class FollowerPreviewComponent {
  @Input() user: UserDisplayDto;
  @Input() currentUser: string;
  @Input() isFollowing: boolean;
  @Input() reaction = '';
  @Input() skinTone: Emoji['skin'] = 1;
  @Output() follow = new EventEmitter<string>();
  lockButton = false;
  darkMode$ = inject(DarkModeService).isDarkMode;

  constructor(
    private readonly users: UsersService,
    private readonly cdr: ChangeDetectorRef,
    private readonly toastService: ToastrService
  ) {}

  public followUser(): void {
    this.lockButton = true;
    this.users.follow(this.user.userId).subscribe(() => {
      this.isFollowing = true;
      this.lockButton = false;
      this.toastService.success('User followed successfully!');

      this.follow.emit(this.user.userId);
      this.cdr.detectChanges();
    });
  }

  public unfollowUser(): void {
    this.lockButton = true;
    this.users.unfollow(this.user.userId).subscribe(() => {
      this.isFollowing = false;
      this.lockButton = false;
      this.toastService.success('User unfollowed successfully!');

      this.follow.emit(this.user.userId);
      this.cdr.detectChanges();
    });
  }
}
