import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CommentReaction } from '@core/models/comment-reaction.model';
import { Comment, CommentAdd, CommentReply, EditComment, IAddReplyComment } from '@core/models/comment.model';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { BASE_URL } from '@core/constants/constants';
import { FeedElementType } from '@core/models/feed-element.model';
import { PagedResponse } from '@core/models/paged-response.model';
import { buildUrl } from '@core/utils/buildUrl';
import { bodyFormData } from '@core/utils/formData';
import { RequestActions } from '@core/enums/request-keys';
import { SignalrBaseService } from '@core/services/api/signalr-base/signalr-base.service';
import { CustomHttpParams } from '@core/services/helpers/handle-loading/handle-loading.service';

@Injectable({
  providedIn: 'root',
})
export class CommentsService extends SignalrBaseService {
  constructor(@Inject(BASE_URL) private readonly baseUrl: string, private readonly http: HttpClient) {
    super(environment.wssHub.comment);
  }

  joinSection(id: string): void {
    this.invoke('JoinCommentSection', id);
  }

  getAllPaged(
    id: string,
    feedElementType: FeedElementType,
    elementsCount = 1,
    requestedElements: number
  ): Observable<PagedResponse<Comment>> {
    const url = buildUrl(`${this.baseUrl}/feed/${id}/comments`, {
      elementsCount,
      requestedElements,
      feedElementType,
    });

    return this.http.get<PagedResponse<Comment>>(url, {
      params: new CustomHttpParams({
        action: RequestActions.GET_COMMENTS,
        data: { id, elementsCount, feedElementType },
      }),
    });
  }

  getAllRepliesPaged(
    id: string,
    elementsCount = 1,
    requestedElements: number
  ): Observable<PagedResponse<CommentReply>> {
    const url = buildUrl(`${this.baseUrl}/replies/byComment/${id}`, { elementsCount, requestedElements });

    return this.http.get<PagedResponse<CommentReply>>(url);
  }

  create(body: CommentAdd): Observable<Comment> {
    const formData = bodyFormData(body);

    return this.http.post<Comment>(`${this.baseUrl}/comments`, formData);
  }

  onCreate(): Observable<Comment> {
    return this.on<Comment>('CreateComment');
  }

  update(body: EditComment): Observable<Comment> {
    const formData = bodyFormData(body);

    return this.http.patch<Comment>(`${this.baseUrl}/comments/${body.id}`, formData);
  }

  onUpdate(): Observable<Comment> {
    return this.on<Comment>('EditComment');
  }

  delete(id: string): Observable<Comment> {
    return this.http.delete<Comment>(`${this.baseUrl}/comments/${id}`);
  }

  onDelete(): Observable<string[]> {
    return this.on<string[]>('DeleteComment', true);
  }

  pin(id: string): Observable<Comment> {
    return this.http.post<Comment>(`${this.baseUrl}/comments/${id}/pin`, {});
  }

  unpin(id: string): Observable<Comment> {
    return this.http.delete<Comment>(`${this.baseUrl}/comments/${id}/pin`);
  }

  createReply(body: IAddReplyComment): Observable<CommentReply> {
    const formData = bodyFormData(body);

    return this.http.post<CommentReply>(`${this.baseUrl}/replies`, formData);
  }

  onCreateReply(): Observable<CommentReply> {
    return this.on<CommentReply>('CreateReply');
  }

  updateReply(body: EditComment): Observable<Comment> {
    const formData = bodyFormData(body);

    return this.http.patch<Comment>(`${this.baseUrl}/replies/${body.id}`, formData);
  }

  onUpdateReply(): Observable<CommentReply> {
    return this.on<CommentReply>('EditReply');
  }

  deleteReply(id: string): Observable<CommentReply> {
    return this.http.delete<CommentReply>(`${this.baseUrl}/replies/${id}`);
  }

  onDeleteReply(): Observable<{ commentId: string; id: string }> {
    return this.on<{ commentId: string; id: string }>('DeleteReply');
  }

  addReaction(
    controller: 'replies' | 'comments',
    id: string,
    reaction: string,
    skinTone = 1
  ): Observable<CommentReaction> {
    return this.http.post<CommentReaction>(`${this.baseUrl}/${controller}/${id}/reactions`, {
      reaction,
      skinTone,
    });
  }

  deleteReaction(controller: 'replies' | 'comments', id: string): Observable<string> {
    return this.http.delete<string>(`${this.baseUrl}/${controller}/${id}/reactions`);
  }
}
