import { Injectable } from '@angular/core';
import { FeedElementBaseService } from '@core/services/api/feed-element-base/feed-element-base.service';
import { Post } from '@core/models/post.model';

@Injectable({
  providedIn: 'root',
})
export class BookmarksService extends FeedElementBaseService<Post> {
  constructor() {
    super('bookmarks');
  }
}
