<mat-card [ngClass]="{ collapse: isCollapsed }" class="suggestion-card">
  <mat-card-header (click)="pfp.redirectToProfile()" class="main">
    <app-profile-image
      #pfp
      imageSize="preview"
      [userId]="suggestion.recommendedUser.userId"
      [name]="suggestion.recommendedUser.displayName"
      [photo]="suggestion.recommendedUser.profilePhotoThumbnail!"
    ></app-profile-image>
    <div class="main__details">
      <span class="main__details--name">{{ suggestion.recommendedUser.displayName | simpleTruncate : 20 }}</span>
      <span class="main__details--job">{{ suggestion.recommendedUser.jobTitle }}</span>
      <div
        *ngIf="suggestion.mutuals.length > 0"
        class="main__details--following link"
        (click)="openMutualDialog(); $event.stopPropagation()"
      >
        {{ suggestion.mutualCount }} mutual following{{ suggestion.mutualCount > 1 ? 's' : '' }}</div
      >
    </div>
  </mat-card-header>

  <div class="actions">
    <button
      [appButtonLoader]="isLoading"
      (click)="followUser(); $event.stopPropagation()"
      mat-flat-button
      class="actions__follow"
    >
      <mat-icon>add</mat-icon> Follow</button
    >

    <button (click)="collapse(); $event.stopPropagation()" class="actions__remove">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</mat-card>
