import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BASE_URL } from '@core/constants/constants';
import { Observable } from 'rxjs';
import { Params } from '@angular/router';
import { PagedResponse } from '@core/models/paged-response.model';
import { buildUrl } from '@core/utils/buildUrl';
import { Feedback } from '@core/models/feedback.model';

@Injectable({
  providedIn: 'root',
})
export class FeedbacksService {
  constructor(private readonly http: HttpClient, @Inject(BASE_URL) private readonly baseUrl: string) {
    this.baseUrl += '/feedback';
  }

  public getAllPaged(
    elementsCount: number,
    requestedElements: number,
    params?: Params
  ): Observable<PagedResponse<Feedback>> {
    const url = buildUrl(`${this.baseUrl}`, {
      elementsCount,
      requestedElements,
      params,
    });
    return this.http.get<PagedResponse<Feedback>>(url);
  }

  public create(text: string): Observable<string> {
    return this.http.post<string>(`${this.baseUrl}`, { text });
  }
}
