<div
  *ngIf="post.sharedPost && user$ | async as currentUser"
  class="shared-post"
  [ngClass]="{ 'dark-mode': (darkMode$ | async) }"
>
  <header>
    <app-profile-image
      [photo]="
        currentUser.userId === post.sharedPost.profile.userId
          ? currentUser.profilePhotoThumbnail!
          : post.sharedPost.profile.profilePhotoThumbnail!
      "
      [name]="
        currentUser.userId === post.sharedPost.profile.userId
          ? currentUser.displayName
          : post.sharedPost.profile.displayName
      "
      [userId]="
        currentUser.userId === post.sharedPost.profile.userId ? currentUser.userId : post.sharedPost.profile.userId
      "
    ></app-profile-image>

    <div class="shared-post__details">
      <div #author class="shared-post__author">
        <app-user-name
          (userIdEmitter)="onUserNameMouseover($event)"
          [userId]="post.sharedPost.profile.userId"
          [userName]="post.sharedPost.profile.displayName"
          [isContentCreator]="post.sharedPost.profile.isContentCreator!"
        ></app-user-name>

        <ng-container *ngIf="post.community">
          <span> posted in </span>

          <app-community-name [community]="post.community"></app-community-name>
        </ng-container>
      </div>

      <div class="shared-post__date">
        <mat-icon class="icon-1rem">schedule</mat-icon>
        <span [matTooltip]="sharedPostDate">
          {{ post.sharedPost.creationDate | timeAgo }}
        </span>
        <span *ngIf="isPostEdited">
          <mat-icon class="mat-icon-fill dot">fiber_manual_record</mat-icon>
          Edited</span
        >
      </div>
    </div>
  </header>

  <div class="shared-post__content">
    <p
      *ngIf="post.sharedPost.text"
      (click)="redirect()"
      [innerHTML]="post.sharedPost.text | truncateText : 200"
      class="shared-post__text"
    ></p>

    <app-gallery-preview *ngIf="post.sharedPost.images.length" [images]="post.sharedPost.images"></app-gallery-preview>

    <ng-container *ngIf="post.sharedPost.document?.fileName">
      <app-attached-files [readonlyDocs]="[post.sharedPost.document]"></app-attached-files>
    </ng-container>
  </div>
</div>

<div
  *ngIf="post.sharedArticle && user$ | async as currentUser"
  class="shared-post"
  [ngClass]="{ 'dark-mode': (darkMode$ | async) }"
>
  <header>
    <app-profile-image
      [photo]="
        currentUser.userId === post.sharedArticle.user.userId
          ? currentUser.profilePhotoThumbnail!
          : post.sharedArticle.user.profilePhotoThumbnail!
      "
      [name]="
        currentUser.userId === post.sharedArticle.user.userId
          ? currentUser.displayName
          : post.sharedArticle.user.displayName
      "
      [userId]="
        currentUser.userId === post.sharedArticle.user.userId ? currentUser.userId : post.sharedArticle.user.userId
      "
    ></app-profile-image>

    <div class="shared-post__details">
      <div #author class="shared-post__author">
        <app-user-name
          (userIdEmitter)="onUserNameMouseover($event)"
          [userId]="post.sharedArticle.user.userId"
          [userName]="post.sharedArticle.user.displayName"
          [isContentCreator]="post.sharedArticle.user.isContentCreator!"
        ></app-user-name>

        <ng-container *ngIf="post.community">
          <span> posted in </span>

          <app-community-name [community]="post.community"></app-community-name>
        </ng-container>
      </div>

      <div class="shared-post__date">
        <mat-icon class="icon-1rem">schedule</mat-icon>
        <span [matTooltip]="sharedArticleDate">
          {{ post.sharedArticle.creationDate | timeAgo }}
        </span>
        <span *ngIf="isArticleEdited">
          <mat-icon class="mat-icon-fill dot">fiber_manual_record</mat-icon>
          Edited</span
        >
      </div>
    </div>
  </header>

  <div (click)="redirect()" class="article-wrapper">
    <p
      class="article__article-content--text article__article-content--title"
      [innerHTML]="post.sharedArticle.title"
    ></p>
    <p
      class="article__article-content--text article__article-content--preview"
      [innerHTML]="post.sharedArticle.text | truncateText : 200"
    ></p>
  </div>

  <div *ngIf="post.sharedArticle?.documents?.length">
    <app-attached-files [readonlyDocs]="post.sharedArticle.documents"></app-attached-files>
  </div>
</div>

<ng-template #userInfoPreviewTemplate>
  <app-user-info-preview [userId]="hoveredUserId"></app-user-info-preview>
</ng-template>
