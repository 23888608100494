import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AsyncPipe, NgClass, NgForOf, NgIf, NgTemplateOutlet, SlicePipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Document } from '@core/models/document.model';
import { MatChipsModule } from '@angular/material/chips';
import { DarkModeService } from '@shared/services/dark-mode.service';
import { DestroyBaseComponent } from '@core/components/destroy-base/destroy-base.component';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-attached-files',
  standalone: true,
  templateUrl: './attached-files.component.html',
  styleUrls: ['./attached-files.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgForOf,
    NgClass,
    MatButtonModule,
    MatIconModule,
    SlicePipe,
    NgIf,
    NgTemplateOutlet,
    MatChipsModule,
    AsyncPipe,
  ],
})
export class AttachedFilesComponent extends DestroyBaseComponent implements OnChanges {
  @Input() control: FormControl;
  @Input() readonlyDocs: Document[];

  darkMode$ = inject(DarkModeService).isDarkMode;
  private readonly cdr = inject(ChangeDetectorRef);

  ngOnChanges(): void {
    this.control?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.cdr.detectChanges());
  }

  public removeFile(index: number): void {
    const filter = (this.control.value as []).filter((_, i) => i !== index);

    this.control.setValue(filter);
  }
}
