import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { UtilitiesService } from '@core/services/helpers/utilities/utilities.service';

@Directive({
  selector: '[clickOutside]',
  standalone: true,
})
export class ClickOutsideDirective implements OnInit, OnDestroy {
  @Output() clickOutside = new EventEmitter<void>();

  private _subscription: Subscription;
  constructor(private readonly elementRef: ElementRef, private readonly utilities: UtilitiesService) {}

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  ngOnInit(): void {
    this._subscription = this.utilities.documentClickedTarget$.subscribe((target) =>
      this._documentClickListener(target)
    );
  }

  private _documentClickListener(target: HTMLElement): void {
    if (!target) {
      return;
    }

    if (document.body.contains(target) && !this.elementRef.nativeElement.contains(target)) {
      this.clickOutside.emit();
    }
  }
}
