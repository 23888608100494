import { createAction, props } from '@ngrx/store';
import { CreatePost, EditPost, Post, SharePublication } from '@core/models/post.model';
import { Publication, PublicationType } from '@core/models/publication.model';

export enum PostActionsEnum {
  RESET_POSTS = '[Post] Reset post',

  PATCH_POST = '[Post] Patch post',
  PATCH_POST_SUCCESS = '[Post] Patch post success',
  PATCH_POST_ERROR = '[Post] Patch post error',

  ADD_POST = '[Post] Add post',
  ADD_POST_SUCCESS = '[Post] Add post success',
  ADD_POST_ERROR = '[Post] Add post error',

  SHARE_PUBLICATION = '[Post] Share post',
  SHARE_PUBLICATION_SUCCESS = '[Post] Share post success',
  SHARE_PUBLICATION_ERROR = '[Post] Share post error',
}

export const resetPostsState = createAction(PostActionsEnum.RESET_POSTS);

export const patchPost = createAction(PostActionsEnum.PATCH_POST, props<EditPost>());
export const patchPostSuccess = createAction(PostActionsEnum.PATCH_POST_SUCCESS, props<Post>);
export const patchPostError = createAction(PostActionsEnum.PATCH_POST_ERROR, props<{ error: string }>());

export const addPost = createAction(PostActionsEnum.ADD_POST, props<CreatePost>());
export const addPostSuccess = createAction(PostActionsEnum.ADD_POST_SUCCESS, props<Post>);
export const addPostError = createAction(PostActionsEnum.ADD_POST_ERROR, props<{ error: string }>());

export const sharePublication = createAction(
  PostActionsEnum.SHARE_PUBLICATION,
  props<{ id: string; publication: SharePublication; publicationType: PublicationType }>()
);
export const sharePublicationSuccess = createAction(PostActionsEnum.SHARE_PUBLICATION_SUCCESS, props<Publication>);
export const sharePublicationError = createAction(PostActionsEnum.SHARE_PUBLICATION_ERROR, props<{ error: string }>());
