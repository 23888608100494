import { FlexibleConnectedPositionStrategy, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Injectable, TemplateRef, ViewContainerRef } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CreateOverlayService {
  private overlayRef: OverlayRef | null = null;

  constructor(private overlay: Overlay) {}

  public showEventPreview(
    event: any,
    target: HTMLElement,
    eventPreviewTemplate: TemplateRef<any>,
    viewContainerRef: ViewContainerRef
  ): void {
    this.closeEventPreview();

    const positionStrategy: FlexibleConnectedPositionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(target)
      .withLockedPosition(false)
      .withPush(false)
      .withPositions([
        // open below, centered
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
        },
        // open to the left, centered
        {
          originX: 'start',
          originY: 'center',
          overlayX: 'end',
          overlayY: 'center',
        },
        // open above, centered
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
        },
        // open above, to the left
        {
          originX: 'end',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
        },
        // open above, to the right
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'bottom',
        },
        // open to the right, centered
        {
          originX: 'end',
          originY: 'center',
          overlayX: 'start',
          overlayY: 'center',
        },
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close(),
      disposeOnNavigation: true,
      height: 'fit-content',
      width: '400px',
      backdropClass: 'overlay-transparent-backdrop',
      panelClass: 'overlay-settings',
    });

    const templatePortal: TemplatePortal = new TemplatePortal(eventPreviewTemplate, viewContainerRef, {
      $implicit: event,
    });

    this.overlayRef.attach(templatePortal);
  }

  public closeEventPreview(): void {
    if (this.overlayRef && this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }
  }
}
