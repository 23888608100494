import { CommunityPreview } from '@core/models/community.model';
import { PublicationUser } from '@core/models/user.model';
import { GroupedReactions, IImage, UserReactions } from '@core/models/common-publication-interfaces.model';
import { FeedElementIdentified } from '@core/models/feed-element.model';
import { Document } from '@core/models/document.model';
import { EditPost } from '@core/models/post.model';

export enum PublicationType {
  post = 'Post',
  article = 'Article',
}

export interface Publication extends FeedElementIdentified {
  creationDate: string;
  lastEditDate: string;
  commentsCount: number;
  isBookmarked: boolean;
  isPinnedByUser: boolean;
  isPinnedByCommunity: boolean;
  community: CommunityPreview;
  publicationType: PublicationType;
  user: PublicationUser;
  userReaction: UserReactions;
  reactions: GroupedReactions[];
  isPrivate: boolean;
}

export interface SharedPublication {
  text: string;
  creationDate: string;
  user: PublicationUser;
  id: string;
  images?: IImage[];
  documents?: Document[];
  articleTitle?: string;
  articlePreview?: string;
  publicationType: PublicationType;
  community: CommunityPreview;
  communityFeed?: boolean;
  isPostEdited?: boolean;
  isArticleEdited?: boolean;
}

export interface EditSharedPublication extends SharedPublication {
  post: EditPost;
  sharingText: string;
}
