import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnimateLoadingDirective } from '@shared/directives/animate-loading.directive';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-post-images-preview',
  standalone: true,
  templateUrl: './post-images-preview.component.html',
  styleUrls: ['./post-images-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, AnimateLoadingDirective, MatIconModule],
})
export class PostImagesPreviewComponent {
  @Input() uploadedImages: any;
  @Input() maxImages = 0;
  @Input() displayMaxImages = false;
  @Output() discardImage = new EventEmitter<number>();
}
