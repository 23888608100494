import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ImageDialogComponent } from '@shared/components/dialogs/image-dialog/image-dialog.component';
import { DestroyBaseComponent } from '@core/components/destroy-base/destroy-base.component';
import { Emoji } from '@ctrl/ngx-emoji-mart/ngx-emoji/emoji.component';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { UserInitialsPipe } from '@shared/pipes/user-initials.pipe';
import { DarkModeService } from '@shared/services/dark-mode.service';
import { LazyLoadImageModule } from 'ng-lazyload-image';

type ImageSize = 'small' | 'profile' | 'preview' | 'mentioning';

@Component({
  selector: 'app-profile-image',
  standalone: true,
  templateUrl: './profile-image.component.html',
  styleUrls: ['./profile-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, NgIf, EmojiModule, UserInitialsPipe, AsyncPipe, LazyLoadImageModule],
})
export class ProfileImageComponent extends DestroyBaseComponent {
  @Input() userId = '';
  @Input() name = '';
  @Input() photo = '';
  @Input() disableAllActions = false;
  @Input() redirectEnabled = true;
  @Input() contentClass = '';
  @Input() backgroundColor = '#45a29e';
  @Input() imageSize: ImageSize | null;
  @Input() reaction = '';
  @Input() showOnlineStatus = false;
  @Input() isOnline = false;
  @Input() skinTone: Emoji['skin'] = 1;

  darkMode$ = inject(DarkModeService).isDarkMode;

  constructor(private readonly router: Router, private readonly dialog: MatDialog) {
    super();
  }

  public redirectToProfile(): void {
    if (this.disableAllActions) return;

    if (this.redirectEnabled) void this.router.navigate(['profile', this.userId]);
    else if (this.photo)
      this.dialog.open(ImageDialogComponent, {
        height: '56vh',
        minWidth: '600px',
        width: 'fit-content',
        data: this.photo,
      });
  }
}
