import { createReducer, on } from '@ngrx/store';
import {
  deleteUserImageSuccess,
  getContentCreatorStatusSuccess,
  getUser,
  getUserSuccess,
  patchUserBackgroundImageSuccess,
  patchUserProfileImageSuccess,
  uploadUserImageSuccess,
  incrementUserStats,
  decrementUserStats,
} from './user.actions';
import { UserState } from './user.state';
import { Storage } from '@core/enums/storage';
import { ContentCreatorStatus } from '@core/enums/permission.enum';

export const initialState: UserState = {
  rolesWereLoaded: false,
  statusWasLoaded: false,
  status: ContentCreatorStatus.NONE,
  user: {
    displayName: '',
    userId: '',
    profilePhoto: '',
  },
  permissions: JSON.parse(<string>localStorage.getItem(Storage.permissions)) ?? [],
};

export const userReducer = createReducer(
  initialState,
  on(getUser, (state, data: any) => ({
    ...state,
    user: {
      displayName: data.displayName,
      userId: data.userId,
      appRoles: [],
    },
  })),
  on(getUserSuccess, (state, data: any) => ({
    ...state,
    rolesWereLoaded: true,
    user: data.payload,
    permissions: Array.from(new Set([...state.permissions, ...data.payload.appRoles])),
  })),
  on(getContentCreatorStatusSuccess, (state, data: any) => ({
    ...state,
    statusWasLoaded: true,
    status: data.payload.status,
    permissions: Array.from(new Set([...state.permissions, data.payload.status])),
  })),
  on(uploadUserImageSuccess, (state, data: any) => ({
    ...state,
    user: data.payload,
  })),
  on(patchUserProfileImageSuccess, (state, data: any) => ({
    ...state,
    user: data.payload,
  })),
  on(patchUserBackgroundImageSuccess, (state, data: any) => ({
    ...state,
    user: data.payload,
  })),
  on(deleteUserImageSuccess, (state, data: any) => ({
    ...state,
    user: data.payload,
  })),
  on(incrementUserStats, (state, data: any) => ({
    ...state,
    user: { ...state.user, [data.key]: (state.user as any)[data.key] + 1 },
  })),
  on(decrementUserStats, (state, data: any) => ({
    ...state,
    user: { ...state.user, [data.key]: (state.user as any)[data.key] - 1 },
  }))
);
