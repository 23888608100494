<app-profile-image
  *ngIf="!reaction; else displayImageWithReaction"
  [photo]="user.profilePhotoThumbnail!"
  [name]="user.displayName"
  [userId]="user.userId"
></app-profile-image>

<ng-template #displayImageWithReaction>
  <app-profile-image
    class="profile-image-reaction"
    [photo]="user.profilePhotoThumbnail!"
    [name]="user.displayName"
    [userId]="user.userId"
    [reaction]="reaction"
    [skinTone]="skinTone"
  ></app-profile-image>
</ng-template>

<app-user-name
  [userId]="user.userId"
  [userName]="user.displayName"
  [isContentCreator]="user.isContentCreator!"
></app-user-name>
<ng-container *ngIf="user.userId === currentUser; else follow"> </ng-container>

<ng-template #follow>
  <ng-container *ngIf="!isFollowing; else unfollow">
    <button
      [ngClass]="(darkMode$ | async) ? 'dark-mode-follow-btn' : ''"
      class="follow-btn"
      [disabled]="lockButton"
      (click)="followUser()"
    >
      <mat-icon>add</mat-icon>

      <span>Follow</span>
    </button>
  </ng-container>
</ng-template>

<ng-template #unfollow>
  <button
    [ngClass]="(darkMode$ | async) ? 'dark-mode-follow-btn' : ''"
    class="follow-btn"
    [disabled]="lockButton"
    (click)="unfollowUser()"
  >
    <mat-icon>remove</mat-icon>

    <span>Unfollow</span>
  </button>
</ng-template>
