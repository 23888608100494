<mat-card
  [ngClass]="{ 'dark-mode-create-post': darkMode$ | async }"
  (clickOutside)="closeWindow()"
  class="create-post-extended"
>
  <div [ngClass]="{ 'dark-mode-flex-end': darkMode$ | async }" class="flex-end">
    <h2
      [ngClass]="{ 'dark-mode-title': darkMode$ | async }"
      class="title"
      [innerHTML]="!this.data.id ? 'Create a post' : 'Edit post'"
    ></h2>

    <mat-icon (click)="closeWindow()" class="close-icon" mat-dialog-close="true">close</mat-icon>
  </div>

  <form [ngClass]="{ 'dark-mode-create-post__form': darkMode$ | async }" class="create-post-extended__form">
    <div
      [ngClass]="{ 'dark-mode-create-post__form--input-container': darkMode$ | async }"
      class="create-post-extended__form--input-container"
    >
      <div
        id="editor"
        (keydown)="(characterCounter)"
        (input)="(characterCounter)"
        class="create-post-extended__form--post-input"
        #editor
      ></div>

      <ng-container #urlContainer>
        <app-url-preview
          *ngIf="urlPreview !== null && isPreviewActive"
          (closePreview)="closeUrlPreview($event)"
          [url]="url"
          [dialogUrlPreview]="urlPreview"
        />
      </ng-container>

      <span [ngClass]="{ 'dark-mode-counter': darkMode$ | async }" class="counter first" *ngIf="characterCounter"
        >{{ characterCounter }} / {{ MAX_POST_SYMBOLS }}
      </span>
    </div>

    <div [ngClass]="{ 'dark-mode-insert': darkMode$ | async }" class="insert">
      <app-media-input [disabled]="imageCounter >= 5" (imageUpload)="uploadImage($event)"></app-media-input>
      <app-documents-input [control]="attachment"></app-documents-input>

      <div class="separator"></div>
      <app-emoji-picker (selectedEmoji)="addReaction($event)"></app-emoji-picker>
      <div class="separator" *ngIf="data.communityId"></div>

      <span *ngIf="data.communityId" class="options__option-description">
        <mat-button-toggle-group name="privacy" appearance="legacy" [(ngModel)]="isPrivateControl">
          <mat-button-toggle [value]="false" matTooltip="Public posts are visible to everyone">
            <mat-icon>public</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle [value]="true" matTooltip="Only members of this community can see a private post">
            <mat-icon>lock</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </span>
    </div>

    <app-attached-files [control]="attachment"> </app-attached-files>

    <div *ngIf="imageCounter > 0">
      <app-post-images-preview
        [uploadedImages]="uploadedImages | async"
        (discardImage)="discardImage($event)"
      ></app-post-images-preview>

      <span class="counter">{{ imageCounter }} / {{ maxImageCount }}</span>
    </div>
  </form>
  <mat-card-actions [ngClass]="{ 'dark-mode-actions': darkMode$ | async }" align="end" class="dialog-actions">
    <button mat-button [mat-dialog-close]="false">Cancel</button>
    <span [matTooltip]="postTooltip" [matTooltipDisabled]="isEdited && characterCounter <= MAX_POST_SYMBOLS">
      <button
        mat-stroked-button
        color="primary"
        [disabled]="!isEdited || characterCounter > MAX_POST_SYMBOLS"
        (click)="post()"
      >
        {{ !this.data.id ? 'Post' : 'Update' }}
      </button>
    </span>
  </mat-card-actions>
</mat-card>
