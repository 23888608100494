import { Inject, Injectable } from '@angular/core';
import { Suggestion } from '@core/models/suggestion.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BASE_URL } from '@core/constants/constants';

@Injectable({
  providedIn: 'root',
})
export class SuggestionsService {
  constructor(private readonly httpClient: HttpClient, @Inject(BASE_URL) private baseUrl: string) {
    this.baseUrl += 'user/suggestions';
  }

  getAll(): Observable<Suggestion[]> {
    return this.httpClient.get<Suggestion[]>(this.baseUrl);
  }

  delete(id: string): Observable<string> {
    return this.httpClient.delete<string>(`${this.baseUrl}/${id}`);
  }
}
