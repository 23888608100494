import { AUTO_STYLE, AnimationTriggerMetadata, animate, state, style, transition, trigger } from '@angular/animations';

export const collapse: AnimationTriggerMetadata = trigger('collapse', [
  state('false', style({ height: 0 })),
  state('true', style({ height: AUTO_STYLE })),
  transition('false <=> true', animate('125ms ease-in-out')),
]);

export const collapseFade: AnimationTriggerMetadata = trigger('collapseFade', [
  state('in', style({ height: AUTO_STYLE, opacity: 1 })),
  transition(':enter', [style({ height: 0 }), animate(125)]),

  transition(':leave', [
    animate(100, style({ height: '*', opacity: 0 })),
    animate(125, style({ height: '0', opacity: 0 })),
  ]),
]);

export const showLoading: AnimationTriggerMetadata = trigger('showLoading', [
  transition(':enter', [
    style({ transform: 'translateY(100%) translateX(-50%)', opacity: 0 }),
    animate(100, style({ transform: 'translateY(0) translateX(-50%)', opacity: 1 })),
  ]),
  transition(':leave', [
    style({ transform: 'translateY(0) translateX(-50%)', opacity: 1 }),
    animate(75, style({ transform: 'translateY(-10px) translateX(-50%)', opacity: 1 })),
    animate(100, style({ transform: 'translateY(100%) translateX(-50%)', opacity: 0 })),
  ]),
]);
