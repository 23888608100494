import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Notification, SeenNotification } from '@core/models/notification.model';
import { environment } from '@environments/environment';
import { BASE_URL } from '@core/constants/constants';
import { PagedResponse } from '@core/models/paged-response.model';
import { buildUrl } from '@core/utils/buildUrl';
import { SignalrBaseService } from '@core/services/api/signalr-base/signalr-base.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService extends SignalrBaseService {
  unreadCount$ = new BehaviorSubject<number>(0);

  constructor(@Inject(BASE_URL) private readonly baseUrl: string, private readonly http: HttpClient) {
    super(environment.wssHub.notification);
    this.baseUrl += '/notifications';
  }

  onReceived(): Observable<Notification> {
    return this.on<Notification>('SendNotification');
  }

  getAllPaged(
    elementsCount: number,
    requestedElements: number,
    notificationSeenStatus = false
  ): Observable<PagedResponse<Notification>> {
    const url = buildUrl(`${this.baseUrl}`, {
      elementsCount,
      requestedElements,
      notificationSeenStatus,
    });

    return this.http.get<PagedResponse<Notification>>(url);
  }

  markAsSeen(id: string): Observable<SeenNotification> {
    return this.http.patch<SeenNotification>(`${this.baseUrl}/${id}/mark-as-seen`, {});
  }

  markAllAsSeen(): Observable<void> {
    return this.http.patch<void>(`${this.baseUrl}/mark-as-seen`, undefined);
  }
}
