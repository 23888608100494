import { AnimationTriggerMetadata, animate, state, style, transition, trigger } from '@angular/animations';

export const menuAnimation: AnimationTriggerMetadata = trigger('menuAnimation', [
  state(
    'open',
    style({
      width: '340px',
    })
  ),
  state(
    'closed',
    style({
      width: '70px',
    })
  ),
  transition('closed <=> open', [animate('0.2s ease')]),
  transition('open <=> closed', [animate('0.2s ease')]),
]);

export const fadeInOut: AnimationTriggerMetadata = trigger('fadeInOut', [
  transition(':enter', [style({ opacity: 0 }), animate('0.3s', style({ opacity: 1 }))]),
  transition(':leave', [animate('0.3s', style({ opacity: 0 }))]),
]);

export const collapse: AnimationTriggerMetadata = trigger('collapse', [
  state(
    'open',
    style({
      height: '0',
      overflow: 'hidden',
    })
  ),
  state(
    'closed',
    style({
      height: '54px',
      overflow: 'hidden',
    })
  ),
  transition('open <=> closed', [animate('0.2s ease-in')]),
]);
