import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnInit,
  inject,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { IImage } from '@core/models/common-publication-interfaces.model';
import { DarkModeService } from '@shared/services/dark-mode.service';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

enum GalleryDirection {
  left = -1,
  right = 1,
}

@Component({
  selector: 'app-gallery-dialog',
  standalone: true,
  templateUrl: './gallery-dialog.component.html',
  styleUrls: ['./gallery-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatIconModule, MatButtonModule, NgOptimizedImage, MatDialogModule],
})
export class GalleryDialogComponent implements OnInit {
  darkMode$ = inject(DarkModeService).isDarkMode;
  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: { images: IImage[]; index: number },
    private readonly cdr: ChangeDetectorRef
  ) {}

  displayedImageIndex = 1;
  actualIndex = this.data.index;
  leftIndex: number;
  rightIndex: number;
  length = this.data.images.length;

  displayedImages: IImage[];

  isMoving = false;
  direction = GalleryDirection;

  ngOnInit(): void {
    this.displayImage();
  }

  @HostListener('document:keydown.arrowRight', ['this.direction.right'])
  @HostListener('document:keydown.arrowLeft', ['this.direction.left'])
  public displayNext(direction: GalleryDirection): void {
    if (this.isMoving) return;

    this.move(direction);
    setTimeout(() => this.displayImage(), 400);
  }

  public displayImage(): void {
    if (this.data.images.length === 1) {
      this.displayedImageIndex = 0;
      this.displayedImages = this.data.images;
      return;
    }

    this.stop();
    this.getSlice();
    this.cdr.detectChanges();
  }

  private stop(): void {
    this.isMoving = false;
    this.displayedImageIndex = 1;
  }

  private move(direction: GalleryDirection): void {
    this.displayedImageIndex += direction;
    this.actualIndex = direction === GalleryDirection.left ? this.leftIndex : this.rightIndex;
    this.isMoving = true;
  }

  private circularSlice(arr: IImage[], start: number, end: number): IImage[] {
    return end <= start ? arr.slice(start).concat(arr.slice(0, end + 1)) : arr.slice(start, end + 1);
  }

  private getSlice(): void {
    this.leftIndex = (this.actualIndex + this.length - 1) % this.length;
    this.rightIndex = (this.actualIndex + 1) % this.length;
    this.displayedImages = this.circularSlice(this.data.images, this.leftIndex, this.rightIndex);
  }
}
