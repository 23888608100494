import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, switchMap, takeUntil } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { PostsService } from '@shared/services/posts/posts.service';
import { editedPost } from '@store/posts-store/posts-store.selectors';
import { Store } from '@ngrx/store';
import { Post } from '@core/models/post.model';
import { DestroyBaseComponent } from '@core/components/destroy-base/destroy-base.component';
import { resetPostsState } from '@store/posts-store/posts-store.actions';
// TODO make component standalone and move to shared directory
@Component({
  selector: 'app-post-redirect',
  templateUrl: './post-redirect.component.html',
  styleUrls: ['./post-redirect.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostRedirectComponent extends DestroyBaseComponent implements OnInit, OnDestroy {
  post$ = new Subject<Post>();

  constructor(
    private readonly posts: PostsService,
    private readonly route: ActivatedRoute,
    private readonly store: Store
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.params
      .pipe(switchMap(({ postId }) => this.posts.getById(postId)))
      .subscribe((post) => this.post$.next(post));

    this.listenUpdatePost();
  }

  override ngOnDestroy(): void {
    this.store.dispatch(resetPostsState());
    super.ngOnDestroy();
  }

  private listenUpdatePost(): void {
    this.store
      .select(editedPost)
      .pipe(takeUntil(this.destroy$))
      .subscribe((post) => this.post$.next(post));
  }
}
