import { CommunityPreview } from '@core/models/community.model';
import { PublicationUser } from '@core/models/user.model';
import { GroupedReactions, UserReactions } from '@core/models/common-publication-interfaces.model';

export enum FeedElementType {
  post = 'Post',
  article = 'Article',
}

export interface FeedElement extends FeedElementIdentified {
  creationDate: string;
  lastEditDate: string;
  commentsCount: number;
  isBookmarked: boolean;
  isPinnedByUser: boolean;
  isPinnedByCommunity: boolean;
  community: CommunityPreview;
  publicationType?: FeedElementType;
  user: PublicationUser;
  userReaction: UserReactions;
  reactions: GroupedReactions[];
  userId: string;
  isPrivate: boolean;
}

export interface FeedElementIdentified extends FeedElementBase {
  id: string;
}

export interface FeedElementBase {
  text: string;
  communityId?: string;
}
