import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  forkJoin,
  from,
  iif,
  map,
  of,
  switchMap,
  throwError,
} from 'rxjs';
import { FileFormats } from '@core/enums/file-formats';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ToastrService } from 'ngx-toastr';
import { ImageCompressionConfig } from '@core/models/image-compression-config.model';
import { ConvertorService } from '@core/services/helpers/file-convertor/convertor.service';

@Injectable({
  providedIn: 'root',
})
export class MediaUtilitiesService {
  constructor(
    private readonly compressionService: NgxImageCompressService,
    private readonly toast: ToastrService,
    private readonly convertorService: ConvertorService
  ) {}

  public compressImages(media: (File | string)[], compressionConfig: ImageCompressionConfig): Observable<string[]> {
    const compressedImages = new BehaviorSubject<string[]>([]);

    if (media.every((item) => typeof item === 'string'))
      media = media.map((item) => this.convertorService.convertToFile(item as string));

    media.map((file) => {
      this.compressImage(file, compressionConfig)
        .pipe(
          map((image) => {
            compressedImages.next([...compressedImages.value, image]);
          }),
          catchError((error: unknown) => {
            this.toast.error((<Error>error).message);
            return of(error);
          })
        )
        .subscribe();
    });

    return compressedImages.asObservable();
  }

  private getImageDimensions(src: string): Observable<{ width: number; height: number }> {
    const img = new Image();
    const sub = new Subject<{ width: number; height: number }>();

    img.src = src;
    img.onload = (): void => {
      sub.next({ width: img.naturalWidth, height: img.naturalHeight });
      sub.complete();
    };

    return sub.asObservable();
  }

  private readFile(file: File): Observable<string> {
    const sub = new Subject<string>();
    const reader = new FileReader();

    reader.onload = (): void => {
      const content = reader.result as string;
      sub.next(content);
      sub.complete();
    };

    reader.readAsDataURL(file);
    return sub.asObservable();
  }

  public isFileValid(file: File, compressionConfig: ImageCompressionConfig): Observable<boolean> {
    if (!compressionConfig.validImageTypes.includes(file.type as FileFormats)) return of(false);

    return this.readFile(file).pipe(
      switchMap((src) =>
        this.getImageDimensions(src).pipe(
          switchMap(({ height, width }) =>
            of(height > compressionConfig.minPixels && width > compressionConfig.minPixels)
          )
        )
      )
    );
  }

  public compressImage(media: File | string, compressionConfig: ImageCompressionConfig): Observable<string> {
    if (typeof media === 'string') media = this.convertorService.convertToFile(media);

    return this.isFileValid(media, compressionConfig).pipe(
      switchMap((isValid) => {
        if (isValid)
          return forkJoin([
            from(this.compressionService.getOrientation(media as File)),
            this.readFile(media as File),
          ]).pipe(
            switchMap(([orientation, image]) =>
              iif(
                () => this.compressionService.byteCount(image) > compressionConfig.minKiloBytes,
                from(
                  this.compressionService.compressFile(
                    image,
                    orientation,
                    100,
                    100,
                    compressionConfig.maxCompressedPixels,
                    compressionConfig.maxCompressedPixels
                  )
                ),
                of(image)
              )
            )
          );
        return throwError(() => new Error('File is invalid!'));
      })
    );
  }
}
