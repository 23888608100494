import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, inject } from '@angular/core';
import { DestroyBaseComponent } from '@core/components/destroy-base/destroy-base.component';
import { CommentsService } from '@shared/services/comments/comments.service';
import { DarkModeService } from '@shared/services/dark-mode.service';
import { Subject, filter, iif, switchMap, takeUntil, timer } from 'rxjs';
import { CommonModule } from '@angular/common';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { PopupComponent } from '@shared/components/popup/popup.component';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { ShortNumberFormatPipe } from '@shared/pipes/short-number-format.pipe';
import { CommentReaction } from '@core/models/comment-reaction.model';
import { MatButtonModule } from '@angular/material/button';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-comment-reaction',
  standalone: true,
  templateUrl: './comment-reaction.component.html',
  styleUrls: ['./comment-reaction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, CdkOverlayOrigin, PopupComponent, EmojiModule, ShortNumberFormatPipe, MatButtonModule],
})
export class CommentReactionComponent extends DestroyBaseComponent implements OnInit {
  @Input() formGroup: FormGroup;

  predefinedEmojis = ['+1', 'heart', 'smile', 'astonished', 'slightly_frowning_face', 'angry'];
  popupOpen = false;
  omitEmptyDelete = true;
  cancelNotifier$ = new Subject<void>();
  protected readonly delayRequest = 250;
  protected readonly darkMode$ = inject(DarkModeService).isDarkMode;

  private increaseReactionCount = (existingReaction: CommentReaction, reaction?: string) => {
    const reactions = this.formGroup.controls['reactions'];
    const commentReaction = {
      reaction: reaction!,
      count: 1,
      skinTone: 1,
    };
    existingReaction ? existingReaction.count++ : reactions.setValue([...reactions.value, commentReaction]);
  };

  private decreaseReactionCount = (existingReaction: CommentReaction) => {
    const filter = (this.formGroup.value.reactions as []).filter((x) => x !== existingReaction);
    existingReaction.count > 1 ? existingReaction.count-- : this.formGroup.controls['reactions'].setValue(filter);
  };

  constructor(private readonly comments: CommentsService, private readonly cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.cdr.detectChanges());
  }

  addReaction(reaction: string, defaultReact = false): void {
    this.formGroup.value.userReaction?.reaction ? this.delete(reaction, defaultReact) : this.update(reaction);
  }

  private update(reaction: string): void {
    const { isReply, id } = this.formGroup.value;

    this.omitEmptyDelete = false;
    this.setUserReaction(this.increaseReactionCount, reaction);
    timer(this.delayRequest)
      .pipe(
        takeUntil(this.cancelNotifier$ || this.destroy$),
        switchMap(() =>
          iif(
            () => isReply,
            this.comments.addReaction('replies', id, reaction),
            this.comments.addReaction('comments', id, reaction)
          )
        )
      )
      .subscribe(() => (this.omitEmptyDelete = true));
  }

  private delete(reaction: string, defaultReact: boolean): void {
    const { isReply, id, userReaction } = this.formGroup.value;
    const updateReaction = userReaction?.reaction !== reaction;

    this.setUserReaction(this.decreaseReactionCount);
    timer(this.delayRequest)
      .pipe(
        takeUntil(this.cancelNotifier$ || this.destroy$),
        filter(() => this.omitEmptyDelete),
        switchMap(() =>
          iif(() => isReply, this.comments.deleteReaction('replies', id), this.comments.deleteReaction('comments', id))
        )
      )
      .subscribe(() => (this.omitEmptyDelete = false));
    if (updateReaction && !defaultReact) this.addReaction(reaction);
  }

  private setUserReaction(fn: (existingReaction: CommentReaction, reaction?: string) => void, reaction?: string): void {
    const existingReaction = (this.formGroup.value.reactions as CommentReaction[]).find(
      (x) => x.reaction === (reaction ?? this.formGroup.value.userReaction?.reaction)
    )!;

    fn(existingReaction, reaction);
    this.formGroup.controls['userReaction'].setValue(reaction ? { skinTone: 1, reaction } : null);
    this.cancelNotifier$.next();
  }
}
