import { CommunitiesState } from '@store/communities/communities.state';
import { createReducer, on } from '@ngrx/store';
import {
  changeUserRoleError,
  changeUserRoleSuccess,
  createCommunityError,
  createCommunityNameError,
  createCommunitySuccess,
  createCommunityWithSampleError,
  createCommunityWithSampleNameError,
  createCommunityWithSampleSuccess,
  deleteCommunity,
  deleteCommunityImageError,
  deleteCommunityImageSuccess,
  deleteCommunitySuccess,
  getCommunitiesError,
  getCommunitiesSuccess,
  getCommunityByIdError,
  getCommunityByIdSuccess,
  getCommunityMembersError,
  getCommunityMembersSuccess,
  joinCommunityError,
  joinCommunitySuccess,
  leaveCommunityError,
  leaveCommunitySuccess,
  patchCommunityBackgroundImageError,
  patchCommunityBackgroundImageSuccess,
  resetJoinedCommunitiesState,
  resetRemainingCommunitiesState,
  uploadCommunityImageError,
  uploadCommunityImageSuccess,
} from '@store/communities/communities.actions';

export const initialState: CommunitiesState = {
  error: null,
  entities: {
    hasMore: true,
    count: 0,
    items: [],
  },
  members: [],
  community: null,
  remainingCommunities: {
    hasMore: true,
    count: 0,
    items: [],
  },
  joinedCommunities: {
    hasMore: true,
    count: 0,
    items: [],
  },
  navigateFromJoined: false,
};

export const communitiesReducer = createReducer(
  initialState,
  on(getCommunitiesSuccess, (state, { communities, flag, navigateFromJoined, hasMore }) => {
    if (flag) {
      return {
        ...state,
        joinedCommunities: {
          ...state.joinedCommunities,
          items: [...state.joinedCommunities.items, ...communities],
          hasMore,
        },
        navigateFromJoined: navigateFromJoined,
      };
    } else {
      return {
        ...state,
        remainingCommunities: {
          ...state.remainingCommunities,
          items: [...state.remainingCommunities.items, ...communities],
          hasMore,
        },
        navigateFromJoined: navigateFromJoined,
      };
    }
  }),

  on(getCommunitiesError, (state, data: any) => ({
    ...state,
    error: data.error,
  })),
  on(resetRemainingCommunitiesState, (state) => ({
    ...state,
    entities: initialState.entities,
    remainingCommunities: {
      ...initialState.remainingCommunities,
    },
  })),
  on(resetJoinedCommunitiesState, (state) => ({
    ...state,
    entities: initialState.entities,
    joinedCommunities: {
      ...initialState.joinedCommunities,
    },
  })),
  on(
    getCommunityByIdSuccess,
    (state, data: any): CommunitiesState => ({
      ...state,
      community: data.payload,
    })
  ),
  on(
    getCommunityByIdError,
    (state, data: any): CommunitiesState => ({
      ...state,
      error: data.error,
    })
  ),
  on(
    uploadCommunityImageSuccess,
    deleteCommunityImageSuccess,
    patchCommunityBackgroundImageSuccess,
    (state, data: any): CommunitiesState => {
      const updatedEntities = state.entities.items.map((element) => {
        if (element.id === data.payload.id) {
          return {
            ...element,
            ...data.payload,
          };
        }
        return element;
      });

      return {
        ...state,
        entities: {
          ...state.entities,
          items: updatedEntities,
        },
        community: {
          ...data.payload,
          currentUserRole: state.community?.currentUserRole,
        },
      };
    }
  ),
  on(
    uploadCommunityImageError,
    deleteCommunityImageError,
    patchCommunityBackgroundImageError,
    (state, data: any): CommunitiesState => ({
      ...state,
      error: data.error,
    })
  ),
  on(joinCommunitySuccess, (state, data: any): CommunitiesState => {
    const updatedEntities = state.remainingCommunities.items.filter(
      (community) => community.id !== data.payload.community.id
    );
    return {
      ...state,
      remainingCommunities: {
        ...state.remainingCommunities,
        items: updatedEntities,
      },
      community: state.community?.id === data.payload.community.id ? data.payload.community : state.community,
      members: [...state.members, data.payload.user],
    };
  }),
  on(
    joinCommunityError,
    (state, data: any): CommunitiesState => ({
      ...state,
      error: data.error,
    })
  ),
  on(leaveCommunitySuccess, (state, data: any): CommunitiesState => {
    return {
      ...state,
      joinedCommunities: {
        ...state.joinedCommunities,
        items: state.joinedCommunities.items.filter((element) => element.id !== data.payload.community.id),
      },
      community: state.community?.id === data.payload.community.id ? data.payload.community : state.community,
      members: state.members.filter((member) => member.userId !== data.payload.user.userId),
    };
  }),
  on(
    leaveCommunityError,
    (state, data: any): CommunitiesState => ({
      ...state,
      error: data.error,
    })
  ),
  on(
    createCommunitySuccess,
    createCommunityWithSampleSuccess,
    (state, data: any): CommunitiesState => ({
      ...state,
      entities: {
        ...state.entities,
        items: [...state.entities.items, data.payload],
      },
      joinedCommunities: {
        ...state.joinedCommunities,
        items: [...state.joinedCommunities.items, data.payload],
      },
    })
  ),
  on(
    createCommunityError,
    createCommunityWithSampleError,
    createCommunityNameError,
    createCommunityWithSampleNameError,
    getCommunitiesError,
    changeUserRoleError,
    (state, data: any): CommunitiesState => ({
      ...state,
      error: data.error,
    })
  ),
  on(getCommunityMembersSuccess, (state, data: any): CommunitiesState => {
    return {
      ...state,
      members: data.payload,
    };
  }),
  on(
    getCommunityMembersError,
    (state, data: any): CommunitiesState => ({
      ...state,
      error: data.error,
    })
  ),
  on(
    deleteCommunity,
    (state): CommunitiesState => ({
      ...state,
    })
  ),
  on(
    deleteCommunitySuccess,
    (state, data: any): CommunitiesState => ({
      ...state,
      entities: {
        ...state.entities,
        items: state.entities.items.filter((community) => community.id !== data.payload.id),
      },
    })
  ),
  on(changeUserRoleSuccess, (state, data: any) => ({
    ...state,
    members: state.members.map((member) =>
      member.userId == data.payload.userId
        ? {
            ...member,
            role: data.payload.role,
          }
        : member
    ),
  }))
);
