import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  ViewChild,
  inject,
} from '@angular/core';
import { QuillEditorComponent, QuillModules } from 'ngx-quill';
import { AsyncPipe, NgClass, NgIf, NgStyle } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DarkModeService } from '@shared/services/dark-mode.service';
import { Mention, User } from '@core/models/user.model';
import { map, of, switchMap, takeUntil } from 'rxjs';
import { CommunityMention } from '@core/models/community.model';
import { UsersService } from '@shared/services/users/users.service';
import { CommunityService } from '@shared/services/community.service';
import { UrlPreviewService } from '@shared/services/url-preview/url-preview.service';
import { UrlPreviewComponent } from '@shared/components/url-preview/url-preview.component';
import { DestroyBaseComponent } from '@core/components/destroy-base/destroy-base.component';
import { MatInputModule } from '@angular/material/input';

const contentMaxLength = 10000;

@Component({
  selector: 'app-rich-text-editor',
  standalone: true,
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    QuillEditorComponent,
    NgClass,
    AsyncPipe,
    ReactiveFormsModule,
    NgIf,
    UrlPreviewComponent,
    NgStyle,
    MatInputModule,
  ],
})
export class RichTextEditorComponent extends DestroyBaseComponent implements AfterViewInit {
  @ViewChild(QuillEditorComponent) quill: QuillEditorComponent;
  @Input() formGroup: FormGroup;
  @Input() theme: 'snow' | 'bubble' = 'snow';
  @Input() enableUrlPreview = false;
  @Input() placeholder = '';
  @Input() headerTitle = '';
  @Input() set insertText(value: string) {
    this.quillInsertText(value);
  }
  @Input() set enableMention(enable: boolean) {
    if (enable)
      this.modules = {
        ...this.modules,
        ...this.mentionConfig,
      };
  }

  modules = {
    magicUrl: true,
    imageCompress: {
      quality: 0.7,
      maxWidth: 1000,
      maxHeight: 1000,
      imageType: 'image/jpeg',
      suppressErrorLogging: false,
      insertIntoEditor: undefined,
    },
    imageResize: {},
  };

  protected readonly contentMaxLength = contentMaxLength;

  darkMode$ = inject(DarkModeService).isDarkMode;
  users$ = (search: string) => this.users.searchMention(search, 0, 10).pipe(map(({ items }) => items));
  communities$ = (search: string) => this.communities.searchMention(search);

  constructor(
    private readonly users: UsersService,
    private readonly communities: CommunityService,
    private readonly urlPreviews: UrlPreviewService,
    private readonly cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.cdr.detectChanges());
  }

  private setPreview(): void {
    this.quill.quillEditor?.on('text-change', () => {
      const firstUrl = this.quill.quillEditor.getContents().ops?.find((op) => op.attributes && op.attributes['link']);

      this.formGroup.controls['urlPreviewLink'].setValue(firstUrl?.attributes ? firstUrl?.attributes['link'] : '');
      if (firstUrl?.attributes) this.setPreviewData();
    });
  }

  private setPreviewData() {
    this.urlPreviews
      .getByUrl(this.formGroup.controls['urlPreviewLink'].value as string)
      .subscribe(({ title, description, image }) =>
        this.formGroup.patchValue({
          urlPreviewTitle: title,
          urlPreviewDescription: description,
          urlPreviewImage: image,
        })
      );
  }

  private quillInsertText(text: string): void {
    if (this.quill) {
      const { quillEditor } = this.quill;
      const selection = quillEditor.getSelection(true).index;

      quillEditor.insertText(selection, text, 'user');
      quillEditor.setSelection(selection + text.length, 0);
    }
  }

  private mentions(search: string, renderList: (mentions: Mention[]) => void, mention: string): void {
    const source$ = of(mention).pipe(
      switchMap((char) => {
        switch (char) {
          case '@':
            return this.users$(search);
          case '^':
            return this.communities$(search);
          default:
            return of([]);
        }
      })
    );

    source$
      .pipe(
        map((items) =>
          items.map((item) => {
            if (mention === '@') {
              const user = item as User;
              return {
                value: user.displayName.replace(/ /g, '\u00A0'),
                link: `/profile/${user.userId}`,
                avatar: this.itemIcon(user.profilePhotoThumbnail, 'account_circle'),
                jobTitle: user.jobTitle ?? '',
              };
            } else {
              const community = item as CommunityMention;
              return {
                value: community.name,
                link: `/communities/${community.id}`,
                avatar: this.itemIcon(community.profilePhoto, 'groups'),
              };
            }
          })
        )
      )
      .subscribe((mentions) => renderList(mentions));
  }

  private itemIcon(photo = '', defaultIcon: string): string {
    return photo
      ? `<img src="${photo}" class="mention-avatar" alt="avatar"/>`
      : `<mat-icon class="mat-icon mention-avatar">${defaultIcon}</mat-icon>`;
  }

  private renderItem(item: Mention): string {
    const div = document.createElement('div');

    div.classList.add('mention-item');
    div.innerHTML = `${item.avatar}
      <div class="mention-details">
        <b class="mention-value">${item.value}</b><br>
        ${item.jobTitle ? `<small class="mention-job-title">${item.jobTitle}</small>` : ''}
      </div>
    `;
    return div.outerHTML;
  }

  private renderLoading(): string {
    return `<div class="loading-spinner"></div>`;
  }

  private get mentionConfig(): QuillModules {
    return {
      mention: {
        allowedChars: /^[A-Za-z0-9_ ]*$/,
        mentionDenotationChars: ['@', '^'],
        linkTarget: '_self',
        isolateCharacter: false,
        minChars: 1,
        positioningStrategy: 'fixed',
        source: this.mentions.bind(this),
        renderItem: this.renderItem,
        renderLoading: this.renderLoading,
      },
    };
  }

  get textCtrl(): FormControl {
    return this.formGroup.controls['text'] as FormControl;
  }
}
