<span
  matTooltip="Maximum number of documents reached"
  [matTooltipDisabled]="control.value.length < maxDocuments || maxDocuments === 1"
  style="display: block"
>
  <button
    (click)="openDialog()"
    [disabled]="control.value?.length >= maxDocuments && maxDocuments > 1"
    type="button"
    class="insert"
    mat-icon-button
    style="margin: 0"
    ><mat-icon>upload_file</mat-icon></button
  >
</span>

<input (change)="handleFileUpload($event)" #fileInput type="file" accept=".docx, .pptx, .xlsx, .txt, .csv, .pdf" />
