import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkConnectedOverlay, CdkOverlayOrigin, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { DestroyBaseComponent } from '@core/components/destroy-base/destroy-base.component';
import { debounceTime, filter, fromEvent, share, startWith, switchMap, takeUntil } from 'rxjs';
import { positions } from '@shared/components/popup/popup-positions';
import { UtilitiesService } from '@core/services/helpers/utilities/utilities.service';

@Component({
  selector: 'app-popup',
  standalone: true,
  imports: [CommonModule, CdkConnectedOverlay],
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupComponent extends DestroyBaseComponent implements OnInit, AfterViewInit {
  @Input() overlayOrigin: CdkOverlayOrigin;
  @Input() hideDelay = 0;
  @Input() showDelay = 0;
  @Input() offsetY = 0;
  @Input() offsetX = 0;
  @Input() position: 'bottom-center' | 'top';
  @Output() close = new EventEmitter<void>();
  @Output() open = new EventEmitter<void>();

  @ViewChild('dialog') dialog: ElementRef;
  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay: CdkConnectedOverlay;

  isOpened = false;
  positions = positions;

  constructor(
    public readonly sso: ScrollStrategyOptions,
    private readonly cdr: ChangeDetectorRef,
    private readonly utilities: UtilitiesService
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.utilities.commentAnimDone$.subscribe(
      () => this.changeState(false)
      /**
       * use instead of this.changeState(false) in case reposition is needed
       * if (this.cdkConnectedOverlay.overlayRef) this.cdkConnectedOverlay.overlayRef.updatePosition();
       */
    );
  }

  ngOnInit(): void {
    const overlayOriginEl = this.overlayOrigin.elementRef.nativeElement;

    /**
     * open popup if mouse stopped in overlayOriginEl (for short time).
     * If user just quickly got over overlayOriginEl element - do not open
     */
    const open$ = fromEvent(overlayOriginEl, 'mouseenter').pipe(
      filter(() => !this.isOpened),
      switchMap((enterEvent) =>
        fromEvent(document, 'mousemove').pipe(
          startWith(enterEvent),
          debounceTime(this.showDelay),
          filter((event) => overlayOriginEl === (event as Event).target)
        )
      ),
      share()
    );
    open$.pipe(takeUntil(this.destroy$)).subscribe(() => this.changeState(true));

    /**
     * close if mouse left the overlayOriginEl and dialog(after short delay)
     */
    const close$ = fromEvent(document, 'mousemove').pipe(
      debounceTime(this.hideDelay),
      filter(() => this.isOpened),
      filter((event) => this.isMovedOutside(overlayOriginEl, event))
    );

    open$
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() => close$)
      )
      .subscribe(() => this.changeState(false));
  }

  connectedOverlayDetach(): void {
    this.changeState(false);
  }

  private changeState(isOpened: boolean): void {
    this.isOpened = isOpened;
    isOpened ? this.open.emit() : this.close.emit();
    this.cdr.markForCheck();
  }

  private isMovedOutside(overlayOriginEl: any, { target }: Event): boolean {
    return !(overlayOriginEl.contains(target) || this.dialog.nativeElement.contains(target));
  }
}
