<main
  class="main"
  [ngClass]="(darkMode$ | async) ? 'dark-mode-main' : ''"
  (click)="contentState(true)"
  (clickOutside)="contentState(false)"
  (keydown.enter)="emitComment($event)"
  [attr.data-placeholder]="placeholderData"
  [class.preview-editor]="!editorState && !expanded"
>
  <div
    *ngIf="editorState || expanded"
    @collapseFade
    [@.disabled]="expanded"
    (@collapseFade.done)="captureDoneEvent($event)"
    class="comment-editor"
  >
    <span
      (paste)="onPasteHtml($event)"
      [formControl]="textControl"
      #contentEditable
      contenteditable="true"
      data-placeholder="Add a comment..."
      class="comment-editor__input"
      [ngClass]="(darkMode$ | async) ? 'dark-mode-input' : ''"
    ></span>

    <span *ngIf="textControl.value?.length > formMaxLength" class="comment-editor__exceed-length">
      {{ exceedLength }}
    </span>

    <app-post-images-preview
      *ngIf="imageControl.value"
      [uploadedImages]="[imageControl.value]"
      (discardImage)="discardImage()"
    >
    </app-post-images-preview>

    <i class="horizontal-divider"></i>

    <div class="comment-editor__actions">
      <div class="comment-editor__actions--insert">
        <app-media-input [multiple]="false" (imageUpload)="uploadImage($event)"></app-media-input>
        <app-emoji-picker (selectedEmoji)="addReaction($event)" (click)="getCursorPosition()"></app-emoji-picker>
      </div>

      <div *ngIf="loading$ | async" style="margin-left: auto">
        <ng-container [ngTemplateOutlet]="loadingSpinner"></ng-container>
      </div>

      <button
        (click)="emitComment($event)"
        [disabled]="(loading$ | async) || editorFormGroup.invalid"
        mat-icon-button
        type="submit"
        class="comment-editor__actions--send-comment"
        matTooltip="Send comment"
        [matTooltipShowDelay]="1000"
      >
        <mat-icon class="mat-icon-fill">send</mat-icon>
      </button>
    </div>
  </div>
</main>

<ng-template #loadingSpinner>
  <div class="loader">
    <app-loading loaderSize="small"></app-loading>
  </div>
</ng-template>
