<div [ngClass]="(darkMode$ | async) ? 'dark-mode-border' : ''" class="comment-container">
  <ng-container *ngRequestKey="requestKey; spinnerSize: 'medium'">
    <form [formGroup]="commentsForm">
      <div class="comment-container__post-comment">
        <app-profile-image
          [photo]="user.profilePhotoThumbnail!"
          [name]="user.displayName"
          [userId]="user.userId"
          imageSize="small"
        ></app-profile-image>

        <app-comment-editor
          [loading$]="isLoading$"
          [editorFormGroup]="commentsForm"
          (handleComment)="addComment()"
        ></app-comment-editor>
      </div>

      <ng-container formArrayName="commentList">
        <div
          *ngFor="let comment of getCommentArray.controls; let i = index; let first = first"
          [formGroupName]="i"
          [style.margin-top]="first ? '8px' : null"
        >
          <app-comment-item
            [canPin]="feedElement.user.userId === user.userId"
            [itemFormGroup]="getFormGroupIndex(i)"
            [user]="user"
            (pinUpdate)="updateCommentAfterPinAction($event)"
            (handleEditItem)="removeEditing($event)"
          ></app-comment-item>

          <app-comment-reply
            *ngIf="getFormGroupIndex(i).value['isThreadOpen'] || getFormGroupIndex(i).value['replyCount'] > 0"
            [user]="user"
            [formGroup]="getFormGroupIndex(i)"
            [repliesFormArray]="getRepliesFormArray(i)"
          ></app-comment-reply>
        </div>
      </ng-container>

      <div *ngIf="hasMore" class="comment-container__more-section">
        <span *ngIf="!loadMoreComment; else displayLoading" (click)="getComments(6, true)" class="link">
          <mat-icon class="icon-1rem">trending_flat</mat-icon> Load more comments...
        </span>

        <span> {{ getCommentArray.controls.length }} of {{ feedElement.commentsCount }} </span>
      </div>
    </form>
  </ng-container>
</div>

<ng-template #displayLoading>
  <app-loading loaderSize="small"></app-loading>
</ng-template>
