import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Comment, EditComment } from '@core/models/comment.model';
import { User } from '@core/models/user.model';
import { ImageDialogComponent } from '@shared/components/dialogs/image-dialog/image-dialog.component';
import { CommentsService } from '@shared/services/comments/comments.service';
import { BehaviorSubject, finalize, iif, takeUntil } from 'rxjs';

import { ConvertorService } from '@core/services/helpers/file-convertor/convertor.service';
import { DarkModeService } from '@shared/services/dark-mode.service';
import { DateTimeConvertService } from '@core/services/helpers/date-time-convert/date-time-convert.service';
import { FeedElementType } from '@core/models/feed-element.model';
import { DestroyBaseComponent } from '@core/components/destroy-base/destroy-base.component';
import { CommonModule } from '@angular/common';
import { ProfileImageComponent } from '@shared/components/profile-image/profile-image.component';
import { UserNameComponent } from '@shared/components/user-name/user-name.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TimeAgoPipe } from '@shared/pipes/time-ago.pipe';
import { TruncateTextPipe } from '@shared/pipes/truncate-text.pipe';
import { CommentReactionComponent } from '@shared/components/comments/comment-reaction/comment-reaction.component';
import { ShortNumberFormatPipe } from '@shared/pipes/short-number-format.pipe';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { PopupComponent } from '@shared/components/popup/popup.component';
import { LoadingComponent } from '@shared/components/loading/loading.component';
import { CommentMenuOptionsComponent } from '@shared/components/comments/comment-menu-options/comment-menu-options.component';
import { CommentEditorComponent } from '@shared/components/comments/comment-editor/comment-editor.component';

@Component({
  selector: 'app-comment-item',
  standalone: true,
  templateUrl: './comment-item.component.html',
  styleUrls: ['./comment-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ProfileImageComponent,
    UserNameComponent,
    MatIconModule,
    MatTooltipModule,
    TimeAgoPipe,
    TruncateTextPipe,
    CommentReactionComponent,
    ShortNumberFormatPipe,
    CdkOverlayOrigin,
    EmojiModule,
    PopupComponent,
    LoadingComponent,
    CommentMenuOptionsComponent,
    CommentEditorComponent,
  ],
})
export class CommentItemComponent extends DestroyBaseComponent implements OnInit {
  @Input() user: User;
  @Input() canPin: boolean;
  @Input() itemFormGroup: FormGroup;
  @Input() isThreadItem: boolean;
  @Input() displayThreadLine: boolean;
  @Input() type: FeedElementType;

  @Output() handleEditItem = new EventEmitter<string>();
  @Output() pinUpdate = new EventEmitter<Comment>();
  @Output() replyInput = new EventEmitter<void>();

  isLoading = new BehaviorSubject<boolean>(false);
  commentEditedDate: string;
  confirmDiscard = true;
  isMenuOpened = false;
  darkMode$ = inject(DarkModeService).isDarkMode;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly dialog: MatDialog,
    private readonly convertor: ConvertorService,
    private readonly comments: CommentsService,
    private readonly dateConvert: DateTimeConvertService
  ) {
    super();
  }

  ngOnInit(): void {
    this.itemFormGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.cdr.detectChanges());
    this.commentEditedDate = this.dateConvert.convertToLocalTime(this.commentFormValue.lastEditDate);
  }

  public editComment(status: boolean): void {
    const { isEditing, text, image } = this.itemFormGroup.controls;

    isEditing.patchValue(status);
    this.confirmDiscard = true;

    if (status) {
      this.handleEditItem.emit(this.commentFormValue.id);
    } else {
      text.patchValue(this.commentFormValue.originalText);
      image.patchValue(this.commentFormValue.originalImage);
      this.cdr.detectChanges();
    }
  }

  public deleteComment(): void {
    const { isDeleting } = this.itemFormGroup.controls;

    this.isLoading.next(true);
    isDeleting.patchValue(true);

    iif(
      () => this.isThreadItem,
      this.comments.deleteReply(this.commentFormValue.id),
      this.comments.delete(this.commentFormValue.id)
    ).subscribe({
      error: () => isDeleting.patchValue(false),
      next: () => this.isLoading.next(false),
    });
  }

  public sendEditedComment(): void {
    this.itemFormGroup.get('isEditing')?.patchValue(false);
    this.isLoading.next(true);

    iif(
      () => this.isThreadItem,
      this.comments.updateReply(this.commentEditValue),
      this.comments.update(this.commentEditValue)
    )
      .pipe(finalize(() => this.isLoading.next(false)))
      .subscribe();
  }

  public openReplyInput(): void {
    this.isThreadItem ? this.replyInput.emit() : this.itemFormGroup.get('isThreadOpen')?.setValue(true);
  }

  public expandImage(image: string): void {
    this.dialog.open(ImageDialogComponent, {
      height: '56vh',
      minWidth: '600px',
      width: 'fit-content',
      data: image,
    });
  }

  public updatePinStatus(comment: Comment): void {
    iif(() => comment.pin, this.comments.unpin(comment.id), this.comments.pin(comment.id)).subscribe((comment) =>
      this.pinUpdate.emit(comment)
    );
  }

  public get commentEditValue(): EditComment {
    const { id, text, image, originalImage } = this.commentFormValue;
    const newImage = !!image;
    const deleteOldImage =
      (newImage && originalImage != image && originalImage != null) || (!newImage && !!originalImage);

    return {
      id,
      text: text ?? ' ',
      deleteOldImage,
      newImage: newImage && this.convertor.isBase64Image(image) ? this.convertor.convertToFile(image) : undefined,
    };
  }

  public get commentFormValue(): Comment {
    return this.itemFormGroup.value;
  }

  public get countTotalReactions(): number {
    return this.commentFormValue.reactions.reduce((acc, reaction) => acc + (reaction?.count || 0), 0);
  }
}
