import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { DestroyBaseComponent } from '@core/components/destroy-base/destroy-base.component';
import { SizeOptions } from '@core/enums/sizeOptions';
import { DarkModeService } from '@shared/services/dark-mode.service';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';

export type LoadingSize = 'tiny' | 'xsmall' | 'small' | 'medium' | 'large';

@Component({
  selector: 'app-loading',
  standalone: true,
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, AsyncPipe, NgIf],
})
export class LoadingComponent extends DestroyBaseComponent {
  @Input() set loaderSize(value: LoadingSize) {
    this.size = SizeOptions[value];
  }
  @Input() isPageLoader?: boolean = false;

  size = SizeOptions.medium;
  darkMode$ = inject(DarkModeService).isDarkMode;

  constructor() {
    super();
  }
}
