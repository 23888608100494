import { AfterViewInit, Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { RequestKey } from '@core/enums/request-keys';
import { HandleLoadingService } from '@core/services/helpers/handle-loading/handle-loading.service';

@Directive({
  selector: '[animateLoading]',
  standalone: true,
})
export class AnimateLoadingDirective implements OnInit, AfterViewInit {
  @Input() requestKey?: RequestKey;
  placeholder = this.renderer.createElement('div');

  constructor(
    private element: ElementRef,
    private readonly renderer: Renderer2,
    private readonly loadingStatusService: HandleLoadingService
  ) {
    this.renderer.addClass(this.placeholder, 'image-preload');
  }

  ngOnInit(): void {
    this.renderer.appendChild(this.element.nativeElement, this.placeholder);
  }

  //wait for request response and image render then remove loading animation element
  //if no request is provided wait for image render only

  ngAfterViewInit(): void {
    if (this.requestKey)
      this.loadingStatusService.isRequestFinalized(this.requestKey).subscribe((isFinalized) => {
        if (isFinalized) {
          if (this.loadedElement.tagName == 'IMG') {
            this.loadedElement.addEventListener('load', () => this.clearLoading());
          } else this.clearLoading();
        }
      });
    else {
      if (this.loadedElement.tagName == 'IMG') {
        this.loadedElement.addEventListener('load', () => {
          this.renderer.removeChild(this.element.nativeElement, this.placeholder);
        });
      } else this.clearLoading();
    }
  }

  private clearLoading(): void {
    this.renderer.removeChild(this.element.nativeElement, this.placeholder);
  }

  private get loadedElement(): HTMLElement {
    return this.element.nativeElement.firstElementChild;
  }
}
