import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeConvertService } from '@core/services/helpers/date-time-convert/date-time-convert.service';

@Pipe({
  name: 'timeAgo',
  standalone: true,
})
export class TimeAgoPipe implements PipeTransform {
  constructor(private dateTimeConvertService: DateTimeConvertService) {}

  transform(value: string, format: string = 'long'): string | Date {
    const seconds = this._getSeconds(value);
    const minute = 60;
    const hour = 60;
    const day = 24;
    const week = 7;

    if (value && format === 'short') {
      const date: Date = new Date(value);

      return `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}`;
    }

    if (value && format === 'long') {
      const isYearLeap: boolean = new Date().getFullYear() % 4 === 0;
      const yearDaysNumber: number = isYearLeap ? 366 : 365;

      if (seconds < 10) return 'Just now';

      if (seconds < minute + minute - 1) return 'A minute ago';

      if (seconds < minute * hour - 1) return `${Math.floor(seconds / minute)} minutes ago`;

      if (seconds < minute * hour * (day - 1))
        return `${Math.floor(seconds / (minute * hour))} hour${
          Math.floor(seconds / (minute * hour)) === 1 ? '' : 's'
        } ago`;

      if (seconds < minute * hour * day * 2 && new Date().getDay() - 1 === new Date(value).getDay())
        return `Yesterday at ${this.dateTimeConvertService.convertToLocalTime(value, 'HH:mm')}`;

      if (
        seconds < minute * hour * day * yearDaysNumber &&
        new Date(value).getFullYear() === new Date().getFullYear()
      ) {
        return `${this.dateTimeConvertService.convertToLocalTime(
          value,
          'd MMM'
        )} at ${this.dateTimeConvertService.convertToLocalTime(value, 'HH:mm')}`;
      }

      return `${this.dateTimeConvertService.convertToLocalTime(
        value,
        'd MMM, y'
      )} at ${this.dateTimeConvertService.convertToLocalTime(value, 'HH:mm')}`;
    } else if (value && format === 'short') {
      if (seconds < 10) return 'Now';

      if (seconds < minute - 1) return `${Math.round(seconds)}s`;

      if (seconds < minute * hour - 1) return `${Math.round(seconds / minute)}m`;

      if (seconds < minute * hour * day - 1) return `${Math.floor(seconds / (minute * hour))}h`;

      if (seconds < minute * hour * day * week - 1) return `${Math.floor(seconds / (minute * hour * day))}d`;

      if (seconds) return `${Math.floor(seconds / (minute * hour * day * week))}w`;
    }
    return value;
  }

  private _getSeconds(value: string): number {
    return (new Date().getTime() - new Date(value).getTime()) / 1000;
  }
}
