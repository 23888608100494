import { NgModule } from '@angular/core';
import { EventCardOverlayComponent } from '@shared/components/event-card-overlay/event-card-overlay.component';
import { LayoutComponent } from '@shared/components/layout/layout.component';
import { PostImagesPreviewComponent } from '@shared/components/post-images-preview';
import { SearchInputComponent } from '@shared/components/inputs/search-input/search-input.component';
import { SearchComponent } from '@shared/components/search/search.component';
import { SharedPostComponent } from '@shared/components/shared-post/shared-post.component';
import { SideMenuComponent } from '@shared/components/side-menu/side-menu.component';
import { SuggestionsComponent } from '@shared/components/suggestions/suggestions.component';
import { CommunityNameComponent } from './components/community-name/community-name.component';
import { FollowerPreviewComponent } from './components/follower-preview/follower-preview.component';
import { GalleryPreviewComponent } from './components/gallery-preview/gallery-preview.component';
import { InfiniteScrollComponent } from './components/infinite-scroll/infinite-scroll.component';
import { LoadingComponent } from './components/loading/loading.component';
import { MediaInputComponent } from '@shared/components/inputs/media-input/media-input.component';
import { ProfileImageComponent } from './components/profile-image/profile-image.component';
import { ReactionsComponent } from './components/reactions/reactions.component';
import { SuggestionCardComponent } from './components/suggestion-card/suggestion-card.component';
import { TimedButtonComponent } from './components/timed-button/timed-button.component';
import { UserNameComponent } from './components/user-name/user-name.component';
import { AnimateLoadingDirective } from './directives/animate-loading.directive';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { ContenteditableDirective } from './directives/contenteditable.directive';
import { ButtonLoaderDirective } from './directives/loading-button.directive';
import { NgRequestKeyDirective } from './directives/ng-request-key.directive';
import { ShortNumberFormatPipe } from './pipes/short-number-format.pipe';
import { SimpleTruncatePipe } from './pipes/simple-truncate.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { TruncateTextPipe } from './pipes/truncate-text.pipe';
import { UserInitialsPipe } from './pipes/user-initials.pipe';
import { UserInfoPreviewComponent } from './components/user-info-preview/user-info-preview.component';
import { CreatePostBoxComponent } from '@shared/components/create-post-box/create-post-box.component';
import { AttachedFilesComponent } from '@shared/components/attached-files/attached-files.component';
import { DocumentsInputComponent } from '@shared/components/inputs/documents-input/documents-input.component';
import { MentionPreviewComponent } from './components/mention-preview/mention-preview.component';
import { CommentReactionComponent } from '@shared/components/comments/comment-reaction/comment-reaction.component';
import { CommentBoxComponent } from '@shared/components/comments/comment-box/comment-box.component';
import { CommentEditorComponent } from '@shared/components/comments/comment-editor/comment-editor.component';
import { CommentItemComponent } from '@shared/components/comments/comment-item/comment-item.component';
import { CommentMenuOptionsComponent } from '@shared/components/comments/comment-menu-options/comment-menu-options.component';
import { CommentReplyComponent } from '@shared/components/comments/comment-reply/comment-reply.component';
import { UrlPreviewComponent } from './components/url-preview/url-preview.component';
import { RichTextEditorComponent } from './components/rich-text-editor/rich-text-editor.component';
import { SanitizeHtmlPipe } from '@shared/pipes/sanitize-html.pipe';
import { PopupComponent } from '@shared/components/popup/popup.component';
import { PostComponent } from './components/post/post.component';
import { ContentCreatorPageComponent } from '@shared/components/content-creator-page/content-creator-page.component';
import { ArticleComponent } from '@shared/components/article/article.component';
import { FocusRemoverDirective } from './directives/focus-remover.directive';

@NgModule({
  imports: [
    UserInitialsPipe,
    TimeAgoPipe,
    TruncateTextPipe,
    SimpleTruncatePipe,
    ShortNumberFormatPipe,
    SanitizeHtmlPipe,
    ContenteditableDirective,
    NgRequestKeyDirective,
    ButtonLoaderDirective,
    AnimateLoadingDirective,
    ClickOutsideDirective,
    FocusRemoverDirective,
    UserNameComponent,
    LoadingComponent,
    InfiniteScrollComponent,
    ReactionsComponent,
    ProfileImageComponent,
    FollowerPreviewComponent,
    RichTextEditorComponent,
    UrlPreviewComponent,
    DocumentsInputComponent,
    AttachedFilesComponent,
    MediaInputComponent,
    SearchInputComponent,
    SearchComponent,
    PopupComponent,
    CommentReactionComponent,
    EventCardOverlayComponent,
    CreatePostBoxComponent,
    SideMenuComponent,
    LayoutComponent,
    PostComponent,
    ArticleComponent,
    CommunityNameComponent,
    GalleryPreviewComponent,
    UserInfoPreviewComponent,
    SharedPostComponent,
    CommentBoxComponent,
    CommentEditorComponent,
    PostImagesPreviewComponent,
    CommentItemComponent,
    CommentMenuOptionsComponent,
    CommentReplyComponent,
    ContentCreatorPageComponent,
    MentionPreviewComponent,
    TimedButtonComponent,
    SuggestionCardComponent,
    SuggestionsComponent,
  ],
  exports: [
    TimeAgoPipe,
    UserInitialsPipe,
    TruncateTextPipe,
    SimpleTruncatePipe,
    ShortNumberFormatPipe,
    SanitizeHtmlPipe,
    NgRequestKeyDirective,
    ButtonLoaderDirective,
    ContenteditableDirective,
    ClickOutsideDirective,
    AnimateLoadingDirective,
    FocusRemoverDirective,
    SearchComponent,
    SearchInputComponent,
    ProfileImageComponent,
    LayoutComponent,
    PostComponent,
    ArticleComponent,
    UserNameComponent,
    LoadingComponent,
    ReactionsComponent,
    CommentReactionComponent,
    CommunityNameComponent,
    GalleryPreviewComponent,
    SideMenuComponent,
    RichTextEditorComponent,
    MediaInputComponent,
    PostImagesPreviewComponent,
    InfiniteScrollComponent,
    SuggestionCardComponent,
    SuggestionsComponent,
    PopupComponent,
    TimedButtonComponent,
    SharedPostComponent,
    EventCardOverlayComponent,
    UserInfoPreviewComponent,
    CreatePostBoxComponent,
    AttachedFilesComponent,
    DocumentsInputComponent,
    CommentBoxComponent,
    CommentEditorComponent,
    CommentItemComponent,
    CommentMenuOptionsComponent,
    CommentReplyComponent,
    FollowerPreviewComponent,
    UrlPreviewComponent,
    ContentCreatorPageComponent,
    MentionPreviewComponent,
  ],
})
export class SharedModule {}
