import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { DarkModeService } from '@shared/services/dark-mode.service';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormValidator } from '@core/validators/form-validator';
import { MatInputModule } from '@angular/material/input';
import { TextFieldModule } from '@angular/cdk/text-field';

@Component({
  selector: 'app-feedback-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    TextFieldModule,
  ],
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackDialogComponent {
  textControl = new FormControl<string>('', { validators: [FormValidator.spaceAndMinlengthValidator(10)] });
  darkMode$ = inject(DarkModeService).isDarkMode;

  constructor(private dialogRef: MatDialogRef<FeedbackDialogComponent>) {}

  submit(): void {
    this.dialogRef.close(this.textControl.value);
  }
}
