<div *ngIf="data$() | async as items" [style.--items-space]="itemsGap + 'px'" [ngClass]="{ hasTabs: hasTabs }">
  <virtual-scroller
    #scroll
    [items]="items"
    [parentScroll]="scrollRef?.nativeElement"
    [enableUnequalChildrenSizes]="true"
    [bufferAmount]="5"
    (vsEnd)="listenScroll($event, items.length)"
  >
    <ng-container *ngFor="let item of scroll.viewPortItems">
      <ng-container [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{ $implicit: item }"> </ng-container>
    </ng-container>
  </virtual-scroller>
  <i
    *ngIf="hasMore && scrollLoading"
    [ngClass]="(darkMode$ | async) ? 'dark-mode-indicator' : ''"
    class="loading-indicator"
  >
    <app-loading loaderSize="large"></app-loading>
  </i>
</div>

<!--TODO: display empty list msg from this component-->
<ng-template> List is empty </ng-template>
