import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UrlPreview } from '@core/models/url-preview.model';
import { RequestActions } from '@core/enums/request-keys';
import { CustomHttpParams } from '@core/services/helpers/handle-loading/handle-loading.service';
import { BASE_URL } from '@core/constants/constants';

@Injectable({
  providedIn: 'root',
})
export class UrlPreviewService {
  constructor(private readonly http: HttpClient, @Inject(BASE_URL) private readonly baseUrl: string) {
    this.baseUrl += '/url-preview';
  }

  public getByUrl(url: string): Observable<UrlPreview> {
    return this.http.get<UrlPreview>(`${this.baseUrl}?url=${url}`, {
      params: new CustomHttpParams({ action: RequestActions.GET_URL_PREVIEW }),
    });
  }
}
