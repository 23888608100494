<form
  [formGroup]="itemFormGroup"
  [ngClass]="{
    'item-deleting': commentFormValue.isDeleting,
    'edit-fit-content': commentFormValue.isEditing,
    'dark-mode': darkMode$ | async
  }"
  class="comment-item"
>
  <section class="comment-item__profile-image-block">
    <span [class.thread-image-block]="isThreadItem">
      <i *ngIf="isThreadItem" class="curvy-line"></i>
      <ng-container *ngIf="!commentFormValue.user.isDeleted; else defaultPhoto">
        <app-profile-image
          [photo]="commentFormValue.user.profilePhotoThumbnail!"
          [name]="commentFormValue.user.displayName"
          [userId]="commentFormValue.user.userId"
          imageSize="small"
        ></app-profile-image>
      </ng-container>
    </span>

    <i
      *ngIf="displayThreadLine || itemFormGroup.value['replyCount'] > 0 || itemFormGroup.value['isThreadOpen']"
      [ngClass]="isThreadItem ? 'thread-straight-line' : 'straight-line'"
    ></i>
  </section>

  <ng-container *ngIf="!commentFormValue.isEditing; else trEditComment">
    <section class="comment-item__details">
      <header>
        <ng-container *ngIf="!commentFormValue.user.isDeleted; else defaultName">
          <app-user-name
            nameSize="small"
            [userId]="commentFormValue.user.userId"
            [userName]="commentFormValue.user.displayName"
            [isContentCreator]="commentFormValue.user.isContentCreator!"
            class="name"
          ></app-user-name>
        </ng-container>
        <span [matTooltip]="commentEditedDate" class="date link">
          <mat-icon class="mat-icon-fill dot">fiber_manual_record</mat-icon>
          {{ commentFormValue.lastEditDate | timeAgo : 'short' }}
        </span>

        <span [matTooltip]="'Pinned by ' + user.displayName" *ngIf="commentFormValue.pin" class="pin-icon">
          <mat-icon class="mat-icon-fill icon-1rem">push_pin</mat-icon>
        </span>

        <span *ngIf="commentFormValue.lastEditDate !== commentFormValue.creationDate" class="date">
          <mat-icon class="mat-icon-fill dot">fiber_manual_record</mat-icon>
          Edited
        </span>

        <div *ngIf="isLoading | async" style="margin-left: auto">
          <ng-container [ngTemplateOutlet]="loading"></ng-container>
        </div>
      </header>

      <span class="text" *ngIf="commentFormValue.text" [innerHTML]="commentFormValue.text | truncateText : 120"></span>

      <div class="image-preview" *ngIf="commentFormValue.image" (click)="expandImage(commentFormValue.image)">
        <img [src]="commentFormValue.image" alt="" />
      </div>
    </section>

    <section class="comment-item__action-buttons" [style.margin-bottom]="isThreadItem ? '3px' : null">
      <app-comment-reaction [formGroup]="itemFormGroup"></app-comment-reaction>

      <button *ngIf="canPin" (click)="updatePinStatus(commentFormValue)" class="comment-item__action-buttons--pin">
        {{ commentFormValue.pin ? 'Unpin' : 'Pin' }}
      </button>
      <button *ngIf="!isThreadItem" (click)="openReplyInput()" class="comment-item__action-buttons--reply">
        Reply
      </button>

      <ng-container *ngIf="commentFormValue.reactions.length > 0">
        <ng-container *ngTemplateOutlet="reactions"></ng-container>
      </ng-container>
    </section>

    <section class="comment-item__menu-options" [style.visibility]="isMenuOpened ? 'visible' : null">
      <ng-container *ngIf="user.userId === commentFormValue.user.userId && !commentFormValue.isDeleting">
        <app-comment-menu-options
          (edit)="editComment(true)"
          (delete)="deleteComment()"
          (isMenuOpened)="isMenuOpened = $event"
        ></app-comment-menu-options>
      </ng-container>
    </section>
  </ng-container>
</form>

<ng-template #trEditComment>
  <section class="comment-item__edit">
    <app-comment-editor
      [expanded]="true"
      [editorFormGroup]="itemFormGroup"
      (handleComment)="sendEditedComment()"
    ></app-comment-editor>

    <span class="comment-item__edit--discard-edit">
      <ng-container *ngIf="confirmDiscard; else confirmTemp">
        Click <a (click)="confirmDiscard = false" class="link">here</a> to discard.
      </ng-container>

      <ng-template #confirmTemp>
        <a (click)="editComment(false)" class="link">Discard</a>
      </ng-template>
    </span>
  </section>
</ng-template>

<ng-template #reactions>
  <div [ngClass]="{ 'dark-mode': darkMode$ | async }" class="comment-reactions">
    <ul cdkOverlayOrigin #overlayOrigin="cdkOverlayOrigin">
      <li style="margin-right: 0.125rem; color: #9da9b9">
        {{ countTotalReactions | shortNumberFormat }}
      </li>
      <li *ngFor="let reaction of itemFormGroup.value['reactions']" style="margin-right: -4px">
        <ngx-emoji [emoji]="reaction.reaction" [size]="14" [isNative]="true"></ngx-emoji>
      </li>
    </ul>

    <app-popup [overlayOrigin]="overlayOrigin" [offsetY]="5" position="bottom-center">
      <ul class="reactions-count">
        <li *ngFor="let reaction of itemFormGroup.value['reactions']">
          {{ reaction.count }}
          <ngx-emoji [emoji]="reaction.reaction" [size]="14" [isNative]="true" class="default-reaction"></ngx-emoji>
        </li>
      </ul>
    </app-popup>
  </div>
</ng-template>

<ng-template #defaultPhoto>
  <mat-icon class="mat-icon-fill default-photo">person</mat-icon>
</ng-template>

<ng-template #defaultName>
  <span style="font-weight: 800; color: #2b4257; cursor: default">Unknown</span>
</ng-template>

<ng-template #loading>
  <div class="loader">
    <app-loading loaderSize="small"></app-loading>
  </div>
</ng-template>
