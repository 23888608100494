export const bodyFormData = <M>(body: Omit<M, 'type'>): FormData => {
  const formData = new FormData();

  Object.entries(body as object).forEach(([key, val]) => {
    if (val !== undefined && val !== null && !Array.isArray(val)) {
      formData.append(key, val as string);
    }
    if (Array.isArray(val)) {
      val.forEach((file) => formData.append(key, file));
    }
  });

  return formData;
};
