<div class="list">
  <section *ngFor="let image of uploadedImages; let index = index">
    <div class="image-container">
      <img animateLoading [src]="image" alt="" />

      <div class="overlay">
        <mat-icon class="discard" (click)="discardImage.emit(index)">close</mat-icon>
      </div>
    </div>
  </section>

  <span style="align-self: center" *ngIf="displayMaxImages">{{ uploadedImages?.length }} / {{ maxImages }}</span>
</div>
