import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Group, User, UserDisplayDto, UserImagesEndpoints } from '@core/models/user.model';
import { BASE_URL } from '@core/constants/constants';
import { buildUrl } from '@core/utils/buildUrl';
import { PagedResponse } from '@core/models/paged-response.model';
import { Publication } from '@core/models/publication.model';
import { bodyFormData } from '@core/utils/formData';
import { ProfilePhoto } from '@core/models/profile-photo.model';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private readonly http: HttpClient, @Inject(BASE_URL) private baseUrl: string) {
    this.baseUrl += '/user';
  }

  getById(id: string, isExtended = true): Observable<User> {
    return this.http.get<User>(`${this.baseUrl}/${id}/?extended=${String(isExtended)}`);
  }

  follow(id: string): Observable<UserDisplayDto> {
    return this.http.post<UserDisplayDto>(`${this.baseUrl}/${id}/follow`, {});
  }

  unfollow(id: string): Observable<UserDisplayDto> {
    return this.http.delete<UserDisplayDto>(`${this.baseUrl}/${id}/follow`);
  }

  search(
    searchString: string,
    jobTitle = '',
    officeCity = '',
    isAuthor = false,
    elementsCount = 0,
    requestedElements = 15
  ): Observable<PagedResponse<User>> {
    const url = buildUrl(`${this.baseUrl}/search`, {
      searchString,
      jobTitle,
      officeCity,
      isAuthor,
      elementsCount,
      requestedElements,
    });

    return this.http.get<PagedResponse<User>>(url);
  }

  searchMention(searchString: string, elementsCount = 0, requestedElements = 15): Observable<PagedResponse<User>> {
    const url = buildUrl(`${this.baseUrl}/mention-search`, {
      searchString,
      elementsCount,
      requestedElements,
    });

    return this.http.get<PagedResponse<User>>(url);
  }

  activity(id: string, elementsCount: number, requestedElements: number): Observable<PagedResponse<Publication>> {
    const url = buildUrl(`${this.baseUrl}/${id}/activity`, {
      elementsCount,
      requestedElements,
    });
    return this.http.get<PagedResponse<Publication>>(url);
  }

  uploadProfilePicture(file: File, type: UserImagesEndpoints): Observable<User> {
    const formData = bodyFormData({ file });

    return this.http.post<User>(`${this.baseUrl}/${type}`, formData);
  }

  updateProfilePicture(photoUrl: string): Observable<User> {
    const url = buildUrl(`${this.baseUrl}/${UserImagesEndpoints.profilePhoto}`, { photoUrl });

    return this.http.patch<User>(url, null);
  }

  deleteProfilePicture(type: UserImagesEndpoints): Observable<User> {
    return this.http.delete<User>(`${this.baseUrl}/${type}`);
  }

  deleteOldProfilePicture(oldProfilePhoto: string): Observable<void> {
    const url = buildUrl(`${this.baseUrl}/old-profile-photos`, { oldProfilePhoto });

    return this.http.delete<void>(url);
  }

  updateBackgroundPicture(picture: string): Observable<User> {
    return this.http.patch<User>(`${this.baseUrl}/${UserImagesEndpoints.backgroundPhoto}`, {
      picture,
    });
  }

  get getOldProfilePictures(): Observable<ProfilePhoto[]> {
    return this.http.get<ProfilePhoto[]>(`${this.baseUrl}/old-profile-photos`);
  }

  get getBackgroundImagesSamples(): Observable<string[]> {
    return this.http.get<string[]>(`${this.baseUrl}/default-background-pictures`);
  }

  get getGroups(): Observable<Group[]> {
    return this.http.get<Group[]>(`${this.baseUrl}/groups`);
  }

  get currentAuth(): Observable<User> {
    return this.http.get<User>(`${this.baseUrl}?extended=true`);
  }
}
