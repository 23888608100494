import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Event } from '@core/models/events.model';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { SimpleTruncatePipe } from '@shared/pipes/simple-truncate.pipe';
import { UserInitialsPipe } from '@shared/pipes/user-initials.pipe';

@Component({
  selector: 'app-event-card-overlay',
  standalone: true,
  templateUrl: './event-card-overlay.component.html',
  styleUrls: ['./event-card-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatCardModule, RouterLink, MatIconModule, SimpleTruncatePipe, UserInitialsPipe],
})
export class EventCardOverlayComponent {
  @Input() event: any;
  @Input() futureEvent: Event;
}
