<div class="content-block">
  <mat-card [ngClass]="(darkMode$ | async) ? 'dark-mode' : ''" *ngIf="user$ | async as user">
    <mat-progress-bar *ngIf="!user.userId" mode="indeterminate" style="position: absolute"></mat-progress-bar>

    <mat-card-header style="flex-direction: column">
      <div class="title">
        <mat-card-title><b>Personal Information</b></mat-card-title>
        <button (click)="goToProfile(user.userId)" [mat-dialog-close]="false" mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="user-details">
        <app-profile-image
          imageSize="profile"
          [photo]="contentCreatorRoute ? user.profilePhotoThumbnail! : previewData.profilePhoto!"
          [name]="contentCreatorRoute ? user.displayName! : previewData.name!"
          [userId]="contentCreatorRoute ? user.userId : previewData.creatorId"
        ></app-profile-image>
        <h2>
          <b>{{ contentCreatorRoute ? user.displayName : previewData.name }}</b>
        </h2>
        <h4> {{ contentCreatorRoute ? user.jobTitle : previewData.role }} </h4>
      </div>
    </mat-card-header>

    <mat-card-content>
      <mat-card-title style="align-self: flex-end"><b>Content Creator Application</b></mat-card-title>
      <div *ngIf="contentCreatorRoute" class="buttons-row">
        <button (click)="openPendingChangesPopup(user.userId)" type="button" mat-stroked-button>Cancel</button>
        <button
          (click)="apply(user.userId)"
          [disabled]="form.invalid"
          type="submit"
          mat-flat-button
          color="accent"
          class="apply"
        >
          Apply</button
        >
      </div>
      <mat-card-subtitle *ngIf="contentCreatorRoute">Add information about yourself</mat-card-subtitle>
      <mat-divider style="grid-column: 1/-1"></mat-divider>
      <form [formGroup]="form">
        <div *ngIf="contentCreatorRoute; else previewInterests" class="form-field">
          <h3><b>Interests</b></h3>
          <ng-container
            *ngTemplateOutlet="textarea; context: { control: form.controls.interests, label: 'Add interests' }"
          ></ng-container>
          <p>Add some activities that you enjoy doing.</p>
        </div>
        <ng-template #previewInterests>
          <div class="form-field">
            <h3><b>Interests</b></h3>
            <ng-container *ngTemplateOutlet="textarea; context: { control: form.controls.interests }"></ng-container>
            <p>Add some activities that you enjoy doing.</p>
          </div>
        </ng-template>

        <mat-divider style="padding-bottom: 1rem"></mat-divider>

        <div *ngIf="contentCreatorRoute; else previewReason" class="form-field">
          <h3><b>Reason</b></h3>
          <ng-container
            *ngTemplateOutlet="textarea; context: { control: form.controls.requestReason, label: 'Add reason' }"
          ></ng-container>
          <p>Why do you want to become a content creator?</p>
        </div>
        <ng-template #previewReason>
          <div class="form-field">
            <h3><b>Reason</b></h3>
            <ng-container
              *ngTemplateOutlet="textarea; context: { control: form.controls.requestReason }"
            ></ng-container>
            <p>Why do you want to become a content creator?</p>
          </div>
        </ng-template>
      </form>
    </mat-card-content>
  </mat-card>
</div>

<ng-template #textarea let-control="control" let-label="label">
  <mat-form-field>
    <mat-label *ngIf="contentCreatorRoute">{{ label }}</mat-label>
    <textarea
      [readonly]="!contentCreatorRoute"
      (blur)="trimValues()"
      [formControl]="control"
      maxlength="500"
      matInput
      cdkTextareaAutosize
      cdkAutosizeMinRows="1"
      cdkAutosizeMaxRows="6"
    ></textarea>
    <mat-error *ngIf="contentCreatorRoute && control.errors?.['required']"> This field is required </mat-error>
  </mat-form-field>
</ng-template>

<ng-template #onReview>
  <p> Your application is on review</p>
</ng-template>
