<div class="flex-end">
  <h2>Reactions</h2>
  <mat-icon class="close-icon" mat-dialog-close="true">close</mat-icon>
</div>

<mat-tab-group
  class="allow-tab-events withdraw-mat-tab"
  [selectedIndex]="selectedTab"
  animationDuration="0ms"
  disablePagination
  mat-stretch-tabs="false"
  mat-align-tabs="start"
>
  <mat-tab *ngIf="data.groupedFeedElementReactions.length > 1" class="reaction-mat-tab">
    <ng-template mat-tab-label>
      <span class="label-template" (click)="setSelectedOption(0)">All {{ data.feedElementReactionsData.length }}</span>
    </ng-template>
    <ng-container *ngIf="data.feedElementReactionsData.length > 0; else noContent">
      <ng-container *ngFor="let user of data.feedElementReactionsData">
        <app-follower-preview
          class="line-item"
          [reaction]="user.reaction"
          [skinTone]="user.skinTone"
          [user]="user.user"
          [currentUser]="data.currentUser"
          [isFollowing]="user.isFollowed"
          (follow)="updateFollow($event)"
        ></app-follower-preview>
      </ng-container>
    </ng-container>
  </mat-tab>

  <ng-container *ngFor="let reactionData of data.groupedFeedElementReactions; let index = index">
    <div *ngIf="index <= 2">
      <mat-tab class="reaction-mat-tab withdraw-mat-tab">
        <ng-template mat-tab-label>
          <div class="label-template" (click)="setSelectedOption(index)">
            <ngx-emoji
              [isNative]="true"
              [size]="16"
              [emoji]="reactionData.reaction"
              [skin]="reactionData.skinTone"
            ></ngx-emoji>
            <span class="reactions-count">{{ reactionData.count }}</span>
          </div>
        </ng-template>
        <ng-container *ngIf="data.feedElementReactionsData.length > 0; else noContent">
          <ng-container *ngFor="let user of data.feedElementReactionsData">
            <app-follower-preview
              class="line-item"
              *ngIf="user.reaction === reactionData.reaction && user.skinTone === reactionData.skinTone"
              [reaction]="user.reaction"
              [skinTone]="user.skinTone"
              [user]="user.user"
              [currentUser]="data.currentUser"
              [isFollowing]="user.isFollowed"
              (follow)="updateFollow($event)"
            ></app-follower-preview>
          </ng-container>
        </ng-container>
      </mat-tab>
    </div>
  </ng-container>

  <ng-container *ngFor="let reactionData of data.groupedFeedElementReactions; let index = index">
    <mat-tab class="reaction-mat-tab" *ngIf="index >= 3" [aria-labelledby]="'hidden'">
      <ng-template mat-tab-label>
        <ngx-emoji
          (click)="setSelectedOption(index)"
          [isNative]="true"
          [size]="16"
          [emoji]="reactionData.reaction"
          [skin]="reactionData.skinTone"
        ></ngx-emoji>
      </ng-template>
      <ng-container *ngIf="data.feedElementReactionsData.length > 0; else noContent">
        <ng-container *ngFor="let user of data.feedElementReactionsData">
          <app-follower-preview
            class="line-item"
            *ngIf="user.reaction === reactionData.reaction && user.skinTone === reactionData.skinTone"
            [reaction]="user.reaction"
            [skinTone]="user.skinTone"
            [user]="user.user"
            [currentUser]="data.currentUser"
            [isFollowing]="user.isFollowed"
            (follow)="updateFollow($event)"
          ></app-follower-preview>
        </ng-container>
      </ng-container>
    </mat-tab>
  </ng-container>

  <mat-tab class="reaction-mat-tab" *ngIf="data.groupedFeedElementReactions.length > 3">
    <ng-template mat-tab-label>
      <div (click)="triggerMenu($event, moreMenuTrigger)">
        <div class="more-reactions">
          <span>More</span>
          <mat-icon class="angle-down-menu">keyboard_arrow_down</mat-icon>
          <span class="more-menu-trigger" [matMenuTriggerFor]="matTabMenu" #moreMenuTrigger="matMenuTrigger"></span>
        </div>
        <span *ngIf="selectedReaction" class="more-tab-indicator"></span>
      </div>
    </ng-template>

    <mat-menu #matTabMenu xPosition="before" backdropClass="reactions-dialog-options-menu">
      <ng-container *ngFor="let reactionData of data.groupedFeedElementReactions; let index = index">
        <ng-container *ngIf="index >= 3">
          <div
            *ngIf="selectedReaction !== reactionData.reaction; else selectedOption"
            class="more-options option"
            (click)="setSelectedOption(index)"
          >
            <ngx-emoji
              [isNative]="true"
              [size]="17"
              [emoji]="reactionData.reaction"
              [skin]="reactionData.skinTone"
            ></ngx-emoji>
            <span class="reactions-count">{{ reactionData.count }}</span>
          </div>
          <ng-template #selectedOption>
            <div class="more-options selected" (click)="setSelectedOption(index)">
              <ngx-emoji
                [isNative]="true"
                [size]="17"
                [emoji]="reactionData.reaction"
                [skin]="reactionData.skinTone"
              ></ngx-emoji>
              <span class="reactions-count">{{ reactionData.count }}</span>
            </div>
          </ng-template>
        </ng-container>
      </ng-container>
    </mat-menu>
  </mat-tab>
</mat-tab-group>

<ng-template #noContent><p> No reactions to display</p> </ng-template>
