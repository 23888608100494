<div
  (click)="redirectToProfile()"
  [style.background-color]="photo ? '' : backgroundColor"
  [ngClass]="imageSize"
  [class.online-status]="isOnline"
  class="user-photo"
>
  <ng-container *ngIf="photo; else userInitials">
    <img
      [lazyLoad]="photo"
      alt=""
      style="
        overflow: hidden;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        border-radius: inherit;
      "
    />
  </ng-container>
  <ng-template #userInitials>
    {{ name | userInitials }}
  </ng-template>

  <ngx-emoji
    *ngIf="reaction"
    [ngClass]="{ 'dark-mode-reaction-icon': darkMode$ | async }"
    class="reaction-icon"
    (click)="redirectToProfile()"
    [isNative]="true"
    [emoji]="reaction"
    [skin]="skinTone"
    [size]="13"
  ></ngx-emoji>
</div>
