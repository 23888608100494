<div class="gallery-preview">
  <div
    *ngFor="let image of sliceImages; let index = index; let first = first; let last = last"
    animateLoading
    (click)="openCarousel(index)"
    [ngClass]="{
      'flex-1': first && images.length < 4
    }"
    class="gallery-preview__item"
  >
    <img *ngIf="image.imageURL" [ngSrc]="image.imageURL" fill alt="" />

    <span *ngIf="last && images.length > 4" class="gallery-preview__item--expand"> + {{ images.length - 4 }}</span>
  </div>
</div>
