import { ChangeDetectorRef, Directive, Input, OnChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { LoadingComponent, LoadingSize } from '@shared/components/loading/loading.component';
import { RequestKey } from '@core/enums/request-keys';
import { HandleLoadingService } from '@core/services/helpers/handle-loading/handle-loading.service';

@Directive({
  selector: '[ngRequestKey]',
  standalone: true,
})
export class NgRequestKeyDirective implements OnChanges {
  @Input() ngRequestKey: RequestKey;
  @Input() ngRequestKeySpinnerSize?: LoadingSize = 'large';

  constructor(
    private readonly templateRef: TemplateRef<unknown>,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly loadingStatusService: HandleLoadingService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnChanges(): void {
    if (this.ngRequestKey) {
      this.loadingStatusService.isRequestFinalized(this.ngRequestKey).subscribe((loaded) => {
        if (!loaded) {
          this.viewContainerRef.clear();
          this.viewContainerRef.createComponent(LoadingComponent).setInput('loaderSize', this.ngRequestKeySpinnerSize);
        } else {
          this.viewContainerRef.clear();
          this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
        this.cdr.detectChanges();
      });
    } else {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
