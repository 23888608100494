import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TabsGroupModel } from '@core/models/tabs.model';
import { MenuNavService } from '@core/services/helpers/menu-nav/menu-nav.service';
import { Store } from '@ngrx/store';
import { MsalService } from '@azure/msal-angular';
import { Observable, filter, switchMap } from 'rxjs';
import { User } from '@core/models/user.model';
import { currentUser } from '@store/user/user.selectors';
import { NotificationsService } from '@shared/services/notifications/notifications.service';
import { InboxService } from '@shared/services/inbox/inbox.service';
import { DarkModeService } from '@shared/services/dark-mode.service';
import { collapse, fadeInOut, menuAnimation } from './animations';
import { CommonModule } from '@angular/common';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { SearchComponent } from '@shared/components/search/search.component';
import { LoadingComponent } from '@shared/components/loading/loading.component';
import { ProfileImageComponent } from '@shared/components/profile-image/profile-image.component';
import { MatButtonModule } from '@angular/material/button';
import { UserNameComponent } from '@shared/components/user-name/user-name.component';
import { FeedbackDialogComponent } from '@shared/components/dialogs/feedback-dialog/feedback-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { FeedbacksService } from '@shared/services/feedbacks/feedbacks.service';
import { UsersService } from '@shared/services/users/users.service';

@Component({
  selector: 'app-side-menu',
  standalone: true,
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  animations: [menuAnimation, fadeInOut, collapse],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterLink,
    MatTooltipModule,
    MatIconModule,
    SearchComponent,
    RouterLinkActive,
    LoadingComponent,
    ProfileImageComponent,
    MatButtonModule,
    UserNameComponent,
  ],
})
export class SideMenuComponent implements OnInit {
  lightLogo = 'assets/light-logo.svg';
  darkLogo = 'assets/dark-logo.svg';
  menuGroup: Record<string, TabsGroupModel[]> = {};
  isExpendedSignOut = false;
  isMenuOpened = JSON.parse(localStorage.getItem('isMenuOpened')!) ?? true;
  darkMode$ = this.darkMode.isDarkMode;

  users$ = (str: string) => this.users.search(str, '', '', false, 0, 5);

  constructor(
    private readonly menuNavService: MenuNavService,
    private readonly store: Store,
    private readonly msalService: MsalService,
    private readonly notifications: NotificationsService,
    private readonly inbox: InboxService,
    private readonly darkMode: DarkModeService,
    private readonly dialog: MatDialog,
    private readonly toastr: ToastrService,
    private readonly feedbacks: FeedbacksService,
    private readonly users: UsersService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.initMenu();
    this.listenNotification();
    this.initInboxCount();
  }

  public toggleMenu(): void {
    this.isExpendedSignOut = false;
    this.isMenuOpened = !this.isMenuOpened;
    localStorage.setItem('isMenuOpened', String(this.isMenuOpened));

    // useful for resizing the calendar
    setTimeout(() => window.dispatchEvent(new Event('resize')), 100);
  }

  public toggle(): void {
    this.isExpendedSignOut = !this.isExpendedSignOut;
  }

  public initMenu(): void {
    this.menuGroup = this.menuNavService.menuGroup.reduce((groupBy: Record<string, TabsGroupModel[]>, product) => {
      const { group } = product;
      groupBy[group] = groupBy[group] ?? [];
      groupBy[group].push(product);
      return groupBy;
    }, {});
  }

  public get user$(): Observable<User> {
    return this.store.select(currentUser);
  }

  public logOut(): void {
    this.msalService.logoutRedirect();
    this.msalService.handleRedirectObservable().subscribe(() => localStorage.clear());
  }

  public openFeedbackDialog(): void {
    this.dialog
      .open(FeedbackDialogComponent, {
        width: '550px',
        height: 'fit-content',
      })
      .afterClosed()
      .pipe(
        filter(Boolean),
        switchMap((res) => this.feedbacks.create(res))
      )
      .subscribe(() => this.toastr.success('Your feedback was submitted successfully!'));
  }

  toggleMode(): void {
    this.darkMode.toggle();
  }

  redirectToProfile(id: string): void {
    if (this.router.url === `/profile/${id}`) {
      return;
    }

    void this.router.navigate(['profile', id]);
  }

  private listenNotification(): void {
    this.notifications.getAllPaged(0, 1).subscribe(({ count }) => this.notifications.unreadCount$.next(count));
    void this.notifications.startHub();
    this.notifications
      .onReceived()
      .subscribe(() => this.notifications.unreadCount$.next(this.notifications.unreadCount$.value + 1));
  }

  private initInboxCount(): void {
    this.inbox.getUnreadMessages().subscribe((value) => this.inbox.unreadCount$.next(value));
    this.inbox.onNewMessageCount();
  }
}
