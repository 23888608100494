<div class="flex">
  <button
    [disabled]="disabled"
    (mousedown)="startClick()"
    (mouseup)="stopClick()"
    (mouseleave)="stopClick()"
    class="flex timed-btn"
    [ngClass]="(darkMode$ | async) ? 'dark-mode-round' : ''"
  >
    <div class="loading" [class.fill]="decreaseTime !== null" [ngClass]="color"></div>
    <div class="text flex"><ng-content></ng-content></div>
  </button>

  <span class="hint">Press and hold</span>
</div>
