import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DestroyBaseComponent } from '@core/components/destroy-base/destroy-base.component';
import { UtilitiesService } from '@core/services/helpers/utilities/utilities.service';
import { AnimationEvent } from '@angular/animations';
import { collapseFade } from '@shared/animations/animations';
import { BehaviorSubject, takeUntil } from 'rxjs';
import { DarkModeService } from '@shared/services/dark-mode.service';
import { CommonModule } from '@angular/common';
import { ClickOutsideDirective } from '@shared/directives/click-outside.directive';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { LoadingComponent } from '@shared/components/loading/loading.component';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { PostImagesPreviewComponent } from '@shared/components/post-images-preview';
import { MediaInputComponent } from '@shared/components/inputs/media-input/media-input.component';
import { ContenteditableDirective } from '@shared/directives/contenteditable.directive';
import { MatMenuModule } from '@angular/material/menu';
import { EmojiPickerComponent } from '@shared/components/emoji-picker/emoji-picker.component';

@Component({
  selector: 'app-comment-editor',
  standalone: true,
  templateUrl: './comment-editor.component.html',
  styleUrls: ['./comment-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [collapseFade],
  imports: [
    CommonModule,
    ClickOutsideDirective,
    ReactiveFormsModule,
    PickerModule,
    LoadingComponent,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    EmojiPickerComponent,
    PostImagesPreviewComponent,
    MediaInputComponent,
    MatMenuModule,
    ContenteditableDirective,
  ],
})
export class CommentEditorComponent extends DestroyBaseComponent implements OnInit, AfterViewInit {
  @ViewChild('contentEditable', { static: false }) contentRef: ElementRef;
  @Input() expanded = false;
  @Input() editorFormGroup: FormGroup;
  @Input() loading$ = new BehaviorSubject<boolean>(false);
  @Output() handleComment = new EventEmitter<void>();

  editorState = false;
  placeholderData = 'Add a comment...';
  formMaxLength = 1500;
  darkMode$ = inject(DarkModeService).isDarkMode;
  private cursorPosition = 0;

  constructor(private readonly utilities: UtilitiesService, private readonly cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.editorFormGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.cdr.detectChanges());
  }

  ngAfterViewInit(): void {
    this.moveCursorAfterRange(this.contentRef?.nativeElement as HTMLElement);
  }

  public contentState(state: boolean): void {
    if (!this.expanded) {
      this.editorState = state;

      if (state) this.utilities.commentAnimDone$.next(false);
    }

    if (!state) {
      const comment = this.textControl.value;
      this.placeholderData = comment?.trim().length ? comment.slice(0, 150) : 'Add a comment...';
    }
  }

  public captureDoneEvent(doneEvent: AnimationEvent): void {
    if (!this.expanded && doneEvent.fromState === null) {
      this.utilities.commentAnimDone$.next(true);
    }
  }

  public emitComment(event: Event): void {
    if (this.editorFormGroup.invalid || this.textControl.disabled) {
      return;
    }

    this.handleComment.emit();
    event.preventDefault();
  }

  public onPasteHtml(e: ClipboardEvent): void {
    e.preventDefault();
    const pastedText = e.clipboardData?.getData('text');
    window.document.execCommand('insertText', false, pastedText);

    this.utilities.scrollToBottom(this.contentRef);
  }

  public get exceedLength(): number {
    return this.formMaxLength - this.textControl.value.length;
  }

  public get textControl(): FormControl {
    return <FormControl>this.editorFormGroup.controls['text'];
  }
  public get imageControl(): FormControl {
    return <FormControl>this.editorFormGroup.controls['image'];
  }

  public uploadImage(image: string): void {
    if (this.imageControl.value) this.imageControl.markAsDirty();
    this.imageControl.setValue(image);
  }

  public discardImage(): void {
    this.imageControl.reset();
  }
  public addReaction(event: any): void {
    if (event != undefined) {
      const elRef = this.contentRef.nativeElement;
      const { innerText } = elRef;
      const reaction = event.emoji.native as string;
      const selection = window.getSelection();
      const range = document.createRange();
      elRef.innerText = [
        innerText.slice(0, this.cursorPosition),
        reaction,
        innerText.slice(this.cursorPosition, innerText.length),
      ].join('');

      this.editorFormGroup.controls['text'].setValue(elRef.innerText);
      this.editorFormGroup.controls['text'].markAsDirty();
      setTimeout(() => {
        range.setStart(elRef.childNodes[0], this.cursorPosition + reaction.length);
        range.collapse(false);
        selection?.removeAllRanges();
        selection?.addRange(range);
        elRef.focus();
      });
    }
  }

  public getCursorPosition(): void {
    if (window.getSelection) {
      const sel = window.getSelection();
      if (sel?.getRangeAt && sel?.rangeCount > 0) {
        // getRangeAt() doesn't return last position of contenteditable on editing a post
        // and without setting cursors - inserted reaction will be added at the beginning of contenteditable
        // this condition checks if text length is greater than 0, cursor position will be the length of contenteditable
        this.cursorPosition =
          sel?.getRangeAt(0).startOffset < 1 && this.contentRef.nativeElement.innerText.length > 0
            ? this.contentRef.nativeElement.innerText.length
            : sel?.getRangeAt(0).startOffset;
      }
    }
  }

  private moveCursorAfterRange(el: HTMLElement) {
    if (el?.innerText) {
      window.setTimeout(() => {
        this.utilities.scrollToBottom(this.contentRef);
      });
    }
  }
}
