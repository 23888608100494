import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appButtonLoader]',
  standalone: true,
})
export class ButtonLoaderDirective {
  @Input() set appButtonLoader(loading: boolean) {
    if (loading) {
      this.renderer.addClass(this.el.nativeElement, 'loading-button');
      this.renderer.setProperty(this.el.nativeElement, 'disabled', true);

      const spinner: any = this.renderer.createElement('span');
      this.renderer.addClass(spinner, 'loading-button-spinner');
      this.renderer.appendChild(this.el.nativeElement, spinner);
    } else {
      this.renderer.removeClass(this.el.nativeElement, 'loading-button');
      const spinner: any = this.el.nativeElement.querySelector('.loading-button-spinner');
      if (spinner) {
        this.renderer.removeChild(this.el.nativeElement, spinner);
      }
      this.renderer.setProperty(this.el.nativeElement, 'disabled', false);
    }
  }

  constructor(private el: ElementRef, private renderer: Renderer2) {}
}
