import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { filter, map, switchMap } from 'rxjs';
import { Permission } from '@core/enums/permission.enum';
import { Store } from '@ngrx/store';
import { permissions$, permissionsWereLoaded$ } from '@store/user/user.selectors';
import { ToastrService } from 'ngx-toastr';

export const permissionsGuard = (allowedPermissions: Permission[], warnMsg: string): CanActivateFn => {
  return () => {
    const userStore = inject(Store);
    const router = inject(Router);
    const toastr = inject(ToastrService);

    return userStore.select(permissionsWereLoaded$).pipe(
      filter(Boolean),
      switchMap(() => userStore.select(permissions$)),
      map((permissions) => {
        if (permissions.length > 0 && allowedPermissions.length > 0) {
          for (let i = 0; i < allowedPermissions.length; i++) {
            if (permissions.includes(allowedPermissions[i])) return true;
          }
        }

        void router.navigate(['/feed'], { replaceUrl: true });
        toastr.info(warnMsg);
        return false;
      })
    );
  };
};
