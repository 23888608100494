import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BASE_URL } from '@core/constants/constants';
import { ContentCreator, ContentCreatorApplication } from '@features/profile/models/content-creator.model';
import { Observable } from 'rxjs';
import { PagedResponse } from '@core/models/paged-response.model';
import { buildUrl } from '@core/utils/buildUrl';
import { ContentCreatorStatus } from '@core/enums/permission.enum';

@Injectable({
  providedIn: 'root',
})
export class ContentCreatorService {
  constructor(private readonly http: HttpClient, @Inject(BASE_URL) private readonly baseUrl: string) {
    this.baseUrl += '/content-creator';
  }

  create(body: ContentCreator): Observable<string> {
    return this.http.post<string>(`${this.baseUrl}`, body);
  }

  getAllPaged(
    elementsCount: number,
    requestedElements: number,
    status: ContentCreatorStatus,
    name: string
  ): Observable<PagedResponse<ContentCreatorApplication>> {
    const url = buildUrl(`${this.baseUrl}/user/all`, { elementsCount, requestedElements, status, name });

    return this.http.get<PagedResponse<ContentCreatorApplication>>(url);
  }

  changeStatus(body: ContentCreator): Observable<string> {
    return this.http.patch<string>(`${this.baseUrl}`, body);
  }

  get latest(): Observable<{ status: ContentCreatorStatus }> {
    return this.http.get<{ status: ContentCreatorStatus }>(`${this.baseUrl}/latest`);
  }
}
