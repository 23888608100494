<div class="url-preview">
  <div class="url-preview__image" (click)="navigateToUrl()">
    <ng-container *ngIf="dialogUrlPreview; else postUrlImagePreview">
      <img src="{{ dialogUrlPreview.image }}" alt="image" />
    </ng-container>
  </div>
  <div class="url-preview__description" (click)="navigateToUrl()">
    <ng-container *ngIf="dialogUrlPreview; else postUrlInfo">
      <h1>{{ cutString(formGroup.controls['urlPreviewTitle'].value, 40) }}</h1>
      <p>{{ cutString(dialogUrlPreview.description, 120) }}</p>
    </ng-container>
  </div>
  <div *ngIf="dialogUrlPreview" (click)="closePreview.emit(true)">
    <mat-icon class="close-icon">close</mat-icon>
  </div>
</div>

<ng-template #postUrlImagePreview>
  <img *ngIf="postUrlPreview" src="{{ postUrlPreview.urlPreviewImage }}" alt="image" />
</ng-template>

<ng-template #postUrlInfo>
  <h1 *ngIf="postUrlPreview">{{ cutString(postUrlPreview.urlPreviewTitle, 40) }}</h1>
  <p *ngIf="postUrlPreview">{{ cutString(postUrlPreview.urlPreviewDescription, 120) }}</p>
</ng-template>
