import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptorService implements HttpInterceptor {
  constructor(private readonly toastr: ToastrService, private readonly router: Router) {}

  private truncateErr = (value: string, limit = 100) => {
    return value.length > limit ? `${value.substring(0, limit)}...` : value;
  };

  /* eslint-disable @typescript-eslint/no-explicit-any */
  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage;
        error.error['errors']
          ? Object.values(error.error['errors']).forEach((x) => {
              errorMessage = x as string;
            })
          : (errorMessage = error.error);
        if (error && error.status === 400 && errorMessage[0].indexOf('is not valid') !== -1) {
          void this.router.navigate(['/page-not-found']);
        } else {
          this.toastr.error(this.truncateErr(errorMessage));
        }
        return throwError(() => error);
      })
    );
  }
}
