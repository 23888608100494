import { ChangeDetectorRef, ElementRef, Pipe, PipeTransform, Renderer2 } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import truncate from 'truncate-html';

@Pipe({
  name: 'truncateText',
  standalone: true,
  pure: false,
})
export class TruncateTextPipe implements PipeTransform {
  private expandButton = '<button type="button" class="truncate-expand" id="expand"> see more </button>';
  private collapseButton = '<button type="button" class="truncate-expand" id="collapse"> see less </button>';
  private isTruncate = false;

  constructor(
    private readonly domSanitizer: DomSanitizer,
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    private readonly cdr: ChangeDetectorRef
  ) {
    this.renderer.listen(this.elementRef.nativeElement, 'click', ({ target }) => {
      if (target.id === 'expand' || target.id == 'collapse') {
        setTimeout(
          () => {
            this.isTruncate = !this.isTruncate;
            this.cdr.markForCheck();
          },
          this.isTruncate ? 300 : 0
        );
      }
    });
  }

  transform(value: string, maxLength: number): SafeHtml {
    const tempElementToTakeTextContent = document.createElement('div');
    tempElementToTakeTextContent.innerHTML = value;
    const textContent = tempElementToTakeTextContent.textContent ?? '';

    if (!value) return '';

    const bindHtml: any = (text: string) => {
      return `${text} ${this.expandButton}`;
    };

    const linesCount = value.split('</p>').length - 1;
    let index = -1;
    for (let i = 0; i < 8; i++) {
      index = value.indexOf('</p>', index + 1);
      if (index == -1) break;
    }

    const truncatedHtml = truncate(value, maxLength);

    if (this.isTruncate) return this.safeHTML(value + `${this.collapseButton}`);

    if (linesCount > 8) return this.safeHTML(bindHtml(value.substring(0, index)));

    if (textContent.length <= maxLength) return this.safeHTML(value);

    return this.safeHTML(bindHtml(truncatedHtml));
  }

  private safeHTML(value: string): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(value);
  }
}
