import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild, inject } from '@angular/core';
import { UtilitiesService } from '@core/services/helpers/utilities/utilities.service';
import { FormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-documents-input',
  standalone: true,
  templateUrl: './documents-input.component.html',
  styleUrls: ['./documents-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, MatIconModule, MatTooltipModule],
})
export class DocumentsInputComponent {
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  @Input() control: FormControl;
  @Input() maxDocuments = 1;

  private readonly utilities = inject(UtilitiesService);
  private readonly toastr = inject(ToastrService);

  public openDialog(): void {
    this.fileInput.nativeElement.click();
    this.fileInput.nativeElement.value = '';
  }

  public handleFileUpload(event: Event): void {
    if (event) {
      let listOfDocs;
      const file = (<HTMLInputElement>event.target).files![0];
      if (file.size === 0) {
        this.toastr.error('Document is empty!');
      } else {
        const extension = this.utilities.getFileExtension(file.name);
        const doc = { fileName: file.name, extension: extension, document: file };

        if (this.maxDocuments > 1) listOfDocs = [...this.control.value, doc];
        this.control.setValue(this.maxDocuments > 1 ? listOfDocs : [doc]);
      }
    }
  }
}
