import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { DarkModeService } from '@shared/services/dark-mode.service';
import { CommunityPreview } from '@core/models/community.model';
import { CommonModule } from '@angular/common';
import { SimpleTruncatePipe } from '@shared/pipes/simple-truncate.pipe';

@Component({
  selector: 'app-community-name',
  standalone: true,
  templateUrl: './community-name.component.html',
  styleUrls: ['./community-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, SimpleTruncatePipe],
})
export class CommunityNameComponent {
  @Input() community: CommunityPreview;

  darkMode$ = inject(DarkModeService).isDarkMode;
}
