import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DestroyBaseComponent } from '@core/components/destroy-base/destroy-base.component';
import { takeUntil } from 'rxjs';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { GroupedReactions, PublicationReactions } from '@core/models/common-publication-interfaces.model';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { FollowerPreviewComponent } from '@shared/components/follower-preview/follower-preview.component';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';

@Component({
  selector: 'app-reactions-dialog',
  standalone: true,
  templateUrl: './reactions-dialog.component.html',
  styleUrls: ['./reactions-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatIconModule,
    MatDialogModule,
    MatTabsModule,
    FollowerPreviewComponent,
    EmojiModule,
    MatMenuModule,
  ],
})
export class ReactionsDialogComponent extends DestroyBaseComponent implements OnInit {
  following = new Map<string, boolean>();
  selectedTab = 0;
  selectedReaction = '';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      currentUser: string;
      feedElementReactionsData: PublicationReactions[];
      groupedFeedElementReactions: GroupedReactions[];
    },
    private readonly dialogRef: MatDialogRef<ReactionsDialogComponent>,
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe(() => this.dialogRef.close());
  }

  public updateFollow(userId: string): void {
    this.data.feedElementReactionsData.forEach((item, index) => {
      if (item.userId === userId) {
        this.data.feedElementReactionsData[index].isFollowed = !this.data.feedElementReactionsData[index].isFollowed;
      }
    });

    this.following.set(userId, !this.following.get(userId));
    this.cdr.detectChanges();
  }

  public setSelectedOption(index: number): void {
    if (index <= 2) {
      this.selectedTab = index;
      this.selectedReaction = '';
    } else {
      this.selectedTab = index + 1;
      this.selectedReaction = this.data.groupedFeedElementReactions[index]?.reaction;
    }
  }

  public triggerMenu(event: Event, moreMenuTrigger: MatMenuTrigger): void {
    event.stopPropagation();
    moreMenuTrigger.openMenu();
  }
}
