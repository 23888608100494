import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { Post } from '@core/models/post.model';
import { Observable } from 'rxjs';
import { DarkModeService } from '@shared/services/dark-mode.service';
import { DateTimeConvertService } from '@core/services/helpers/date-time-convert/date-time-convert.service';
import { Router } from '@angular/router';
import { User } from '@core/models/user.model';
import { currentUser } from '@store/user/user.selectors';
import { Store } from '@ngrx/store';
import { CreateOverlayService } from '@shared/services/create-overlay.service';
import { CommonModule } from '@angular/common';
import { ProfileImageComponent } from '@shared/components/profile-image/profile-image.component';
import { UserNameComponent } from '@shared/components/user-name/user-name.component';
import { CommunityNameComponent } from '@shared/components/community-name/community-name.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TimeAgoPipe } from '@shared/pipes/time-ago.pipe';
import { TruncateTextPipe } from '@shared/pipes/truncate-text.pipe';
import { GalleryPreviewComponent } from '@shared/components/gallery-preview/gallery-preview.component';
import { AttachedFilesComponent } from '@shared/components/attached-files/attached-files.component';
import { UserInfoPreviewComponent } from '@shared/components/user-info-preview/user-info-preview.component';

@Component({
  selector: 'app-shared-post',
  standalone: true,
  templateUrl: './shared-post.component.html',
  styleUrls: ['./shared-post.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ProfileImageComponent,
    UserNameComponent,
    CommunityNameComponent,
    MatIconModule,
    MatTooltipModule,
    TimeAgoPipe,
    TruncateTextPipe,
    GalleryPreviewComponent,
    AttachedFilesComponent,
    UserInfoPreviewComponent,
  ],
})
export class SharedPostComponent implements OnInit {
  @ViewChild('author') author: ElementRef<HTMLElement>;
  @ViewChild('userInfoPreviewTemplate') userInfoPreviewTemplate: TemplateRef<any>;
  @Input() post: Post;

  sharedPostDate: string;
  sharedArticleDate: string;
  isPostEdited: boolean;
  isArticleEdited: boolean;
  hoveredUserId: string;
  darkMode$ = inject(DarkModeService).isDarkMode;

  constructor(
    private dateConvert: DateTimeConvertService,
    private router: Router,
    private store: Store,
    private viewContainerRef: ViewContainerRef,
    private createOverlayService: CreateOverlayService
  ) {}

  ngOnInit(): void {
    this.getIsEdited();
    this.convertDate();
  }

  public redirect(): void {
    if (this.post.sharedPost) {
      void this.router.navigate(['post', this.post.sharedPost.id], { queryParams: { path: this.router.url } });
    } else if (this.post.sharedArticle) {
      void this.router.navigate(['article/overview', this.post.sharedArticle.id]);
    }
  }

  public onUserNameMouseover(userId: string): void {
    this.hoveredUserId = userId;

    this.createOverlayService.showEventPreview(
      this.hoveredUserId,
      this.author.nativeElement,
      this.userInfoPreviewTemplate,
      this.viewContainerRef
    );
  }

  private getIsEdited(): void {
    if (this.post.sharedPost) {
      const createdDate = new Date(this.post.sharedPost.creationDate).getTime();
      const editedDate = new Date(this.post.sharedPost.lastEditDate).getTime();

      this.isPostEdited = createdDate !== editedDate;
    } else if (this.post.sharedArticle) {
      const createdDate = new Date(this.post.sharedArticle.creationDate).getTime();
      const editedDate = new Date(this.post.sharedArticle.lastEditDate).getTime();

      this.isArticleEdited = createdDate !== editedDate;
    }
  }

  private convertDate(): void {
    if (this.post.sharedPost) {
      this.sharedPostDate = this.dateConvert.convertToLocalTime(this.post.sharedPost.creationDate);
    } else if (this.post.sharedArticle) {
      this.sharedArticleDate = this.dateConvert.convertToLocalTime(this.post.sharedArticle.creationDate);
    }
  }

  public get user$(): Observable<User> {
    return this.store.select(currentUser);
  }
}
