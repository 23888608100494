import { UserState } from './user.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Storage } from '@core/enums/storage';

export const currentUserState = createFeatureSelector<UserState>('user');
export const currentUser = createSelector(currentUserState, (state) => state.user);
export const currentUserId = createSelector(currentUser, ({ userId }) => userId);
export const currentDisplayName = createSelector(currentUser, ({ displayName }) => displayName);
export const getCreatorStatus$ = createSelector(currentUserState, ({ status }) => status);
export const permissionsWereLoaded$ = createSelector(
  currentUserState,
  ({ rolesWereLoaded, statusWasLoaded }) => rolesWereLoaded && statusWasLoaded
);
export const permissions$ = createSelector(currentUserState, ({ permissions }) => {
  localStorage.setItem(Storage.permissions, JSON.stringify(permissions));

  return permissions;
});
