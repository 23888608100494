import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import { Observable, of } from 'rxjs';
import { DarkModeService } from '@shared/services/dark-mode.service';
import { IPageInfo, VirtualScrollerComponent, VirtualScrollerModule } from 'ngx-virtual-scroller';
import { AsyncPipe, NgClass, NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { LoadingComponent } from '@shared/components/loading/loading.component';

@Component({
  selector: 'app-infinite-scroll',
  standalone: true,
  templateUrl: './infinite-scroll.component.html',
  styleUrls: ['./infinite-scroll.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, AsyncPipe, NgClass, VirtualScrollerModule, NgForOf, NgTemplateOutlet, LoadingComponent],
})
export class InfiniteScrollComponent {
  @Output() scrolled = new EventEmitter<void>();
  @Input() hasMore = false;
  @Input() scrollLoading = false;
  @Input() itemsGap = 25;
  @Input() scrollRef: ElementRef | null;
  @Input() hasTabs = false;

  @ContentChild('list', { static: false }) templateRef: TemplateRef<HTMLElement>;
  @ViewChild(VirtualScrollerComponent, { static: false }) scroll: VirtualScrollerComponent;

  darkMode$ = inject(DarkModeService).isDarkMode;
  @Input() data$: () => Observable<any> = () => of();

  listenScroll(event: IPageInfo, listLength: number): void {
    if (event.endIndex < listLength * 0.7 || !this.hasMore || this.scrollLoading) return;
    this.scrolled.emit();
  }
}
