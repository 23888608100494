import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DestroyBaseComponent } from '@core/components/destroy-base/destroy-base.component';
import { UrlPreview } from '@core/models/url-preview.model';
import { PostUrl } from '@core/models/post.model';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-url-preview',
  standalone: true,
  templateUrl: './url-preview.component.html',
  styleUrls: ['./url-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule, NgIf],
})
export class UrlPreviewComponent extends DestroyBaseComponent {
  @Input() dialogUrlPreview?: UrlPreview;
  @Input() postUrlPreview?: PostUrl;
  @Input() title: string | null;
  @Input() formGroup: FormGroup;
  @Input() url: string;
  @Output() closePreview = new EventEmitter<boolean>();

  public navigateToUrl(): void {
    window.open(this.url, '_blank');
  }

  public cutString(str: string, maxLength: number): string {
    if (str.length >= maxLength) {
      return str.substring(0, maxLength) + '...';
    }

    return str;
  }
}
