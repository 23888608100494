<mat-card [ngClass]="{ 'dark-mode': darkMode$ | async }" class="create-post-box">
  <div class="overlay" *ngIf="!canPost">
    <span>Only members can post</span>
  </div>

  <ng-container *ngIf="user$ | async as user">
    <app-profile-image
      [photo]="user.profilePhotoThumbnail!"
      [name]="user.displayName"
      [userId]="user.userId"
      [redirectEnabled]="true"
    ></app-profile-image>

    <mat-card-title
      matRipple
      [ngClass]="{
        'create-post-box__feed-input': user.displayName,
        'skeleton-loader create-post-box__skeleton-feed-input': !user.displayName
      }"
      (click)="expandFeed()"
    >
      <span class="create-post-box__feed-input--input-item">
        {{ user.displayName ? "What's new, " + user.displayName + '?' : "What's popping?" }}
      </span>
      <span *ngIf="hasDraft" class="create-post-box__feed-input--draft">
        Draft
        <span class="hint">You have an unfinished post</span>
      </span>
    </mat-card-title>
    <ng-container *ngIf="currentCreatorStatus$ | async as currentCreatorStatus">
      <mat-card-subtitle *ngIf="currentCreatorStatus && currentCreatorStatus === ContentCreatorStatus.APPROVED">
        <button mat-icon-button (click)="storeOriginRoute()" matTooltip="Create article" class="create-article">
          <mat-icon>article</mat-icon>
        </button>
      </mat-card-subtitle>
    </ng-container>
  </ng-container>
</mat-card>
