import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit, ViewChild, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { DarkModeService } from '@shared/services/dark-mode.service';
import { DestroyBaseComponent } from '@core/components/destroy-base/destroy-base.component';
import { MAX_POST_SYMBOLS } from '@core/constants/constants';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { ProfileImageComponent } from '@shared/components/profile-image/profile-image.component';
import { MatButtonModule } from '@angular/material/button';
import { UserNameComponent } from '@shared/components/user-name/user-name.component';
import { CommunityNameComponent } from '@shared/components/community-name/community-name.component';
import { GalleryPreviewComponent } from '@shared/components/gallery-preview/gallery-preview.component';
import { AttachedFilesComponent } from '@shared/components/attached-files/attached-files.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { TimeAgoPipe } from '@shared/pipes/time-ago.pipe';
import { ContenteditableDirective } from '@shared/directives/contenteditable.directive';
import { EditSharedPublication, PublicationType } from '@core/models/publication.model';
import { patchPost, sharePublication } from '@store/posts-store/posts-store.actions';
import { EmojiPickerComponent } from '@shared/components/emoji-picker/emoji-picker.component';

@Component({
  selector: 'app-share-post-dialog',
  standalone: true,
  templateUrl: './share-post-dialog.component.html',
  styleUrls: ['./share-post-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatIconModule,
    MatCardModule,
    ReactiveFormsModule,
    MatDialogModule,
    ProfileImageComponent,
    MatButtonModule,
    UserNameComponent,
    CommunityNameComponent,
    GalleryPreviewComponent,
    AttachedFilesComponent,
    PickerModule,
    TimeAgoPipe,
    ContenteditableDirective,
    EmojiPickerComponent,
  ],
})
export class SharePostDialogComponent extends DestroyBaseComponent implements OnInit {
  @ViewChild('postTextarea', { static: false }) postTextarea: ElementRef;
  postFormGroup = new FormGroup({
    text: new FormControl(''),
  });
  cursorPosition = 0;
  publicationType = PublicationType;
  darkMode$ = inject(DarkModeService).isDarkMode;
  private clickOutsideCounter = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: EditSharedPublication,
    private readonly fb: FormBuilder,
    private readonly toastService: ToastrService,
    private readonly store: Store
  ) {
    super();
  }

  ngOnInit(): void {
    this._initPostFormGroup(this.data.sharingText || '');
  }

  public removeMarkdownFromText(markdownText: string): string {
    return markdownText.replace(/<[^>]*>/g, '').replace(/&[a-z]+;/gi, '');
  }

  public truncContent(content: string): string {
    content = this.removeMarkdownFromText(content);
    return content.length
      ? content.length > 200
        ? content.substring(0, 200).concat('...')
        : content.concat('...')
      : '';
  }
  public share(): void {
    const value = this.postFormGroup.value.text ? this.postFormGroup.value?.text.trim() : '';

    if (this._validateFormData(value)) {
      if (this.data.publicationType === this.publicationType.post) {
        if (this.data.isPostEdited && this.data.post) {
          this.data.post.text = value;
          this.store.dispatch(patchPost(this.data.post));
        } else {
          const post = { text: value, communityId: this.data.community?.communityId };
          this.store.dispatch(
            sharePublication({ id: this.data.id, publication: post, publicationType: this.data.publicationType })
          );
        }
      } else if (this.data.publicationType === this.publicationType.article) {
        const article = { text: value };
        this.store.dispatch(
          sharePublication({ id: this.data.id, publication: article, publicationType: this.data.publicationType })
        );
      }
    }
  }

  public addReaction(event: any): void {
    if (event != undefined) {
      const reaction = event.emoji.native as string;
      const elRef = this.postTextarea.nativeElement;
      const { innerText } = elRef;
      const selection = window.getSelection();
      const range = document.createRange();

      elRef.innerText = [
        innerText.slice(0, this.cursorPosition),
        reaction,
        innerText.slice(this.cursorPosition, innerText.length),
      ].join('');

      this.postFormGroup.controls['text'].setValue(elRef.innerText);
      this.postFormGroup.controls['text'].markAsDirty();
      setTimeout(() => {
        range.setStart(elRef.childNodes[0], this.cursorPosition + reaction.length);
        range.collapse(false);
        selection?.removeAllRanges();
        selection?.addRange(range);
        elRef.focus();
      });
    }
  }

  public getCursorPosition(): void {
    if (window.getSelection) {
      const sel = window.getSelection();

      if (sel?.getRangeAt && sel?.rangeCount > 0) {
        // getRangeAt() doesn't return last position of contenteditable on editing a post
        // and without setting cursors - inserted reaction will be added at the begging of contenteditable
        // this condition verify if cursor not setted and the length of text is bigger than 0, cursor position will be the length of contenteditable
        this.cursorPosition =
          sel?.getRangeAt(0).startOffset <= 1 && this.postTextarea.nativeElement.innerText.length > 0
            ? this.postTextarea.nativeElement.innerText.length
            : sel?.getRangeAt(0).startOffset;
      }
    }
  }

  private _validateFormData(value: string): boolean {
    if (value.length > MAX_POST_SYMBOLS) {
      this.toastService.error(`Maximum of characters allowed is ${MAX_POST_SYMBOLS}`);
      return false;
    }
    return true;
  }

  private _initPostFormGroup(value = ''): void {
    this.postFormGroup = this.fb.group({
      text: [value, [Validators.maxLength(MAX_POST_SYMBOLS)]],
    });
  }

  private get isEmptySpace(): boolean {
    return !this.postTextarea?.nativeElement.innerText.trim().length;
  }

  public get isFormInvalid(): boolean {
    return this.isEmptySpace || !(this.characterCounter > 0 && this.characterCounter < MAX_POST_SYMBOLS);
  }

  public get characterCounter(): number {
    const text = this.postFormGroup.get('text')?.value || '';
    return text.replace(/&nbsp;/g, ' ').replace(/<[^>]+>/g, '').length;
  }

  public get MAX_POST_SYMBOLS(): number {
    return MAX_POST_SYMBOLS;
  }
}
