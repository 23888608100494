import { formatDate } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateTimeConvertService {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  public convertToLocalTime(dateString: string, format: string = 'EEEE, d MMM y, HH:mm'): string {
    const date = new Date(dateString);
    return formatDate(date, format, this.locale);
  }
}
