import { Injectable, inject } from '@angular/core';
import { BASE_URL } from '@core/constants/constants';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PagedResponse } from '@core/models/paged-response.model';
import { buildUrl } from '@core/utils/buildUrl';
import { bodyFormData } from '@core/utils/formData';
import { PinPath } from '@core/enums/pin-path';
import { Params } from '@angular/router';

@Injectable()
export abstract class FeedElementBaseService<T> {
  public readonly http = inject(HttpClient);
  public baseUrl = inject(BASE_URL);

  constructor(path = '') {
    this.baseUrl += `/${path}`;
  }

  getAllPaged(elementsCount: number, requestedElements: number, params?: Params): Observable<PagedResponse<T>> {
    const url = buildUrl(`${this.baseUrl}`, {
      elementsCount,
      requestedElements,
      params,
    });

    return this.http.get<PagedResponse<T>>(url);
  }

  getById(id: string): Observable<T> {
    return this.http.get<T>(`${this.baseUrl}/${id}`);
  }

  create<M>(body: M, isFormData = false): Observable<T> {
    const formData = bodyFormData<M>(body);

    return this.http.post<T>(`${this.baseUrl}`, isFormData ? formData : body);
  }

  update<M>(body: M, id: string): Observable<T> {
    const formData = bodyFormData<M>(body);

    return this.http.patch<T>(`${this.baseUrl}/${id}`, formData);
  }

  delete(id: string, body?: object): Observable<T> {
    return this.http.delete<T>(`${this.baseUrl}/${body ? '' : id}`, { body });
  }

  pin(id: string, suffix: PinPath): Observable<T> {
    return this.http.post<T>(`${this.baseUrl}/${suffix}/${id}/pin`, {});
  }

  unpin(id: string, suffix: PinPath): Observable<T> {
    return this.http.delete<T>(`${this.baseUrl}/${suffix}/${id}/pin`);
  }

  share<M>(id: string, body: M): Observable<T> {
    const formData = bodyFormData<M>(body);

    return this.http.post<T>(`${this.baseUrl}/${id}/share`, formData);
  }

  // TODO: Create common endpoint to get user posts & articles
  getByIdPaged(id: string, elementsCount: number, requestedElements: number): Observable<PagedResponse<T>> {
    const url = buildUrl(`${this.baseUrl}/users/${id}`, {
      elementsCount,
      requestedElements,
    });

    return this.http.get<PagedResponse<T>>(url);
  }
}
