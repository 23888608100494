import { Component, HostListener, Input, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs';
import { DestroyBaseComponent } from '@core/components/destroy-base/destroy-base.component';
import { currentUser, getCreatorStatus$ } from '@store/user/user.selectors';
import { Store } from '@ngrx/store';
import { DraftPost } from '@core/models/post.model';
import { DarkModeService } from '@shared/services/dark-mode.service';
import { UserStatusType } from '@core/models/community.model';
import { PostInputDialogComponent } from '@shared/components/dialogs/post-input-dialog/post-input-dialog.component';
import { PostsService } from '@shared/services/posts/posts.service';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { ProfileImageComponent } from '@shared/components/profile-image/profile-image.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { Storage } from '@core/enums/storage';
import { ContentCreatorStatus } from '@core/enums/permission.enum';
import { MatRippleModule } from '@angular/material/core';

@Component({
  selector: 'app-create-post-box',
  standalone: true,
  templateUrl: './create-post-box.component.html',
  styleUrls: ['./create-post-box.component.scss'],
  imports: [
    CommonModule,
    MatCardModule,
    RouterLink,
    MatIconModule,
    ProfileImageComponent,
    MatTooltipModule,
    MatButtonModule,
    MatRippleModule,
  ],
})
export class CreatePostBoxComponent extends DestroyBaseComponent implements OnInit {
  @Input() communityId?: string;
  @Input() isMember?: string;
  @Input() currentUserStatus: UserStatusType;
  @Input() displayCreateEditArticleLink = true;

  hasDraft = false;
  draftedPost: DraftPost;
  user$ = this.store.select(currentUser);
  currentCreatorStatus$ = this.store.select(getCreatorStatus$);
  darkMode$ = inject(DarkModeService).isDarkMode;
  protected ContentCreatorStatus = ContentCreatorStatus;

  constructor(
    private readonly dialogService: MatDialog,
    private readonly store: Store,
    private readonly posts: PostsService,
    private readonly router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.posts.draft$.subscribe((draftPost) => (this.draftedPost = draftPost));
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event): void {
    if (this.draftedPost?.text && this.draftedPost?.images) event.returnValue = false;
  }

  storeOriginRoute(): void {
    localStorage.setItem(Storage.route_to_compose_article, this.router.url);
    void this.router.navigate(['article/composer']);
  }

  public expandFeed(): void {
    if (!this.canPost) return;

    const { text, images, document, isPrivate } = this.draftedPost || {};

    this.dialogService
      .open(PostInputDialogComponent, {
        data: { communityId: this.communityId, text, images, document, isPrivate },
      })
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe((item) => {
        this.posts.draft$.next(item);
        this.hasDraft = !(!item.images.length && item.text === '');
      });
  }

  public get canPost(): boolean {
    return (
      (this.isMember && !['Rejected', 'Pending', 'Kicked', null].includes(this.currentUserStatus)) ||
      (this.isMember === undefined && this.communityId === undefined)
    );
  }
}
