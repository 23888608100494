import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { DestroyBaseComponent } from '@core/components/destroy-base/destroy-base.component';
import { FileFormats } from '@core/enums/file-formats';
import { DarkModeService } from '@shared/services/dark-mode.service';
import { MediaUtilitiesService } from '@core/services/helpers/media-utilities/media-utilities.service';
import { map, takeUntil } from 'rxjs';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-media-input',
  standalone: true,
  templateUrl: './media-input.component.html',
  styleUrls: ['./media-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, NgClass, AsyncPipe, MatButtonModule, MatIconModule],
})
export class MediaInputComponent extends DestroyBaseComponent {
  @Input() compressionConfig = {
    minPixels: 200,
    maxCompressedPixels: 1000,
    minKiloBytes: 1024 * 500,
    validImageTypes: [FileFormats.PNG, FileFormats.JPG],
  };
  @Input() disabled = false;
  @Input() multiple = true;
  @Input() displayLabel?: string;
  @Input() iconColor = '';
  @Input() buttonColor = '';
  @Output() imageUpload = new EventEmitter<string>();

  darkMode$ = inject(DarkModeService).isDarkMode;

  constructor(private readonly media: MediaUtilitiesService) {
    super();
  }

  public compressImages(event: Event): void {
    const files = Array.from((<HTMLInputElement>event.target).files || []);
    (<HTMLInputElement>event.target).value = '';
    let i = 0;

    this.media
      .compressImages(files, this.compressionConfig)
      .pipe(
        map((images) => {
          if (images[i]) this.imageUpload.emit(images[i++]);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }
}
