import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { Post } from '@core/models/post.model';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  catchError,
  delay,
  delayWhen,
  filter,
  first,
  fromEvent,
  iif,
  of,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { User } from '@core/models/user.model';
import { DestroyBaseComponent } from '@core/components/destroy-base/destroy-base.component';
import { ActionType } from '@core/models/action.model';
import { currentUser } from '@store/user/user.selectors';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ConfirmationDialogComponent } from '@shared/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { DateTimeConvertService } from '@core/services/helpers/date-time-convert/date-time-convert.service';
import { DarkModeService } from '@shared/services/dark-mode.service';
import { MentionType } from '@core/enums/mention-type';
import { SharePostDialogComponent } from '@shared/components/dialogs/share-post-dialog/share-post-dialog.component';
import { MentionPreviewComponent } from '@shared/components/mention-preview/mention-preview.component';
import { CreateOverlayService } from '@shared/services/create-overlay.service';
import { PostInputDialogComponent } from '@shared/components/dialogs/post-input-dialog/post-input-dialog.component';
import { PostsService } from '@shared/services/posts/posts.service';
import { BookmarksService } from '@shared/services/bookmarks/bookmarks.service';
import { PinPath } from '@core/enums/pin-path';
import { FeedElementType } from '@core/models/feed-element.model';
import { CommonModule, Location } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { ProfileImageComponent } from '@shared/components/profile-image/profile-image.component';
import { UserNameComponent } from '@shared/components/user-name/user-name.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TimeAgoPipe } from '@shared/pipes/time-ago.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { TruncateTextPipe } from '@shared/pipes/truncate-text.pipe';
import { ReactionsComponent } from '@shared/components/reactions/reactions.component';
import { AttachedFilesComponent } from '@shared/components/attached-files/attached-files.component';
import { UrlPreviewComponent } from '@shared/components/url-preview/url-preview.component';
import { CommunityNameComponent } from '@shared/components/community-name/community-name.component';
import { GalleryPreviewComponent } from '@shared/components/gallery-preview/gallery-preview.component';
import { UserInfoPreviewComponent } from '@shared/components/user-info-preview/user-info-preview.component';
import { SharedPostComponent } from '@shared/components/shared-post/shared-post.component';
import { CommentBoxComponent } from '@shared/components/comments/comment-box/comment-box.component';
import { LoadingComponent } from '@shared/components/loading/loading.component';
import { Router, RouterLink } from '@angular/router';
import { decrementUserStats } from '@store/user/user.actions';

@Component({
  selector: 'app-post',
  standalone: true,
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatCardModule,
    ProfileImageComponent,
    UserNameComponent,
    MatIconModule,
    MatTooltipModule,
    TimeAgoPipe,
    MatButtonModule,
    MatMenuModule,
    TruncateTextPipe,
    ReactionsComponent,
    AttachedFilesComponent,
    UrlPreviewComponent,
    CommunityNameComponent,
    GalleryPreviewComponent,
    UserInfoPreviewComponent,
    SharedPostComponent,
    CommentBoxComponent,
    LoadingComponent,
    RouterLink,
  ],
})
export class PostComponent extends DestroyBaseComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('author') author: ElementRef<HTMLElement>;
  @ViewChild('userInfoPreviewTemplate') userInfoPreviewTemplate: TemplateRef<any>;
  @Input() post: Post;
  @Input() communityFeed = false;
  @Input() canUpdate = false;
  @Input() canPinPost = false;
  @Input() canInteract = true;
  @Input() redirect = false;
  @Input() emitUnbookmark = false;
  @Input() enableResized = false;
  @Output() pinUpdate = new EventEmitter<string>();
  @Output() delete = new EventEmitter<string>();
  @Output() resized = new EventEmitter<void>();
  @Output() updatePost = new EventEmitter<Post>();

  isPostEdited: boolean;
  isPostBookmarked: boolean;
  postCreatedDate: string;
  actionType = ActionType;
  actionEvent: Record<string, boolean> = { [ActionType.comment]: false };
  pinPath = PinPath;
  overlayRef: OverlayRef;
  isPreviewDisplayed = false;
  hoveredUserId: string;
  feedElementType = FeedElementType;
  resizeObserver = new ResizeObserver(() => this.resized.next());
  darkMode$ = inject(DarkModeService).isDarkMode;
  loading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly posts: PostsService,
    private readonly bookmarks: BookmarksService,
    private readonly dialog: MatDialog,
    private readonly store: Store,
    private readonly toastService: ToastrService,
    private readonly dateConvert: DateTimeConvertService,
    private readonly renderer: Renderer2,
    private readonly overlay: Overlay,
    private readonly el: ElementRef,
    private readonly overlayPositionBuilder: OverlayPositionBuilder,
    private readonly injector: Injector,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly createOverlayService: CreateOverlayService,
    private readonly router: Router,
    private readonly location: Location
  ) {
    super();
  }

  // base
  public ngOnInit(): void {
    this.getIsPostEdited();
    this.isPostBookmarked = this.post?.isBookmarked;
    this.postCreatedDate = this.dateConvert.convertToLocalTime(this.post.creationDate);
  }

  override ngOnDestroy(): void {
    this.resizeObserver.unobserve(this.el.nativeElement);
    super.ngOnDestroy();
  }

  //posts only
  ngAfterViewInit(): void {
    this.addMentionListener();
  }

  goBack(): void {
    this.location.back();
  }

  //post only
  private addMentionListener(): void {
    this.renderer.listen(this.el.nativeElement, 'mouseover', (event) => {
      this.onMentionMouseover(event);
    });
    this.renderer.listen(this.el.nativeElement, 'mouseout', (event) => {
      this.onMentionMouseout(event);
      this.onCloseUserPreview();
    });
  }

  //move to different component, user overlay
  public onUserNameMouseover(userId: string): void {
    this.hoveredUserId = userId;

    this.createOverlayService.showEventPreview(
      this.hoveredUserId,
      this.author.nativeElement,
      this.userInfoPreviewTemplate,
      this.viewContainerRef
    );
  }
  //move to different component, user overlay
  public onCloseUserPreview(): void {
    this.createOverlayService.closeEventPreview();
    this.hoveredUserId = '';
  }

  //posts only
  public onMentionMouseover(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (
      (String(target).includes(MentionType.Profile) && target.hasAttribute('href')) ||
      target.classList.contains('mention')
    ) {
      this.showOverlay(target, MentionType.Profile);
    } else if (
      (String(target).includes(MentionType.Community) && target.hasAttribute('href')) ||
      target.classList.contains('mention')
    ) {
      this.showOverlay(target, MentionType.Community);
    }
  }
  //posts only
  public onMentionMouseout(event: MouseEvent): void {
    if (this.overlayRef) {
      const target = event.target as HTMLElement;

      if (
        (String(target).includes(MentionType.Profile) && target.hasAttribute('href')) ||
        (String(target).includes(MentionType.Community) && target.hasAttribute('href'))
      ) {
        this.closeOverlayWithDelay();
      } else {
        this.isPreviewDisplayed = false;
        this.overlayRef.detach();
        this.overlayRef
          .outsidePointerEvents()
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.overlayRef.detach();
          });
      }
    }
  }

  //post only
  public closeOverlayWithDelay(): void {
    of(null)
      .pipe(
        delayWhen(() =>
          fromEvent(this.overlayRef.overlayElement, 'mouseleave').pipe(
            delay(1000),
            tap(() => (this.isPreviewDisplayed = false))
          )
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.overlayRef.detach());
  }

  //post
  public showOverlay(target: HTMLElement, mentionType: string): void {
    if (this.isPreviewDisplayed) return;
    this.isPreviewDisplayed = true;
    const positionStrategy = this.overlayPositionBuilder.flexibleConnectedTo(target).withPositions([
      {
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top',
        offsetY: 0,
      },
      {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom',
      },
    ]);

    const scrollStrategy = this.overlay.scrollStrategies.close();
    const overlayRef = this.overlay.create({ positionStrategy, scrollStrategy });
    const injector = Injector.create({
      parent: this.injector,
      providers: [
        {
          provide: 'MENTION_ID',
          useValue: { id: target.getAttribute('href')?.split('/').pop() ?? '', type: mentionType },
        },
      ],
    });
    overlayRef.attach(new ComponentPortal(MentionPreviewComponent, null, injector));

    this.overlayRef = overlayRef;
  }

  //base
  public editPost(post: Post): void {
    const { id, text, images, documents, isPrivate, communityId } = post;
    const stringImages = images.map((image) => image.imageURL);
    if (post.sharedPost || post.sharedArticle) {
      this.dialog
        .open(SharePostDialogComponent, {
          data: {
            publicationType: this.post.publicationType,
            text: post.sharedPost ? post.sharedPost.text : post.sharedArticle.text,
            sharingText: post.text,
            creationDate: this.post.creationDate,
            user: this.post.user,
            id: this.post.id,
            images: this.post.images,
            documents: this.post.documents,
            community: this.post.community,
            communityFeed: this.communityFeed,
            isPostEdited: true,
            post: post,
          },
        })
        .afterClosed()
        .subscribe();
    } else {
      this.dialog
        .open(PostInputDialogComponent, {
          data: {
            id,
            text,
            images: stringImages,
            documents,
            isPrivate,
            communityId,
            isEditDialog: true,
          },
        })
        .afterClosed()
        .pipe(filter(Boolean))
        .subscribe();
    }
  }

  //base
  public deletePost(id: string): void {
    this.dialog
      .open(ConfirmationDialogComponent, {
        width: '400px',
        autoFocus: false,
        data: {
          title: 'Delete',
          text: 'Are you sure you want to delete this post?',
          confirmBtnText: 'Delete',
          cancelBtnText: 'Cancel',
        },
      })
      .afterClosed()
      .pipe(
        first(),
        filter(Boolean),
        tap(() => this.loading$.next(true)),
        switchMap(() => this.posts.delete(id)),
        catchError(() => (this.loading$.next(false), EMPTY))
      )
      .subscribe(() => {
        this.delete.emit(id);
        this.store.dispatch(decrementUserStats({ key: 'postsCount' }));
        this.toastService.success('Post was deleted successfully.');
        if (this.postRedirectRoute) this.goBack();
      });
  }
  //base
  public bookmarkPost(id: string): void {
    iif(
      () => this.isPostBookmarked,
      this.bookmarks.delete(id, { feedElementId: id, feedElementType: FeedElementType.post }),
      this.bookmarks.create({ feedElementId: id, feedElementType: FeedElementType.post })
    )
      .pipe(
        catchError(() => {
          const err = this.isPostBookmarked ? 'Unable to bookmark post.' : 'Unable to remove post from bookmarks.';
          this.toastService.error(err);
          return EMPTY;
        })
      )
      .subscribe(() => {
        if (!this.isPostBookmarked && this.emitUnbookmark) this.delete.emit(id);

        this.toastService.success(`Bookmark ${this.isPostBookmarked ? 'added' : 'removed'} successfully!`);
      });

    this.isPostBookmarked = !this.isPostBookmarked;
  }

  //base
  public updatePinState(id: string, location: PinPath): void {
    this.post =
      location == PinPath.user
        ? {
            ...this.post,
            isPinnedByUser: !this.post.isPinnedByUser,
          }
        : {
            ...this.post,
            isPinnedByCommunity: !this.post.isPinnedByCommunity,
          };

    const update$ = this.isPinned ? this.posts.pin(id, location) : this.posts.unpin(id, location);

    update$.subscribe(() => this.pinUpdate.emit(id));
  }

  // base
  public getIsPostEdited(): void {
    const createdDate = new Date(this.post?.creationDate).getTime();
    const editedDate = new Date(this.post?.lastEditDate).getTime();
    this.isPostEdited = createdDate !== editedDate;
  }

  //base
  public countComments(commentsCount: number): void {
    this.post = {
      ...this.post,
      commentsCount,
    };

    this.updatePost.emit(this.post);
  }

  //base
  public setActionEvent(event: ActionType): void {
    this.actionEvent[event] = true;
    if (this.enableResized) this.resizeObserver.observe(this.el.nativeElement);

    if (event === this.actionType.share) {
      this.dialog
        .open(SharePostDialogComponent, {
          width: '600px',
          data: {
            publicationType: this.post.publicationType,
            text: this.post.text,
            creationDate: this.post.creationDate,
            user: this.post.user,
            id: this.post.id,
            images: this.post.images,
            documents: this.post.documents,
            community: this.post.community,
            communityFeed: this.communityFeed,
            isPostEdited: this.isPostEdited,
          },
        })
        .afterClosed()
        .subscribe();
    }
  }

  public get postRedirectRoute() {
    return this.router.url === `/post/${this.post.id}`;
  }

  //base
  public get user$(): Observable<User> {
    return this.store.select(currentUser);
  }

  //base
  public get isPinned(): boolean {
    return this.communityFeed ? this.post.isPinnedByCommunity : this.post.isPinnedByUser;
  }
}
