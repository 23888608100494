import { ChangeDetectionStrategy, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '@shared/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { filter, first } from 'rxjs';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { UtilitiesService } from '@core/services/helpers/utilities/utilities.service';

@Component({
  selector: 'app-comment-menu-options',
  standalone: true,
  templateUrl: './comment-menu-options.component.html',
  styleUrls: ['./comment-menu-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatButtonModule, MatMenuModule, MatIconModule],
})
export class CommentMenuOptionsComponent {
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  @Output() edit = new EventEmitter<void>();
  @Output() delete = new EventEmitter<void>();
  @Output() isMenuOpened = new EventEmitter<boolean>();

  menuOpen = false;

  constructor(private readonly dialogService: MatDialog, private readonly utilities: UtilitiesService) {}

  public setMenuState(): void {
    this.menuOpen = this.menuTrigger.menuOpen;
    this.isMenuOpened.emit(this.menuTrigger.menuOpen);
  }

  public openMatMenu(): void {
    this.utilities.commentAnimDone$.pipe(filter(Boolean), first()).subscribe(() => this.menuTrigger.openMenu());
  }

  public openDeleteDialog(): void {
    this.dialogService
      .open(ConfirmationDialogComponent, {
        width: '400px',
        autoFocus: false,
        data: {
          title: 'Delete',
          text: 'Are you sure you want to delete this comment?',
          confirmBtnText: 'Delete',
          cancelBtnText: 'Cancel',
        },
      })
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe(() => this.delete.emit());
  }
}
