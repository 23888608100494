// TODO: Request interceptor & handle-loading.service should be improved to accept multiple keys
export enum RequestActions {
  GET_USER = '[User] GET',
  SEARCH_USERS = '[People Search] GET',

  GET_COMMUNITIES = `[Communities] GET`,
  GET_COMMUNITY = `[Community] GET`,

  GET_COMMENTS = `[Comments] GET`,
  GET_MEMBERS = `[Members] GET`,

  GET_URL_PREVIEW = `[UrlPreview] GET`,

  CREATE_MESSAGE = `[MSG] CREATE MSG`,
}

export type RequestAction = RequestActions;

export interface RequestKey {
  action?: RequestAction;
  data?: unknown;
  cacheRequest?: boolean;
}
