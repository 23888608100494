import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, filter, iif, of, switchMap } from 'rxjs';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root',
})
export class MsalPermissionGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly msal: MsalService,
    private readonly msalBroadcast: MsalBroadcastService
  ) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const hasActiveAccount = this.msal.instance.getAllAccounts().length > 0;

    return this.msalBroadcast.inProgress$.pipe(
      filter((status) => status === InteractionStatus.None),
      switchMap(() =>
        iif(
          () => hasActiveAccount,
          of(true),
          (!hasActiveAccount && void this.router.navigate(['/login'], { replaceUrl: true }), of(false))
        )
      )
    );
  }
}
