import { Injectable } from '@angular/core';
import { MenuNavTitle, TabType, TabsGroupModel } from '@core/models/tabs.model';
import { map } from 'rxjs';
import { permissions$ } from '@store/user/user.selectors';
import { Store } from '@ngrx/store';
import { Permission } from '@core/enums/permission.enum';
import { NotificationsService } from '@shared/services/notifications/notifications.service';
import { InboxService } from '@shared/services/inbox/inbox.service';

@Injectable({
  providedIn: 'root',
})
export class MenuNavService {
  private permissions$ = this.store.select(permissions$);

  constructor(
    private readonly store: Store,
    private readonly notifications: NotificationsService,
    private readonly inbox: InboxService
  ) {}

  public menuGroup: TabsGroupModel[] = [
    {
      title: MenuNavTitle.homeFeed,
      route: '/feed',
      type: TabType.basic,
      icon: 'home',
      group: 'a',
    },
    {
      title: MenuNavTitle.people,
      route: '/people',
      type: TabType.basic,
      icon: 'group',
      group: 'a',
    },
    {
      title: MenuNavTitle.bookmarks,
      route: '/bookmarks',
      type: TabType.basic,
      icon: 'bookmarks',
      group: 'a',
    },
    {
      title: MenuNavTitle.content,
      route: '/content',
      type: TabType.basic,
      icon: 'article',
      group: 'a',
      permission: this.permissions$.pipe(map((x) => x.includes(Permission.CONTENT_CREATOR))),
    },
    {
      title: MenuNavTitle.contentSearch,
      route: '/content/search',
      type: TabType.basic,
      icon: 'quick_reference_all',
      group: 'b',
      permission: this.permissions$.pipe(map((x) => x.includes(Permission.CONTENT_CREATOR))),
    },
    {
      title: MenuNavTitle.inbox,
      route: '/inbox',
      type: TabType.basic,
      icon: 'mail',
      group: 'b',
      notificationCount: this.inbox.unreadCount$,
    },
    {
      title: MenuNavTitle.notifications,
      route: '/notifications',
      type: TabType.basic,
      icon: 'notifications',
      group: 'b',
      notificationCount: this.notifications.unreadCount$,
    },
    {
      title: MenuNavTitle.event,
      route: '/event',
      type: TabType.basic,
      icon: 'event',
      group: 'b',
    },
    {
      title: MenuNavTitle.applicationReview,
      route: '/application-review',
      type: TabType.basic,
      icon: 'action_key',
      group: 'a',
      permission: this.permissions$.pipe(map((x) => x.includes(Permission.ADMIN))),
    },
    {
      title: MenuNavTitle.userFeedback,
      route: '/users-feedback',
      type: TabType.basic,
      icon: 'feedback',
      group: 'a',
      permission: this.permissions$.pipe(map((x) => x.includes(Permission.ADMIN))),
    },
  ];
}
