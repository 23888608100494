import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BASE_URL } from '@core/constants/constants';
import { FeedElement, FeedElementType } from '@core/models/feed-element.model';
import { buildUrl } from '@core/utils/buildUrl';

@Injectable({
  providedIn: 'root',
})
export class ReactionsService {
  constructor(private readonly httpClient: HttpClient, @Inject(BASE_URL) private baseUrl: string) {}

  getAll(feedElementId: string, feedElementType: FeedElementType): Observable<any[]> {
    const url = buildUrl(`${this.baseUrl}/feed-element/${feedElementId}/emoji-reactions`, { feedElementType });
    return this.httpClient.get<any[]>(url);
  }

  add(body: {
    feedElementId: string;
    reaction: string;
    skinTone: number;
    feedElementType: FeedElementType;
  }): Observable<FeedElement> {
    return this.httpClient.post<FeedElement>(`${this.baseUrl}/emoji-reactions`, { ...body });
  }

  delete(params: { feedElementId: string; feedElementType: FeedElementType }): Observable<unknown> {
    const url = buildUrl(`${this.baseUrl}/emoji-reactions`, { params });
    return this.httpClient.delete<unknown>(url);
  }
}
