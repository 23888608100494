import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { CommunitiesState } from '@store/communities/communities.state';
import { ICommPag, UserRole } from '@core/models/community.model';
import { Member } from '@core/models/user.model';

export const selectCommunitiesState = createFeatureSelector<CommunitiesState>('communities');
export const selectCommunities = createSelector(selectCommunitiesState, (state: CommunitiesState) => state.entities);
export const selectJoinedCommunities = createSelector(
  selectCommunitiesState,
  (state: CommunitiesState) => state.joinedCommunities
);
export const selectRemainingCommunities = createSelector(
  selectCommunitiesState,
  (state: CommunitiesState) => state.remainingCommunities
);
export const selectNavigateFromJoined = createSelector(selectCommunitiesState, (state) => state.navigateFromJoined);
export const selectCommunity = createSelector(selectCommunitiesState, ({ community }) => community);
export const selectCommunityMembers = createSelector(selectCommunitiesState, (state) => state.members);
export const selectMembersByRole = (role: UserRole): MemoizedSelector<object, Member[]> =>
  createSelector(selectCommunityMembers, (members: Member[]) => {
    return members.filter((member) => member.role === role);
  });
export const selectMemberRoleCount = (role: UserRole): MemoizedSelector<object, number> =>
  createSelector(selectCommunityMembers, (members: Member[]) => {
    return members.filter((member) => member.role === role).length;
  });
export const selectMembersForPreview = (role: UserRole): MemoizedSelector<object, Member[]> =>
  createSelector(selectCommunityMembers, (members: Member[]) => {
    const filteredMembers = members.filter((member) => member.role === role);
    return filteredMembers.length > 3 ? filteredMembers.slice(0, 3) : filteredMembers;
  });
export const selectCommunityProfilePhoto = (id: string): MemoizedSelector<object, string | undefined> =>
  createSelector(
    selectCommunities,
    (communities: ICommPag) => communities.items.find((community) => community.id == id)?.profilePhoto
  );
