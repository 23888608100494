<span *ngIf="displayLabel" class="display-label">{{ displayLabel }}</span>

<button
  type="button"
  class="insert"
  [ngClass]="(darkMode$ | async) ? 'dark-mode-insert' : ''"
  [disabled]="disabled"
  [style.background]="buttonColor"
  (click)="fileInput.click()"
  mat-icon-button
>
  <mat-icon [style.color]="iconColor">add_photo_alternate</mat-icon>
</button>

<input
  (change)="compressImages($event)"
  #fileInput
  type="file"
  [accept]="compressionConfig.validImageTypes"
  [multiple]="multiple"
/>
