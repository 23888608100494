import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Observable, debounceTime, filter, map, of, switchMap, takeUntil, tap } from 'rxjs';
import { Router } from '@angular/router';
import { DarkModeService } from '@shared/services/dark-mode.service';
import { AsyncPipe, NgClass, NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { SearchInputComponent } from '@shared/components/inputs/search-input/search-input.component';
import { ProfileImageComponent } from '@shared/components/profile-image/profile-image.component';
import { UserNameComponent } from '@shared/components/user-name/user-name.component';
import { LoadingComponent } from '@shared/components/loading/loading.component';
import { MatIconModule } from '@angular/material/icon';
import { User } from '@core/models/user.model';
import { PagedResponse } from '@core/models/paged-response.model';
import { MatCardModule } from '@angular/material/card';
import { DestroyBaseComponent } from '@core/components/destroy-base/destroy-base.component';
import { CdkConnectedOverlay, CdkOverlayOrigin, ConnectedPosition } from '@angular/cdk/overlay';

@Component({
  selector: 'app-search',
  standalone: true,
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  imports: [
    NgClass,
    AsyncPipe,
    NgIf,
    NgForOf,
    SearchInputComponent,
    ProfileImageComponent,
    UserNameComponent,
    LoadingComponent,
    MatIconModule,
    MatCardModule,
    CdkConnectedOverlay,
    CdkOverlayOrigin,
    NgTemplateOutlet,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent extends DestroyBaseComponent {
  @Input() header = '';
  @Input() showMore = false;
  @Input() itemTemplate: TemplateRef<any> | null = null;
  @Input() contentClass = '';
  @Output() itemClick = new EventEmitter();

  @ViewChild(CdkOverlayOrigin) overlayOrigin: CdkOverlayOrigin;
  @ContentChild('item', { static: false }) templateRef: TemplateRef<HTMLElement>;

  control = new FormControl('');
  loading$ = new BehaviorSubject<boolean>(true);
  darkMode$ = inject(DarkModeService).isDarkMode;
  search$ = new BehaviorSubject<PagedResponse<User>>({
    items: [],
    hasMore: false,
    count: 0,
  });

  protected readonly positions: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'top',
      offsetX: 20,
    },
  ];

  @Input() data$: (value: string) => Observable<any> = () => of();

  constructor(private readonly router: Router) {
    super();
    this.listenInputChanges();
  }

  redirectToUser(): void {
    void this.router.navigateByUrl(`/people?search=${this.control.value ?? ''}`);
  }

  close({ target }: Event): void {
    const overlayOriginEl = this.overlayOrigin.elementRef.nativeElement;

    if (!overlayOriginEl.contains(target)) this.control.reset('');
  }

  private listenInputChanges(): void {
    this.control.valueChanges
      .pipe(
        tap(() => this.search$.next({ items: [], hasMore: false, count: 0 })),
        map((str) => str!.trim()),
        filter((str) => {
          if (str.length > 0 && str.length <= 50) {
            this.loading$.next(true);
            return true;
          } else return false;
        }),
        debounceTime(250),
        switchMap((search) => this.data$(search)),
        takeUntil(this.destroy$)
      )
      .subscribe((value) => (this.loading$.next(false), this.search$.next(value)));
  }
}
