export enum Permission {
  // User Roles
  ADMIN = 'ADMIN',
  CONTENT_CREATOR = 'CONTENT_CREATOR',

  // Content creator statuses
  APPROVED_CONTENT_CREATOR = 'APPROVED',
  DECLINED_CONTENT_CREATOR = 'DECLINED',
  PENDING_CONTENT_CREATOR = 'PENDING',
  NONE_CONTENT_CREATOR = 'NONE',
}

export enum ContentCreatorStatus {
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  PENDING = 'PENDING',
  NONE = 'NONE',
}
