import { ConnectedPosition } from '@angular/cdk/overlay';

export const positions: Record<string, ConnectedPosition[]> = {
  'bottom-center': [
    {
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
    },
  ],
  top: [
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom',
    },
  ],
  bellow: [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    },
  ],
};
