<mat-form-field appearance="outline" class="search-input">
  <input
    [formControl]="control"
    [ngClass]="(darkMode$ | async) ? 'dark-mode-placeholder' : ''"
    [placeholder]="placeholder"
    matInput
  />
  <mat-icon matPrefix> search </mat-icon>
  <button *ngIf="control.value" (click)="control.reset('')" matSuffix mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
  <mat-error *ngIf="control.errors?.['maxlength']"
    >Max. {{ control.errors?.['maxlength'].requiredLength }} characters!</mat-error
  >
</mat-form-field>
