import { ChangeDetectionStrategy, Component, Inject, OnInit, inject } from '@angular/core';
import { MentionType } from '@core/enums/mention-type';
import { RequestActions, RequestKey } from '@core/enums/request-keys';
import { CommunityMentionCardInfo } from '@core/models/community.model';
import { User } from '@core/models/user.model';
import { CommunityService } from '@shared/services/community.service';
import { UsersService } from '@shared/services/users/users.service';
import { UtilitiesService } from '@core/services/helpers/utilities/utilities.service';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { DarkModeService } from '@shared/services/dark-mode.service';
import { CommonModule } from '@angular/common';
import { ProfileImageComponent } from '@shared/components/profile-image/profile-image.component';
import { UserInitialsPipe } from '@shared/pipes/user-initials.pipe';
import { SimpleTruncatePipe } from '@shared/pipes/simple-truncate.pipe';
import { TimeAgoPipe } from '@shared/pipes/time-ago.pipe';
import { LoadingComponent } from '@shared/components/loading/loading.component';

@Component({
  selector: 'app-mention-preview',
  standalone: true,
  templateUrl: './mention-preview.component.html',
  styleUrls: ['./mention-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, ProfileImageComponent, UserInitialsPipe, SimpleTruncatePipe, TimeAgoPipe, LoadingComponent],
})
export class MentionPreviewComponent implements OnInit {
  private isLoadingDone$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  requestKey: RequestKey = { action: RequestActions.GET_USER, cacheRequest: true };
  public user!: User;
  public community!: CommunityMentionCardInfo;
  darkMode$ = inject(DarkModeService).isDarkMode;

  constructor(
    @Inject('MENTION_ID') public mention: { id: string; type: string },
    private readonly users: UsersService,
    private readonly communityService: CommunityService,
    private readonly utilitiesService: UtilitiesService
  ) {}

  ngOnInit(): void {
    if (this.mention.type === MentionType.Profile && this.mention.id !== '') {
      this.utilitiesService.IsAlreadyStoredUserMention(this.mention.id).subscribe((isUserSaved) => {
        isUserSaved
          ? this.utilitiesService
              .getStoredUserMention(this.mention.id)
              .pipe(
                catchError((error: unknown) =>
                  throwError(() => {
                    this.isLoadingDone$.next(true);
                    return error;
                  })
                )
              )
              .subscribe((user) => {
                this.user = user;
                this.isLoadingDone$.next(true);
              })
          : this.users
              .getById(this.mention.id, false)
              .pipe(
                catchError((error: unknown) =>
                  throwError(() => {
                    this.isLoadingDone$.next(true);
                    return error;
                  })
                )
              )
              .subscribe((user) => {
                this.user = user;
                this.utilitiesService.addNewUserMention(user);
                this.isLoadingDone$.next(true);
              });
      });
    } else if (this.mention.type === MentionType.Community && this.mention.id !== '') {
      this.utilitiesService.IsAlreadyStoredCommunityMention(this.mention.id).subscribe((isCommunitySaved) => {
        isCommunitySaved
          ? this.utilitiesService
              .getStoredCommunityMention(this.mention.id)
              .pipe(
                catchError((error: unknown) =>
                  throwError(() => {
                    this.isLoadingDone$.next(true);
                    return error;
                  })
                )
              )
              .subscribe((community) => {
                this.community = community;
                this.isLoadingDone$.next(true);
              })
          : this.communityService
              .getCommunityMentionById(this.mention.id)
              .pipe(
                catchError((error: unknown) =>
                  throwError(() => {
                    this.isLoadingDone$.next(true);
                    return error;
                  })
                )
              )
              .subscribe((community) => {
                this.community = community;
                this.utilitiesService.addNewCommunityMention(community);
                this.isLoadingDone$.next(true);
              });
      });
    }
  }

  public get isLoaded$(): Observable<boolean> {
    return this.isLoadingDone$.asObservable();
  }
}
