import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, map } from 'rxjs';
import { User } from '@core/models/user.model';
import { CommunityMentionCardInfo } from '@core/models/community.model';

@Injectable({
  providedIn: 'root',
})
export class UtilitiesService {
  documentClickedTarget$ = new Subject<HTMLElement>();
  commentAnimDone$ = new BehaviorSubject(true);

  private _usersMentions = new BehaviorSubject<User[]>([]);
  private _communitiesMentions = new BehaviorSubject<CommunityMentionCardInfo[]>([]);

  public IsAlreadyStoredUserMention(userId: string): Observable<boolean> {
    return this._usersMentions.asObservable().pipe(map((users) => users.some((user) => user.userId === userId)));
  }

  public addNewUserMention(newUserMention: User): void {
    const userValue = this._usersMentions.value;
    this._usersMentions.next([...userValue, newUserMention]);
  }

  public getStoredUserMention(userId: string): Observable<User> {
    return this._usersMentions.asObservable().pipe(map((users) => users.find((user) => user.userId === userId)!));
  }

  public IsAlreadyStoredCommunityMention(communityId: string): Observable<boolean> {
    return this._communitiesMentions
      .asObservable()
      .pipe(map((communities) => communities.some((community) => community.id === communityId)));
  }

  public addNewCommunityMention(newCommunityMention: CommunityMentionCardInfo): void {
    const communityValue = this._communitiesMentions.value;
    this._communitiesMentions.next([...communityValue, newCommunityMention]);
  }

  public getStoredCommunityMention(communityId: string): Observable<CommunityMentionCardInfo> {
    return this._communitiesMentions
      .asObservable()
      .pipe(map((communities) => communities.find((community) => community.id === communityId)!));
  }

  public setEndOfContenteditable(elRef: HTMLElement): void {
    const range = document.createRange();
    const selection = window.getSelection();

    range.selectNodeContents(elRef);
    range.collapse(false);

    selection!.removeAllRanges();
    selection!.addRange(range);
  }

  public scrollToBottom(elRef: ElementRef): void {
    if (!elRef?.nativeElement) {
      return;
    }

    const { scrollHeight } = elRef.nativeElement;

    this.setEndOfContenteditable(<HTMLElement>elRef.nativeElement);

    setTimeout(() => {
      elRef.nativeElement.scrollTo(0, <number>scrollHeight);
    });
  }

  public getFileExtension(fileName: string): string | undefined {
    if (fileName.indexOf('.') === -1) {
      return;
    }
    const extension = fileName.split('.').pop();

    return `.${extension!}`;
  }
}
