import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BASE_URL } from '@core/constants/constants';
import { HttpClient } from '@angular/common/http';
import { UserSettings } from '@core/models/user-settings.model';
import { Storage } from '@core/enums/storage';

@Injectable({
  providedIn: 'root',
})
export class DarkModeService {
  private darkMode = new BehaviorSubject<boolean>(false);
  private isModeSetInLocalStorage: string | null;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(BASE_URL) private readonly baseUrl: string,
    private readonly http: HttpClient
  ) {
    this.baseUrl += '/user-settings';
  }

  public get isDarkMode(): Observable<boolean> {
    return this.darkMode.asObservable();
  }

  public toggle(): void {
    const isDarkTheme = !this.darkMode.value;
    this.setBodyClassList(isDarkTheme);
    this.darkMode.next(isDarkTheme);
    localStorage.setItem(Storage.is_dark_mode, String(isDarkTheme));
    this.http.patch<UserSettings>(`${this.baseUrl}/theme`, { darkTheme: isDarkTheme }).subscribe();
  }

  public getCurrentTheme(): void {
    this.isModeSetInLocalStorage = localStorage.getItem(Storage.is_dark_mode);
    if (this.isModeSetInLocalStorage) {
      this.darkMode.next(JSON.parse(localStorage.getItem(Storage.is_dark_mode) as string));
      this.setBodyClassList(this.darkMode.value);
    } else {
      this.http.get<{ darkTheme: boolean }>(`${this.baseUrl}/theme`).subscribe(({ darkTheme }) => {
        this.darkMode.next(darkTheme);
        this.setBodyClassList(this.darkMode.value);
        localStorage.setItem(Storage.is_dark_mode, String(darkTheme));
      });
    }
  }

  private setBodyClassList(darkMode: boolean): void {
    darkMode ? this.document.body.classList.add('dark-mode') : this.document.body.classList.remove('dark-mode');
  }
}
