/**
 * Builds an url object based on the options provided.
 *
 * @param string Base url for params to be applied
 * @param object options object. Adds every property as "?param=value". Supports string, array, number, objects
 * @returns URL
 */
export const buildUrl = (baseUrl: string, options: object, appendEmpty = false): string => {
  // const url = new URL(baseUrl, 'http://localhost');
  let params = '';
  Object.entries(options).forEach((option) => {
    if (option[1] === null || option[1] === undefined || option[1] == '') {
      if (appendEmpty) {
        // add the & if we have added other params
        params += params !== '' ? '&' : '';
        params += `${option[0]}=''`;
        // url.searchParams.append(option[0], '');
      }
    } else if (typeof option[1] === 'object' && !Array.isArray(option[1])) {
      Object.entries<object>(option[1]).forEach((opt) => {
        // url.searchParams.append(opt[0], opt[1].toString());
        params += params !== '' ? '&' : '';

        Array.isArray(opt[1])
          ? opt[1].forEach((x, i, array) => {
              params += `${opt[0]}=${encodeURIComponent(x.toString())}${i === array.length - 1 ? '' : '&'}`;
            })
          : (params += `${opt[0]}=${encodeURIComponent(opt[1]?.toString())}`);
      });
    } else {
      // url.searchParams.append(option[0], option[1].toString());
      params += params !== '' ? '&' : '';
      params += `${option[0]}=${encodeURIComponent(option[1].toString())}`;
    }
  });
  return baseUrl + '?' + params;
};
