import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DarkModeService } from '@shared/services/dark-mode.service';
import { RequestActions, RequestKey } from '@core/enums/request-keys';
import { UsersService } from '@shared/services/users/users.service';
import { User } from '@core/models/user.model';
import { CommonModule } from '@angular/common';
import { ProfileImageComponent } from '@shared/components/profile-image/profile-image.component';
import { TimeAgoPipe } from '@shared/pipes/time-ago.pipe';
import { LoadingComponent } from '@shared/components/loading/loading.component';

@Component({
  selector: 'app-user-info-preview',
  standalone: true,
  templateUrl: './user-info-preview.component.html',
  styleUrls: ['./user-info-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, ProfileImageComponent, TimeAgoPipe, LoadingComponent],
})
export class UserInfoPreviewComponent implements OnInit {
  @Input() userId: string;

  public requestKey: RequestKey = { action: RequestActions.GET_USER, cacheRequest: true };
  public user: User;
  darkMode$ = inject(DarkModeService).isDarkMode;
  private isLoadingDone$ = new BehaviorSubject<boolean>(false);

  constructor(private users: UsersService) {}

  ngOnInit(): void {
    this.users.getById(this.userId, false).subscribe((user) => {
      this.user = user;
      this.isLoadingDone$.next(true);
    });
  }

  public get isLoaded$(): Observable<boolean> {
    return this.isLoadingDone$.asObservable();
  }
}
