import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DarkModeService } from '@shared/services/dark-mode.service';
import { currentUser } from '@store/user/user.selectors';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs';
import { ContentCreator, ContentCreatorApplication } from '@features/profile/models/content-creator.model';
import { ConfirmationDialogComponent } from '@shared/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ContentCreatorService } from '@shared/services/users/content-creator.service';
import { MatCardModule } from '@angular/material/card';
import { AsyncPipe, CommonModule } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ProfileImageComponent } from '@shared/components/profile-image/profile-image.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-content-creator-page',
  standalone: true,
  templateUrl: './content-creator-page.component.html',
  styleUrls: ['./content-creator-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatCardModule,
    MatProgressBarModule,
    ProfileImageComponent,
    MatDividerModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    AsyncPipe,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
  ],
})
export class ContentCreatorPageComponent implements OnInit {
  @Input() set applicationData(value: ContentCreatorApplication) {
    this.previewData = Object.assign({}, value);
  }
  previewData: ContentCreatorApplication;
  form = new FormGroup({
    interests: new FormControl('', [Validators.required, Validators.maxLength(500)]),
    requestReason: new FormControl('', [Validators.required, Validators.maxLength(500)]),
  });
  darkMode$ = inject(DarkModeService).isDarkMode;
  user$ = this.store.select(currentUser);

  constructor(
    private readonly store: Store,
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly toastr: ToastrService,
    private readonly contentCreator: ContentCreatorService
  ) {}

  ngOnInit(): void {
    this._initPreviewFields();
  }

  openPendingChangesPopup(id: string): void {
    if (this.form.dirty)
      this.dialog
        .open(ConfirmationDialogComponent, {
          width: '400px',
          height: '150px',
          data: {
            title: 'Discard changes',
            text: 'Any changes you made will be lost.',
            confirmBtnText: 'Ok',
            cancelBtnText: 'Cancel',
          },
        })
        .afterClosed()
        .pipe(filter(Boolean))
        .subscribe(() => this.goToProfile(id));
    else this.goToProfile(id);
  }

  apply(id: string): void {
    this.contentCreator.create(this.form.value as ContentCreator).subscribe(() => {
      this.goToProfile(id);
      this.toastr.success('Your application was submitted.');
    });
  }

  goToProfile(id: string): void {
    if (this.contentCreatorRoute) {
      void this.router.navigate(['profile', id]);
    }
  }

  trimValues(): void {
    Object.keys(this.form.controls).forEach((key) => {
      const control = this.form.get(key);
      if (control && typeof control.value === 'string') {
        control.setValue(control.value.trim());
      }
    });
  }

  private _initPreviewFields(): void {
    if (!this.contentCreatorRoute) {
      this.form.controls['interests'].setValue(this.previewData.interests as string);
      this.form.controls['interests'].setErrors(null);
      this.form.controls['requestReason'].setValue(this.previewData.requestReason as string);
      this.form.controls['requestReason'].setErrors(null);
    }
  }

  get contentCreatorRoute() {
    return this.router.url === '/profile/content-creator';
  }
}
