import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CommentsService } from '@shared/services/comments/comments.service';
import { User } from '@core/models/user.model';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormValidator } from '@core/validators/form-validator';
import { BehaviorSubject, filter, takeUntil } from 'rxjs';
import { DestroyBaseComponent } from '@core/components/destroy-base/destroy-base.component';
import { CommentReply } from '@core/models/comment.model';
import { ConvertorService } from '@core/services/helpers/file-convertor/convertor.service';
import { FeedElementType } from '@core/models/feed-element.model';
import { CommonModule } from '@angular/common';
import { CommentItemComponent } from '@shared/components/comments/comment-item/comment-item.component';
import { MatIconModule } from '@angular/material/icon';
import { ProfileImageComponent } from '@shared/components/profile-image/profile-image.component';
import { CommentEditorComponent } from '@shared/components/comments/comment-editor/comment-editor.component';
import { LoadingComponent } from '@shared/components/loading/loading.component';

@Component({
  selector: 'app-comment-reply',
  standalone: true,
  templateUrl: './comment-reply.component.html',
  styleUrls: ['./comment-reply.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    CommentItemComponent,
    MatIconModule,
    ProfileImageComponent,
    CommentEditorComponent,
    LoadingComponent,
  ],
})
export class CommentReplyComponent extends DestroyBaseComponent implements OnInit {
  @Input() user: User;
  @Input() repliesFormArray: FormArray;
  @Input() type: FeedElementType;
  @Input() formGroup: FormGroup;

  loadMoreReplies = false;
  replyCommentForm: FormGroup;
  isLoading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
    private readonly convertor: ConvertorService,
    private readonly comments: CommentsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.repliesFormArray.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.cdr.detectChanges());
    this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.cdr.detectChanges());
    this.initReplyCommentForm();
    this.initReplySection();
  }

  private initReplySection(): void {
    const { commentReplies, id } = this.formGroup.controls;

    this.listenAddedReplyComment(id.value, <FormArray>commentReplies);
    this.listenEditReplyComment(id.value, <FormArray>commentReplies);
    this.listenDeleteReplyComment(id.value, <FormArray>commentReplies);
  }

  public getReplies(loadMore = false): void {
    const { replyCount, id, hasMoreReplies } = this.formGroup.controls;

    this.loadMoreReplies = loadMore;
    this.comments.getAllRepliesPaged(id.value, this.oldRepliesLength, 3).subscribe({
      error: () => (this.loadMoreReplies = false),
      next: ({ items }) => {
        this.loadMoreReplies = false;
        this.replyItemFormGroup(items);
        hasMoreReplies.setValue(this.oldRepliesLength !== replyCount.value);
      },
    });
  }

  private listenAddedReplyComment(commentId: string, commentReplies: FormArray): void {
    this.comments
      .onCreateReply()
      .pipe(
        filter((value) => commentId === value.commentId),
        takeUntil(this.destroy$)
      )
      .subscribe((reply) => {
        const commentFormGroup = this._commentFormGroup();
        reply.reactions = [];
        reply.userReaction = null;
        commentFormGroup.patchValue({ ...reply, originalText: reply.text, justAdded: true });
        commentReplies.push(commentFormGroup);
      });
  }

  private listenEditReplyComment(commentId: string, commentReplies: FormArray): void {
    this.comments
      .onUpdateReply()
      .pipe(
        filter((value) => commentId === value.commentId),
        takeUntil(this.destroy$)
      )
      .subscribe((reply) => {
        const replyIndex = commentReplies.value.findIndex(({ id }: { id: string }) => id === reply.id);

        commentReplies.at(replyIndex).patchValue({ ...reply, originalText: reply.text, originalImage: reply.image });
      });
  }

  private listenDeleteReplyComment(commentId: string, commentReplies: FormArray): void {
    const { replyCount } = this.formGroup.controls;

    this.comments
      .onDeleteReply()
      .pipe(
        filter((value) => commentId === value.commentId),
        takeUntil(this.destroy$)
      )
      .subscribe((reply) => {
        const newReply = commentReplies.value.find(({ id }: { id: string }) => id === reply.id)?.justAdded;

        if (!newReply) {
          replyCount.setValue(replyCount.value - 1);
        }

        commentReplies.removeAt(commentReplies.value.findIndex(({ id }: { id: string }) => id === reply.id));
      });
  }

  private replyItemFormGroup(items: CommentReply[]): void {
    const { commentReplies } = this.formGroup.controls;

    items.forEach((x) => {
      const duplicateReply = commentReplies.value.find(({ id }: { id: string }) => id === x.id);
      const justAddedIndex = commentReplies.value.findIndex(({ justAdded }: { justAdded: boolean }) => justAdded);

      if (duplicateReply) {
        return;
      }

      const commentFormGroup = this._commentFormGroup();
      commentFormGroup.patchValue({ ...x, originalText: x.text });
      (<FormArray>commentReplies).insert(duplicateReply ? justAddedIndex : this.oldRepliesLength, commentFormGroup);
    });
  }

  private initReplyCommentForm(): void {
    this.replyCommentForm = this.fb.group(
      {
        text: [null, [Validators.maxLength(1500)]],
        image: [null],
      },
      { validators: FormValidator.emptySpaceValidator() }
    );
  }

  private _commentFormGroup(): FormGroup {
    return this.fb.group(
      {
        text: [{ value: null, disabled: false }, [Validators.maxLength(1500)]],
        originalText: null,
        originalImage: null,
        isEditing: false,
        isDeleting: false,
        id: null,
        commentId: null,
        user: null,
        creationDate: null,
        lastEditDate: null,
        image: null,
        postPin: false,
        justAdded: false,
        isReply: true,
        userReaction: null,
        reactions: [],
      },
      { validators: FormValidator.emptySpaceValidator() }
    );
  }

  public addReplyComment(): void {
    const { text, image } = this.replyCommentForm.controls;

    this.isLoading$.next(true);
    this.comments
      .createReply({
        commentId: this.formGroup.value.id,
        text: text.value,
        image: image.value ? this.convertor.convertToFile(image.value) : undefined,
      })
      .subscribe({
        error: () => this.isLoading$.next(false),
        next: () => {
          this.isLoading$.next(false);
          this.replyCommentForm.controls['text'].reset();
          this.replyCommentForm.controls['image'].reset();
        },
      });
  }

  public getFormGroupIndex(index: number): FormGroup {
    return <FormGroup>this.repliesFormArray.controls[index];
  }

  public get oldRepliesLength(): number {
    return this.formGroup.controls['commentReplies'].value.filter(({ justAdded }: { justAdded: boolean }) => !justAdded)
      .length;
  }
}
