import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Suggestion } from '@core/models/suggestion.model';
import { SuggestionsService } from '@shared/services/suggestions/suggestions.service';
import { UsersService } from '@shared/services/users/users.service';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';
import { MutualDialogComponent } from '@shared/components/dialogs/mutual-dialog/mutual-dialog.component';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { ProfileImageComponent } from '@shared/components/profile-image/profile-image.component';
import { SimpleTruncatePipe } from '@shared/pipes/simple-truncate.pipe';
import { ButtonLoaderDirective } from '@shared/directives/loading-button.directive';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-suggestion-card',
  standalone: true,
  templateUrl: './suggestion-card.component.html',
  styleUrls: ['./suggestion-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatCardModule,
    ProfileImageComponent,
    SimpleTruncatePipe,
    ButtonLoaderDirective,
    MatButtonModule,
    MatIconModule,
  ],
})
export class SuggestionCardComponent {
  @Input() suggestion: Suggestion;
  @Output() removeSuggestion = new EventEmitter<string>();

  public isCollapsed = false;
  public isLoading = false;

  constructor(
    private readonly users: UsersService,
    private readonly cdr: ChangeDetectorRef,
    private readonly toaster: ToastrService,
    private readonly matDialog: MatDialog,
    private readonly suggestions: SuggestionsService
  ) {}

  public collapse(): void {
    this.isCollapsed = true;

    this.suggestions.delete(this.suggestion.recommendedUser.userId).subscribe();
    setTimeout(() => this.removeSuggestion.emit(this.suggestion.recommendedUser.userId), 300);
  }

  public followUser(): void {
    this.isLoading = true;
    this.users
      .follow(this.suggestion.recommendedUser.userId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(() => {
        this.collapse();
        this.cdr.detectChanges();
        this.toaster.success('User followed successfully!');
      });
  }

  public openMutualDialog(): void {
    this.matDialog.open(MutualDialogComponent, {
      maxHeight: '65vh',
      minHeight: '10rem',
      width: '28rem',
      data: {
        userId: this.suggestion.recommendedUser.userId,
        userName: this.suggestion.recommendedUser.displayName,
        mutuals: this.suggestion.mutuals,
      },
    });
  }
}
