<div [ngClass]="{ 'dark-mode': (darkMode$ | async) }">
  <div class="flex-end">
    <h1>People you have in common with {{ data.userName }} </h1>

    <mat-icon class="close-icon" mat-dialog-close="true">close</mat-icon>
  </div>

  <div
    *ngFor="let user of data.mutuals; let odd = odd"
    [ngClass]="(darkMode$ | async) && !odd ? 'dark-mode-odd' : ''"
    class="user"
  >
    <app-profile-image
      mat-dialog-close="true"
      [userId]="user.userId"
      [name]="user.displayName"
      [photo]="user.profilePhotoThumbnail!"
    ></app-profile-image>

    <div class="user__info-box">
      <app-user-name
        [userId]="user.userId"
        [userName]="user.displayName"
        mat-dialog-close="true"
        class="user__info-box--name"
      ></app-user-name>
    </div>
  </div>
</div>
