import { Injectable } from '@angular/core';
import { base64ToFile } from 'ngx-image-cropper';

@Injectable({
  providedIn: 'root',
})
export class ConvertorService {
  public convertToFile(file: string): File {
    const blobFile = base64ToFile(file);

    return new File([blobFile], 'img.png', {
      lastModified: new Date().getTime(),
      type: blobFile.type,
    });
  }

  public isBase64Image(image: string): boolean {
    try {
      base64ToFile(image);
      return true;
    } catch {
      return false;
    }
  }
}
