<div class="wrapper">
  <button [mat-dialog-close]="false" mat-mini-fab style="position: absolute; z-index: 3; right: 10px; top: 10px">
    <mat-icon>close</mat-icon>
  </button>

  <div *ngIf="data.images.length > 1" [ngClass]="(darkMode$ | async) ? 'dark-mode-index' : ''" class="index">
    <button
      *ngFor="let image of data.images; let index = index"
      (click)="actualIndex = index; displayImage()"
      [disabled]="actualIndex === index"
      mat-mini-fab
    ></button>
  </div>

  <div *ngIf="data.images.length > 1" [ngClass]="(darkMode$ | async) ? 'dark-mode-arrow' : ''" class="arrow left">
    <button mat-mini-fab (click)="displayNext(direction.left)">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
  </div>
  <div class="images">
    <div
      class="swipe-content"
      [ngClass]="{ moving: isMoving }"
      [ngStyle]="{ transform: 'translateX(-' + 720 * displayedImageIndex + 'px)' }"
    >
      <div *ngFor="let image of displayedImages"
        ><img [ngSrc]="image.imageURL" fill [alt]="image.imageURL" style="object-fit: cover"
      /></div>
    </div>
  </div>
  <div *ngIf="data.images.length > 1" [ngClass]="(darkMode$ | async) ? 'dark-mode-arrow' : ''" class="arrow right">
    <button mat-mini-fab (click)="displayNext(direction.right)">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </div>
</div>
