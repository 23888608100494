import { Injectable } from '@angular/core';
import { CanMatch, UrlTree } from '@angular/router';
import { Observable, catchError, iif, of, switchMap } from 'rxjs';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root',
})
export class MsalLoginPermissionGuard implements CanMatch {
  constructor(private readonly msalService: MsalService, private readonly msalBroadcast: MsalBroadcastService) {}

  canMatch(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.msalBroadcast.inProgress$.pipe(
      switchMap(() => iif(() => this.msalService.instance.getAllAccounts().length > 0, of(false), of(true))),
      catchError(() => of(true))
    );
  }
}
