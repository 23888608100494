<div>
  <button
    class="insert"
    #emojiBtn="matMenuTrigger"
    [matMenuTriggerFor]="emoji"
    (click)="$event.preventDefault()"
    mat-icon-button
  >
    <mat-icon>add_reaction</mat-icon>
  </button>
  <mat-menu #emoji="matMenu" xPosition="after" backdropClass="emoji-menu" (closed)="onMenuClosed()">
    <emoji-mart
      (click)="$event.stopPropagation()"
      (emojiSelect)="onSelectedEmoji($event); emojiBtn.closeMenu()"
      [isNative]="true"
      [autoFocus]="false"
      [emojiTooltip]="false"
      [hideObsolete]="true"
      [useButton]="false"
      [totalFrequentLines]="2"
      [emojiSize]="20"
      [perLine]="8"
      [color]="'#45a29e'"
      [showPreview]="false"
      [ngClass]="{ 'dark-emoji-mart': darkMode$ | async }"
    >
    </emoji-mart>
  </mat-menu>
</div>
