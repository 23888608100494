<mat-chip-set [ngClass]="(darkMode$ | async) ? 'dark-mode-chip' : ''" style="padding-top: 0.625rem">
  <mat-chip *ngFor="let doc of control?.value || readonlyDocs; let i = index" (removed)="removeFile(i)">
    <a [href]="doc.url" [download]="doc.fileName" target="_blank" class="file-attachment__details">
      <ng-container *ngTemplateOutlet="fileIcon; context: { doc }"></ng-container>
      <span>{{ doc.fileName }}</span>
    </a>
    <button *ngIf="control?.value" matChipRemove><mat-icon>close</mat-icon></button>
  </mat-chip>
</mat-chip-set>

<ng-template #fileIcon let-doc="doc">
  <div class="extension-block">
    <i class="file"></i>
    <span [ngClass]="doc.extension | slice : 1" class="type">{{ doc.extension | slice : 1 }}</span>
  </div>
</ng-template>
