<mat-card *ngIf="event" class="event-card">
  <mat-card-header>
    <div class="image-container">
      <div *ngIf="event.image; else noEventImage" mat-card-avatar>
        <img class="event-image" [src]="event.image" alt="" />
      </div>
    </div>
    <mat-icon *ngIf="event.accepted" class="tick">task_alt</mat-icon>
    <mat-card-title [routerLink]="'/event/' + event.id">{{ event.title | simpleTruncate : 18 }}</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div class="details-line">
      <mat-icon>place</mat-icon>
      <div>
        <p> {{ event?.location ? (event.location | titlecase | simpleTruncate : 22) : 'Online' }} </p>
        <h6>Location</h6>
      </div>
    </div>

    <div class="details-line">
      <mat-icon>access_time</mat-icon>
      <div>
        <p>
          {{ event.startDate | date : 'EEE, dd MMM, h:mm a' }} – {{ event.endDate | date : 'EEE, dd MMM, h:mm a' }}
        </p>
        <h6>Time</h6>
      </div>
    </div>

    <div class="details-line">
      <ng-container *ngIf="!event.author.isDeleted; else unknownImage">
        <img
          *ngIf="event.author.profilePhotoThumbnail; else noUserImage"
          src="{{ event.author.profilePhotoThumbnail }}"
          alt=""
          class="user-image"
        />
      </ng-container>
      <ng-template #unknownImage>
        <mat-icon
          class="mat-icon-fill"
          style="
            width: 1.375rem;
            height: 1.375rem;
            padding: 0.5rem;
            font-size: 1.375rem;
            color: #9da9b9;
            background: #eeeef3;
            border-radius: 50%;
          "
          >person</mat-icon
        >
      </ng-template>
      <div>
        <ng-container *ngIf="!event.author.isDeleted; else unknownName">
          <p class="author" [routerLink]="'/profile/' + event.author.authorId"> {{ event.author?.displayName }} </p>
        </ng-container>
        <ng-template #unknownName>
          <p class="author">Unknown</p>
        </ng-template>
        <h6>Organizer</h6>
      </div>
    </div>
    <hr *ngIf="event.speakers.length" />
    <div *ngIf="event.speakers.length" class="details-line wrap-speakers">
      <ng-container *ngFor="let speaker of event.speakers">
        <div *ngIf="speaker.profilePhotoThumbnail; then image; else noImage"></div>
        <ng-template #image>
          <div class="details-line">
            <span><img class="user-image margin" src="{{ speaker.profilePhotoThumbnail }}" alt="" /> </span>
            <p class="author margin" [routerLink]="'/profile/' + speaker.userId"> {{ speaker.displayName }} </p>
          </div>
        </ng-template>
        <ng-template #noImage>
          <div class="details-line">
            <span class="user-initials-no-name avatar-user">{{ speaker.displayName | userInitials }}</span>
            <p class="author margin" [routerLink]="'/profile/' + speaker.userId"> {{ speaker.displayName }} </p>
          </div>
        </ng-template>
      </ng-container>
    </div>
    <div *ngIf="event.speakers.length" class="label">
      <h6>Speakers</h6>
    </div>
  </mat-card-content>
</mat-card>

<ng-template #noEventImage>
  <span class="user-initials"> <mat-icon class="avatar-icon">event</mat-icon> </span>
</ng-template>

<ng-template #noUserImage>
  <span class="user-initials avatar-user mat-icon">{{ event.author.displayName | userInitials }}</span>
</ng-template>
