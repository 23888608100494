import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, Subject, takeUntil } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CustomHttpParams } from '@core/services/helpers/handle-loading/handle-loading.service';

@Injectable()
export class DuplicateRequestsInterceptorService implements HttpInterceptor {
  private cache = new Map();
  private cancelNotifier$ = new Subject<void>();

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.method === 'GET') {
      return next.handle(request);
    }

    const requestKey = this.getRequestKey(request);

    if (this.cache.has(requestKey)) this.cache.get(requestKey).next();

    this.cache.set(requestKey, this.cancelNotifier$);

    return next.handle(request).pipe(
      takeUntil(this.cancelNotifier$),
      tap({
        next: (event) => {
          if (event instanceof HttpResponse) {
            this.cache.delete(requestKey);
          }
        },
        error: () => this.cache.delete(requestKey),
      })
    );
  }

  private getRequestKey(req: HttpRequest<unknown>): string {
    return `${req.method} ${req.urlWithParams} ${JSON.stringify((<CustomHttpParams>req.params)?.key?.data)}`;
  }
}
