import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SideMenuComponent } from '@shared/components/side-menu/side-menu.component';
import { CommentsService } from '@shared/services/comments/comments.service';
import { UtilitiesService } from '@core/services/helpers/utilities/utilities.service';
import { InboxService } from '@shared/services/inbox/inbox.service';
import { DarkModeService } from '@shared/services/dark-mode.service';

@Component({
  selector: 'app-layout',
  standalone: true,
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterOutlet, SideMenuComponent],
})
export class LayoutComponent {
  constructor(
    private readonly comments: CommentsService,
    private readonly inbox: InboxService,
    private readonly utilities: UtilitiesService,
    private readonly darkMode: DarkModeService
  ) {
    this.darkMode.getCurrentTheme();
    void this.comments.startHub();
    void this.inbox.startHub();
  }

  @HostListener('document:click', ['$event'])
  documentClick({ target }: Event): void {
    this.utilities.documentClickedTarget$.next(<HTMLElement>target);
  }
}
