import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestActions, RequestKey } from '@core/enums/request-keys';
import { BehaviorSubject, Observable, of, switchMap, takeWhile } from 'rxjs';

export class CustomHttpParams extends HttpParams {
  constructor(public key: RequestKey) {
    super();
  }
}

@Injectable({
  providedIn: 'root',
})
export class HandleLoadingService {
  private removedRequest$ = new BehaviorSubject<RequestKey>({});
  private cachedRequests: RequestActions[] = [];
  private isPageLoadingSubj$ = new BehaviorSubject<boolean>(false);

  public removeRequest(key: RequestKey): void {
    this.removedRequest$.next(key);

    if (key?.cacheRequest && key.action) {
      this.cachedRequests.push(key.action);
    }
  }

  public isRequestFinalized(key: RequestKey): Observable<boolean> {
    return this.removedRequest$.asObservable().pipe(
      switchMap((request) => {
        if (key.action && this.cachedRequests.includes(key.action)) return of(true);

        return of(request && key && JSON.stringify(request) == JSON.stringify(key));
      }),
      takeWhile((isFinalized) => !isFinalized, true)
    );
  }

  public showFullPageLoader(): void {
    this.isPageLoadingSubj$.next(true);
  }

  public hideFullPageLoader(): void {
    this.isPageLoadingSubj$.next(false);
  }

  public get isLoading$(): Observable<boolean> {
    return this.isPageLoadingSubj$.asObservable();
  }
}
