import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '@shared/shared.module';
import { ToastrModule } from 'ngx-toastr';
import { StoreModule } from '@ngrx/store';
import { userReducer } from '@store/user/user.reducers';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from '@store/user/user.effects';
import { postsReducer } from '@store/posts-store/posts-store.reducers';
import { PostsStoreEffects } from '@store/posts-store/posts-store.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@environments/environment';
import { CommunitiesEffects } from '@store/communities/communities.effects';
import { communitiesReducer } from '@store/communities/communities.reducers';
import { CoreModule } from '@core/core.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    CoreModule.forRoot(environment.apiUrl),
    ToastrModule.forRoot(),
    StoreModule.forRoot(
      {
        user: userReducer,
        posts: postsReducer,
        communities: communitiesReducer,
      },
      {
        runtimeChecks: {
          strictActionWithinNgZone: true,
        },
      }
    ),
    EffectsModule.forRoot([UserEffects, PostsStoreEffects, CommunitiesEffects]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
