import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import { Configuration, IPublicClientApplication, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { environment } from '@environments/environment';

export function MSALInterceptorConfig(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, string[]>();
  protectedResourceMap.set(environment.msal.msalInterceptor.apiUrl, environment.msal.msalInterceptor.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfig(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: environment.msal.msalInterceptor.scopes,
    },
    loginFailedRoute: '/login',
  };
}

export function MSALFactoryConfig(msalConfig: Configuration): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    MsalService,
    MsalGuard,
  ],
  imports: [CommonModule, MsalModule, MatButtonModule],
  bootstrap: [MsalRedirectComponent],
})
export class MsalLoginModule {
  static forRoot(msalConfig: Configuration): ModuleWithProviders<MsalLoginModule> {
    return {
      ngModule: MsalLoginModule,
      providers: [
        {
          provide: MSAL_INSTANCE,
          useFactory: () => MSALFactoryConfig(msalConfig),
        },
        {
          provide: MSAL_INTERCEPTOR_CONFIG,
          useFactory: () => MSALInterceptorConfig(),
        },
        {
          provide: MSAL_GUARD_CONFIG,
          useFactory: () => MSALGuardConfig(),
        },
      ],
    };
  }
}
