<div [ngClass]="(darkMode$ | async) ? 'dark-mode-rich-text-editor' : ''" class="rich-text-editor">
  <h3 *ngIf="headerTitle">{{ headerTitle }}</h3>

  <quill-editor
    [formControl]="textCtrl"
    [theme]="theme"
    [modules]="modules"
    [placeholder]="placeholder"
    [ngClass]="(darkMode$ | async) ? 'dark-mode-content' : ''"
    [maxLength]="contentMaxLength"
    classes="quill-editor"
    bounds="self"
  >
  </quill-editor>

  <mat-hint *ngIf="quill && textCtrl.value" align="end"
    >{{ quill.editorElem.innerText.length }} / {{ contentMaxLength }}</mat-hint
  >
  <mat-error *ngIf="textCtrl.errors?.['maxLength']"> Character limit exceeded </mat-error>

  <!--<ng-container *ngIf="enableUrlPreview">
    <app-url-preview [formGroup]="formGroup" [title]="formGroup.controls['urlPreviewTitle'].value" />
  </ng-container>-->
</div>
