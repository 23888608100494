<div class="dialog" [ngClass]="{ 'dark-mode': (darkMode$ | async) }">
  <div class="header">
    <div class="icon">
      <mat-icon>feedback</mat-icon>
    </div>
    <div>
      <div class="title">Help us Improve, anonymously.</div>
      <div class="subtitle">Leave your feedback here!</div>
    </div>
  </div>

  <mat-form-field>
    <textarea
      class="input"
      [formControl]="textControl"
      #text
      matInput
      rows="8"
      type="text"
      placeholder="Write your message to us here..."
      maxlength="300"
      minlength="10"
      autocomplete="off"
      tabindex="-1"
      required
    ></textarea>
    <mat-error *ngIf="textControl.hasError('minlength')">
      The minimum length for this field is {{ text.minLength }} characters
    </mat-error>
    <mat-hint *ngIf="textControl.enabled" align="end"> {{ text.value.length }} / {{ text.maxLength }} </mat-hint>
  </mat-form-field>

  <mat-dialog-actions align="center">
    <button class="cancel-button" mat-flat-button color="warn" [mat-dialog-close]="false">Cancel</button>
    <button class="confirm-button" mat-flat-button color="primary" [disabled]="!textControl.valid" (click)="submit()">
      Submit
    </button>
  </mat-dialog-actions>
</div>
