import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, forkJoin, map, of, switchMap, throwError } from 'rxjs';
import {
  Community,
  CommunityImagesEndpoints,
  CommunityJoinRequest,
  CommunityMention,
  CommunityMentionCardInfo,
  CommunityMinimised,
  CommunityPostDto,
  ISearchUser,
  RoleChangeResponse,
  UserRole,
} from '@core/models/community.model';
import { Member } from '@core/models/user.model';
import { Post } from '@core/models/post.model';
import { PagedResponse } from '@core/models/paged-response.model';
import { CustomHttpParams } from '@core/services/helpers/handle-loading/handle-loading.service';
import { RequestActions } from '@core/enums/request-keys';
import { BASE_URL } from '@core/constants/constants';
import { bodyFormData } from '@core/utils/formData';

@Injectable({
  providedIn: 'root',
})
export class CommunityService {
  constructor(private readonly httpClient: HttpClient, @Inject(BASE_URL) private baseUrl: string) {}

  public getAllCommunities(
    elementsCount: number,
    requestedElements: number,
    isCurrentUserCommunities: boolean = true
  ): Observable<PagedResponse<Community>> {
    const isCurrentUserCommunitiesBoolean = String(isCurrentUserCommunities);
    return this.httpClient.get<PagedResponse<Community>>(
      `${this.baseUrl}/communities?ElementsCount=${elementsCount}&RequestedElements=${requestedElements}&IsCurrentUserCommunities=${isCurrentUserCommunitiesBoolean}`,
      {
        params: new CustomHttpParams({ action: RequestActions.GET_COMMUNITIES }),
      }
    );
  }

  public searchUsers(communityId: string, searchTerm: string): Observable<ISearchUser[]> {
    return this.httpClient.get<ISearchUser[]>(
      `${this.baseUrl}/communities/${communityId}/user-search?searchTerm=${searchTerm}`
    );
  }

  public getCommunityById(id: string): Observable<Community> {
    return this.httpClient.get<Community>(`${this.baseUrl}/communities/${id}`, {
      params: new CustomHttpParams({ action: RequestActions.GET_COMMUNITY, data: id }),
    });
  }

  public getCommunityMentionById(id: string): Observable<CommunityMentionCardInfo> {
    return this.httpClient.get<CommunityMentionCardInfo>(`${this.baseUrl}/communities/${id}/card`, {
      params: new CustomHttpParams({ action: RequestActions.GET_COMMUNITY, data: id }),
    });
  }

  public getCommunitiesForCurrentUser(): Observable<CommunityMinimised[]> {
    return this.httpClient.get<CommunityMinimised[]>(`${this.baseUrl}/communities/minimised/current-user`);
  }

  public patchCommunity(community: CommunityPostDto, communityId: string): Observable<Community> {
    const formData: FormData = new FormData();

    Object.entries(community).forEach(([key, value]) => formData.append(key, value));

    return this.httpClient.patch<Community>(`${this.baseUrl}/communities`, {
      ...community,
      communityId: communityId,
    });
  }

  public createCommunity(community: CommunityPostDto): Observable<Community> {
    const formData: FormData = new FormData();

    Object.entries(community).forEach(([key, value]) => formData.append(key, value));

    return this.httpClient.post<Community>(`${this.baseUrl}/communities`, formData);
  }

  public createCommunityWithSample(community: CommunityPostDto, sample: string): Observable<Community> {
    const formData = new FormData();

    Object.entries(community).forEach(([key, value]) => formData.append(key, value));

    return this.httpClient.post<Community>(`${this.baseUrl}/communities`, formData).pipe(
      switchMap((community) => {
        return forkJoin(this.updateBackgroundPicture(community.id, sample), of(community));
      }),
      map(([newC, oldC]) => {
        return Object.assign(oldC, newC);
      })
    );
  }

  public updateBackgroundPicture(id: string, picture: string): Observable<Community> {
    return this.httpClient.patch<Community>(`${this.baseUrl}/communities/${id}/background-picture`, {
      picture: picture,
    });
  }

  public joinCommunity(id: string): Observable<Community> {
    return this.httpClient.post<Community>(`${this.baseUrl}/communities/${id}/join`, null);
  }

  public leaveCommunity(id: string): Observable<Community> {
    return this.httpClient.delete<Community>(`${this.baseUrl}/communities/${id}/leave`);
  }

  public acceptOrDeclineAllRequests(communityId: string, approve: boolean): Observable<CommunityJoinRequest[]> {
    const approveString = String(approve);
    return this.httpClient.patch<CommunityJoinRequest[]>(
      `${this.baseUrl}/communities/${communityId}/join-requests?ApproveJoin=${approveString}`,
      {
        communityId: communityId,
        approveJoin: approveString,
      }
    );
  }

  public getMembers(id: string, requestedUsers: number): Observable<Member[]> {
    return this.httpClient.get<Member[]>(`${this.baseUrl}/communities/${id}/members?requestedUsers=${requestedUsers}`, {
      params: new CustomHttpParams({ action: RequestActions.GET_MEMBERS }),
    });
  }

  public deleteUserFromCommunity(communityId: string, userId: string): Observable<RoleChangeResponse> {
    return this.httpClient.delete<RoleChangeResponse>(`${this.baseUrl}/communities/${communityId}/users/${userId}`);
  }

  public deleteCommunity(id: string): Observable<Community> {
    return this.httpClient.delete<Community>(`${this.baseUrl}/communities/${id}`);
  }

  public getPosts(id: string, requiredElements: number, elementCount: number = 0): Observable<PagedResponse<Post>> {
    return this.httpClient.get<PagedResponse<Post>>(
      `${this.baseUrl}/communities/${id}/posts?ElementCount=${elementCount}&RequiredElements=${requiredElements}`
    );
  }

  public changeUserRole(communityId: string, userId: string, role: UserRole): Observable<RoleChangeResponse> {
    return this.httpClient
      .patch<RoleChangeResponse>(`${this.baseUrl}/communities/${communityId}/user/${userId}/user-role`, {
        role: role,
      })
      .pipe(catchError((error: unknown) => throwError(() => error)));
  }

  public resolveJoinRequest(approve: boolean, communityId: string, userId: string): Observable<void> {
    const approveString = String(approve);
    return this.httpClient.patch<void>(
      `${this.baseUrl}/communities/${communityId}/users/${userId}/join-request?ApproveJoin=${approveString}`,
      {
        ApproveJoin: approveString,
      }
    );
  }

  public uploadPicture(id: string, file: File, type: CommunityImagesEndpoints): Observable<Community> {
    const formData = bodyFormData({ file });

    return this.httpClient.post<Community>(`${this.baseUrl}/communities/${id}/${type}`, formData);
  }

  public deletePicture(communityId: string, pictureType: CommunityImagesEndpoints): Observable<any> {
    return this.httpClient.delete<any>(`${this.baseUrl}/communities/${communityId}/${pictureType}`);
  }

  public getAllJoinRequests(id: string): Observable<CommunityJoinRequest[]> {
    return this.httpClient.get<CommunityJoinRequest[]>(`${this.baseUrl}/communities/${id}/join-requests`);
  }

  public searchMention(searchValue: string): Observable<CommunityMention[]> {
    return this.httpClient
      .get<CommunityMention[]>(
        `${this.baseUrl}/communities/mention-search?${searchValue === '' ? '' : `searchTerm=${searchValue}&`}`
      )
      .pipe(catchError((error: unknown) => throwError(() => error)));
  }
}
