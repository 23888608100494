import { User, UserImagesEndpoints } from '@core/models/user.model';
import { createAction, props } from '@ngrx/store';
import { ContentCreatorStatus } from '@core/enums/permission.enum';

export enum UserActionsEnum {
  GET_USER = '[User] Get',
  GET_USER_SUCCESS = '[User] Get success',

  GET_CONTENT_CREATOR_STATUS = '[User] Get Content Creator status',
  GET_CONTENT_CREATOR_STATUS_SUCCESS = '[User] Get Content Creator success',

  UPLOAD_USER_IMAGE = '[User] Upload image',
  UPLOAD_USER_IMAGE_SUCCESS = '[User] Upload image success',

  PATCH_USER_PROFILE_IMAGE = '[User] Update profile image',
  PATCH_USER_PROFILE_IMAGE_SUCCESS = '[User] Update profile image success',

  PATCH_USER_BACKGROUND_IMAGE = '[User] Update background image',
  PATCH_USER_BACKGROUND_IMAGE_SUCCESS = '[User] Update background image success',

  DELETE_USER_IMAGE = '[User] Delete image',
  DELETE_USER_IMAGE_SUCCESS = '[User] Delete image success',

  INCREMENT_USER_STATS = '[User] Increment stats',
  DECREMENT_USER_STATS = '[User] Decrement stats',
}

export const getUser = createAction(UserActionsEnum.GET_USER, props<{ displayName: string; userId: string }>());
export const getUserSuccess = createAction(UserActionsEnum.GET_USER_SUCCESS, props<User>);

export const getContentCreatorStatus = createAction(UserActionsEnum.GET_CONTENT_CREATOR_STATUS);
export const getContentCreatorStatusSuccess = createAction(
  UserActionsEnum.GET_CONTENT_CREATOR_STATUS_SUCCESS,
  props<{ status: ContentCreatorStatus }>()
);

export const uploadUserImage = createAction(
  UserActionsEnum.UPLOAD_USER_IMAGE,
  props<{ file: File; pictureType: UserImagesEndpoints }>()
);
export const uploadUserImageSuccess = createAction(UserActionsEnum.UPLOAD_USER_IMAGE_SUCCESS, props<User>);

export const patchUserProfileImage = createAction(
  UserActionsEnum.PATCH_USER_PROFILE_IMAGE,
  props<{ picture: string }>()
);
export const patchUserProfileImageSuccess = createAction(UserActionsEnum.PATCH_USER_PROFILE_IMAGE_SUCCESS, props<User>);

export const patchUserBackgroundImage = createAction(
  UserActionsEnum.PATCH_USER_BACKGROUND_IMAGE,
  props<{ picture: string }>()
);
export const patchUserBackgroundImageSuccess = createAction(
  UserActionsEnum.PATCH_USER_BACKGROUND_IMAGE_SUCCESS,
  props<User>
);

export const deleteUserImage = createAction(
  UserActionsEnum.DELETE_USER_IMAGE,
  props<{ pictureType: UserImagesEndpoints }>()
);
export const deleteUserImageSuccess = createAction(UserActionsEnum.DELETE_USER_IMAGE_SUCCESS, props<User>);

export const incrementUserStats = createAction(UserActionsEnum.INCREMENT_USER_STATS, props<{ key: string }>());
export const decrementUserStats = createAction(UserActionsEnum.DECREMENT_USER_STATS, props<{ key: string }>());
