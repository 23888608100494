<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="overlayOrigin"
  [cdkConnectedOverlayOpen]="isOpened"
  [cdkConnectedOverlayPositions]="positions[position]"
  [cdkConnectedOverlayOffsetY]="offsetY"
  [cdkConnectedOverlayOffsetX]="offsetX"
  [cdkConnectedOverlayScrollStrategy]="sso.close()"
  (detach)="connectedOverlayDetach()"
>
  <div #dialog role="dialog" aria-label="Dialog" class="dialog-container">
    <ng-content></ng-content>
  </div>
</ng-template>
