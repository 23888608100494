<div [ngClass]="(darkMode$ | async) ? 'dark-mode-mention-card' : ''" class="mention-card">
  <div class="mention-card__info" *ngIf="isLoaded$ | async; else loading">
    <ng-container *ngIf="community || user; else noCommunity">
      <div class="info-container">
        <div class="profile-photo-container">
          <ng-container *ngIf="mention.type === 'profile'; else communityAvatar">
            <app-profile-image
              imageSize="mentioning"
              [photo]="user.profilePhotoThumbnail!"
              [name]="user.displayName"
              [userId]="user.userId"
              [redirectEnabled]="true"
            ></app-profile-image>
            <span class="user-status" [ngStyle]="{ 'background-color': user.isOnline ? '#98fb98' : '#ff4154' }"></span>
          </ng-container>
          <ng-template #communityAvatar>
            <div class="community-avatar">
              <img
                *ngIf="community.profilePhotoThumbnail"
                src="{{ community.profilePhotoThumbnail }}"
                alt=""
                class="community-avatar"
              />
              <span *ngIf="!community.profilePhotoThumbnail" class="initials"
                >{{ community.name | userInitials }}
              </span>
            </div>
          </ng-template>
        </div>
        <div
          [ngClass]="(darkMode$ | async) ? 'dark-mode-mention-card__info--details' : ''"
          class="mention-card__info--details"
        >
          <span class="name">{{ mention.type === 'profile' ? user.displayName : community.name }}</span>
          <span class="mail">{{
            mention.type === 'profile' ? user.jobTitle : (community.description | simpleTruncate : 100)
          }}</span>
          <span class="mail" *ngIf="mention.type === 'profile'">{{ user.email }}</span>
          <span class="mail" *ngIf="mention.type === 'profile' && !user.isOnline && user.lastOnline !== null"
            >Last seen {{ user.lastOnline! | timeAgo }}</span
          >
        </div>
      </div>

      <div [ngClass]="(darkMode$ | async) ? 'dark-mode-mention-card__numbers' : ''" class="mention-card__numbers">
        <span class="mention-card__numbers--info" *ngIf="mention.type === 'profile'">
          <span class="mention-card__numbers--info followers"> {{ user.followersCount }} </span>
          <span class="block-name">Followers</span>
        </span>

        <span class="mention-card__numbers--info">
          <span class="mention-card__numbers--info following">
            {{ mention.type === 'profile' ? user.followingCount : community.membersCount }}
          </span>
          <span class="block-name">{{ mention.type === 'profile' ? 'Following' : 'Members' }}</span>
        </span>

        <span class="mention-card__numbers--info">
          <span class="mention-card__numbers--info posts">
            {{ mention.type === 'profile' ? user.postsCount : community.postsCount }}
          </span>
          <span class="block-name">Posts</span>
        </span>

        <span class="mention-card__numbers--info">
          <span class="mention-card__numbers--info posts">
            {{ mention.type === 'profile' ? user.articlesCount : community.articlesCount }}
          </span>
          <span class="block-name">Articles</span>
        </span>
      </div>
    </ng-container>
  </div>

  <ng-template #loading>
    <div class="loader">
      <app-loading loaderSize="large"></app-loading>
    </div>
  </ng-template>

  <ng-template #noCommunity><span>This community has been deleted.</span> </ng-template>
</div>
