import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, iif, map, of, switchMap, tap } from 'rxjs';
import {
  addPost,
  addPostError,
  addPostSuccess,
  patchPost,
  patchPostError,
  patchPostSuccess,
  PostActionsEnum,
  sharePublication,
  sharePublicationError,
  sharePublicationSuccess,
} from '@store/posts-store/posts-store.actions';
import { ToastrService } from 'ngx-toastr';
import { PostsService } from '@shared/services/posts/posts.service';
import { ArticlesService } from '@shared/services/articles/articles.service';
import { PublicationType } from '@core/models/publication.model';
import { incrementUserStats } from '@store/user/user.actions';
import { Store } from '@ngrx/store';

@Injectable()
export class PostsStoreEffects {
  constructor(
    private actions$: Actions,
    private readonly posts: PostsService,
    private readonly articles: ArticlesService,
    private readonly toastService: ToastrService,
    private readonly store: Store
  ) {}

  addPosts$: any = createEffect(() => {
    return this.actions$.pipe(
      ofType(addPost),
      switchMap((post) =>
        this.posts.create(post, true).pipe(
          map((post) => ({
            type: PostActionsEnum.ADD_POST_SUCCESS,
            payload: post,
            success: 'Post was added successfully.',
          })),
          catchError(() =>
            of({ type: PostActionsEnum.ADD_POST_ERROR, error: 'Something went wrong while adding post.' })
          )
        )
      ),
      tap(() => this.store.dispatch(incrementUserStats({ key: 'postsCount' })))
    );
  });

  patchPosts$: any = createEffect(() => {
    return this.actions$.pipe(
      ofType(patchPost),
      switchMap((editPostDto) =>
        this.posts.update(editPostDto, editPostDto.id).pipe(
          map((post) => ({
            type: PostActionsEnum.PATCH_POST_SUCCESS,
            payload: post,
            success: 'Post was updated successfully.',
          })),
          catchError(() =>
            of({ type: PostActionsEnum.PATCH_POST_ERROR, error: 'Something went wrong while updating post.' })
          )
        )
      )
    );
  });

  sharePublications$: any = createEffect(() => {
    return this.actions$.pipe(
      ofType(sharePublication),
      switchMap(({ id, publication, publicationType }) =>
        iif(
          () => publicationType === PublicationType.post,
          this.posts.share(id, publication),
          this.articles.share(id, publication)
        ).pipe(
          map((publication) => ({
            type: PostActionsEnum.SHARE_PUBLICATION_SUCCESS,
            payload: publication,
            success: `${publicationType === PublicationType.post ? 'Post' : 'Article'} was shared successfully.`,
          })),
          catchError(() =>
            of({ type: PostActionsEnum.SHARE_PUBLICATION_ERROR, error: 'Something went wrong while sharing the post.' })
          )
        )
      )
    );
  });

  displayErrors$: any = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(patchPostError, addPostError, sharePublicationError),
        tap((err) => this.toastService.error(err.error))
      );
    },
    {
      dispatch: false,
    }
  );

  displaySuccess$: any = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(patchPostSuccess, addPostSuccess, sharePublicationSuccess),
        tap((action: any) => this.toastService.success(action.success))
      );
    },
    {
      dispatch: false,
    }
  );
}
