<div class="confirmation-dialog">
  <button [mat-dialog-close]="false" mat-mini-fab class="confirmation-dialog__close-button">
    <mat-icon>close</mat-icon>
  </button>

  <h3 [ngClass]="(darkMode$ | async) ? 'dark-mode-color' : ''" class="confirmation-dialog__title" mat-dialog-title>
    <b>{{ data.title }}</b></h3
  >
  <span [ngClass]="(darkMode$ | async) ? 'dark-mode-color' : ''" class="confirmation-dialog__text" mat-dialog-content>{{
    data.text
  }}</span>

  <mat-dialog-actions>
    <button *ngIf="!data.isDelete; else timedButton" [mat-dialog-close]="true" class="confirm-button" mat-button>{{
      data.confirmBtnText
    }}</button>

    <button [mat-dialog-close]="false" class="cancel-button" mat-button>{{ data.cancelBtnText }}</button>
  </mat-dialog-actions>
</div>

<ng-template #timedButton>
  <app-timed-button (timerFinish)="dialogRef.close(true)">{{ data.confirmBtnText }}</app-timed-button>
</ng-template>
