<div *ngIf="reactionsCount || feedElement.commentsCount" [ngClass]="{ 'dark-mode': darkMode$ | async }" class="count">
  <div class="feedElement-reactions link" (click)="expandFeedElementReactions()">
    <div *ngFor="let reaction of sortedFeedElementReactions">
      <ngx-emoji
        class="feedElement-reaction"
        [isNative]="true"
        [size]="15"
        [emoji]="reaction.reaction"
        [skin]="reaction.skinTone"
      ></ngx-emoji>
    </div>
    <div *ngIf="reactionsCount" class="count__reactions"> {{ reactionsCount }} </div>
  </div>

  <div *ngIf="feedElement.commentsCount" (click)="actionResponse.emit(actionType.comment)" class="count__comments link">
    <span>{{ feedElement.commentsCount }} {{ feedElement.commentsCount === 1 ? 'comment' : 'comments' }}</span>
  </div>
</div>
<div [ngClass]="{ 'dark-mode': darkMode$ | async }" class="comment-actions">
  <div class="comment-actions__action">
    <div (mouseover)="mouseEnter()" (mouseleave)="mouseLeave()">
      <div class="reaction-status">
        <button mat-button class="action-mat-button reaction-button" (click)="reactButton()">
          <div class="action-container">
            <mat-icon *ngIf="'' === chosenReaction; else displayChosenReaction">mood</mat-icon>
            <ng-template #displayChosenReaction>
              <ngx-emoji
                class="icon-container-reaction"
                [ngClass]="{ chosen: isChosen }"
                [isNative]="true"
                [size]="14"
                [emoji]="chosenReaction"
                [skin]="chosenSkinTone"
              ></ngx-emoji>
            </ng-template>

            <div [ngClass]="{ chosen: isChosen }" class="reaction-text">React</div>
          </div>
        </button>

        <div class="reaction-tooltip" *ngIf="isReactionsMenuVisible">
          <div
            *ngFor="let defaultReaction of defaultReactions"
            (click)="addReaction(defaultReaction)"
            style="padding-top: 5px"
          >
            <ngx-emoji
              class="default-reaction"
              [ngClass]="{ 'chosen-reaction': defaultReaction === checkChosenReaction(chosenReaction) }"
              [isNative]="true"
              [size]="18"
              [emoji]="defaultReaction"
              [skin]="1"
            >
            </ngx-emoji>
          </div>
          <app-emoji-picker (selectedEmoji)="addReaction($event)" (click)="openEmojiPicker()"></app-emoji-picker>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngFor="let action of actions">
    <div
      class="comment-actions__action"
      [matTooltip]="isShareInactive(action) ? 'Private content cannot be shared' : ''"
    >
      <button
        (click)="actionResponse.emit(action.type)"
        type="button"
        mat-button
        class="action-mat-button"
        [disabled]="isShareInactive(action)"
      >
        <div class="action-container">
          <mat-icon>{{ action.icon }}</mat-icon>
          <span class="action-text">{{ action.text }}</span>
        </div>
      </button>
    </div>
  </ng-container>
</div>
