export interface IAction {
  icon: string;
  type: ActionType;
  text: string;
}

export enum ActionType {
  comment = 'comment',
  share = 'share',
  bookmark = 'bookmark',
}
