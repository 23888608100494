import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PagedResponse } from '@core/models/paged-response.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { Conversation, Message, NewConversation, UserStatus } from '@core/models/inbox.model';
import { BASE_URL } from '@core/constants/constants';
import { SignalrBaseService } from '@core/services/api/signalr-base/signalr-base.service';
import { buildUrl } from '@core/utils/buildUrl';
import { bodyFormData } from '@core/utils/formData';
import { CustomHttpParams } from '@core/services/helpers/handle-loading/handle-loading.service';
import { RequestActions } from '@core/enums/request-keys';

@Injectable({
  providedIn: 'root',
})
export class InboxService extends SignalrBaseService {
  unreadCount$ = new BehaviorSubject<number>(0);
  conversationDeleted$ = new BehaviorSubject<string>('');

  constructor(private readonly http: HttpClient, @Inject(BASE_URL) private readonly baseUrl: string) {
    super(environment.wssHub.inbox);
    this.baseUrl += '/inbox';
  }

  getConversations(): Observable<Conversation[]> {
    return this.http.get<Conversation[]>(`${this.baseUrl}/conversations-list`);
  }

  getById(id: string, requestedElements: number, elementCount: number = 0): Observable<PagedResponse<Message>> {
    const url = buildUrl(`${this.baseUrl}/conversations/${id}/messages`, { requestedElements, elementCount });

    return this.http.get<PagedResponse<Message>>(url);
  }

  searchUsers(searchString: string, requestedElements = 5, elementCount = 0): Observable<PagedResponse<Conversation>> {
    const url = buildUrl(`${this.baseUrl}/search`, { searchString, elementCount, requestedElements });

    return this.http.get<PagedResponse<Conversation>>(url);
  }

  deleteConversations(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/conversations/${id}`);
  }

  createMessage(body: Message): Observable<Message> {
    const formData = bodyFormData(body);

    return this.http.post<Message>(`${this.baseUrl}/messages`, formData, {
      params: new CustomHttpParams({ action: RequestActions.CREATE_MESSAGE, data: body }),
    });
  }

  updateMessage(body: Message, id: string): Observable<Message> {
    const formData = bodyFormData(body);

    return this.http.patch<Message>(`${this.baseUrl}/messages/${id}`, formData);
  }

  deleteMessage(id: string): Observable<string> {
    return this.http.delete<string>(`${this.baseUrl}/messages/${id}`);
  }

  getUnreadMessages(): Observable<number> {
    return this.http.get<number>(`${this.baseUrl}/unseen-messages`);
  }

  markAsSeen(id: string): Observable<Message> {
    return this.http.patch<Message>(`${this.baseUrl}/conversations/${id}/mark-as-seen`, { id });
  }

  getLastMessage(id: string): Observable<Message> {
    return this.http.get<Message>(`${this.baseUrl}/conversations/${id}/last-message`);
  }

  join(usersIds: string[] = []): void {
    this.invoke('JoinInbox', usersIds);
  }

  leave(usersIds: string[] = []): void {
    this.invoke('LeaveInbox', usersIds);
  }

  onNewMessageCount(): void {
    this.hubConnection.on('NewMessageCount', (value) => this.unreadCount$.next(value));
  }

  onAddConversation(): Observable<NewConversation> {
    return this.on<NewConversation>('AddConversation');
  }

  onOnlineStatus(): Observable<UserStatus> {
    return this.on<UserStatus>('OnlineStatus');
  }

  onRemoveConversation(): Observable<string> {
    return this.on<string>('RemoveConversation');
  }

  onSendOnlineUsers(): Observable<UserStatus[]> {
    return this.on<UserStatus[]>('SendOnlineUsers');
  }

  onReceiveMessage(): Observable<Message> {
    return this.on<Message>('MessageReceived');
  }

  onMessageSent(): Observable<Message> {
    return this.on<Message>('MessageSent');
  }

  onMessageDeleted(): Observable<Message> {
    return this.on<Message>('MessageDeleted');
  }

  onMessageEdited(): Observable<Message> {
    return this.on<Message>('MessageEdited');
  }
}
