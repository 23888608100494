import { ChangeDetectionStrategy, Component, Inject, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DarkModeService } from '@shared/services/dark-mode.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { TimedButtonComponent } from '@shared/components/timed-button/timed-button.component';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-confirmation-dialog',
  standalone: true,
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatDialogModule, MatButtonModule, TimedButtonComponent, MatIconModule],
})
export class ConfirmationDialogComponent {
  darkMode$ = inject(DarkModeService).isDarkMode;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly data: {
      title?: string;
      text: string;
      confirmBtnText: string;
      cancelBtnText: string;
      isDelete?: boolean;
    },
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>
  ) {}
}
