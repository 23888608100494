import { createAction, props } from '@ngrx/store';
import {
  Community,
  CommunityImagesEndpoints,
  CommunityPostDto,
  RoleChangeResponse,
  UserRole,
} from '@core/models/community.model';
import { Member } from '@core/models/user.model';

export enum CommunitiesActions {
  GET_COMMUNITIES = '[Communities] Get',
  GET_COMMUNITIES_SUCCESS = '[Communities] Get success',
  GET_COMMUNITIES_ERROR = '[Communities] Get error',
  RESET_COMMUNITIES = '[Communities] Reset Communities',
  RESET_JOINED_COMMUNITIES = '[Communities] Reset Joined Communities',
  RESET_REMAINING_COMMUNITIES = '[Communities] Reset Remaining Communities',

  GET_COMMUNITY_BY_ID = '[Communities] Get by id',
  GET_COMMUNITY_BY_ID_SUCCESS = '[Communities] Get by id success',
  GET_COMMUNITY_BY_ID_ERROR = '[Communities] Get by id error',

  JOIN_COMMUNITY = '[Community] Join',
  JOIN_COMMUNITY_SUCCESS = '[Community] Join success',
  JOIN_COMMUNITY_ERROR = '[Community] Join error',

  LEAVE_COMMUNITY = '[Community] Leave',
  LEAVE_COMMUNITY_SUCCESS = '[Community] Leave success',
  LEAVE_COMMUNITY_ERROR = '[Community]  Leave error',

  PATCH_COMMUNITY = '[Community] PATCH',
  PATCH_COMMUNITY_ERROR = '[Community] Patch error',

  CREATE_COMMUNITY = '[Community] Create',
  CREATE_COMMUNITY_SUCCESS = '[Community] Create success',
  CREATE_COMMUNITY_ERROR = '[Community] Create error',

  CREATE_COMMUNITY_NAME_ERROR = 'Community name already exists!',

  CREATE_COMMUNITY_WITH_SAMPLE = '[Community] Create with sample',
  CREATE_COMMUNITY_WITH_SAMPLE_SUCCESS = '[Community] Create with sample success',
  CREATE_COMMUNITY_WITH_SAMPLE_ERROR = '[Community] Create with sample error',

  CREATE_COMMUNITY_WITH_SAMPLE_NAME_ERROR = 'Community name already exists!',

  PATCH_COMMUNITY_BACKGROUND_IMAGE = '[Community] Patch background image',
  PATCH_COMMUNITY_BACKGROUND_IMAGE_SUCCESS = '[Community] Patch background image success',
  PATCH_COMMUNITY_BACKGROUND_IMAGE_ERROR = '[Community] Patch background image error',

  GET_COMMUNITY_MEMBERS = '[Communities] Get members',
  GET_COMMUNITY_MEMBERS_SUCCESS = '[Communities] Get members success',
  GET_COMMUNITY_MEMBERS_ERROR = '[Communities] Get members error',

  UPLOAD_COMMUNITY_IMAGE = '[Community] Upload image',
  UPLOAD_COMMUNITY_IMAGE_SUCCESS = '[Community] Upload image success',
  UPLOAD_COMMUNITY_IMAGE_ERROR = '[Community] Upload image error',

  DELETE_COMMUNITY_IMAGE = '[Community] Delete image',
  DELETE_COMMUNITY_IMAGE_SUCCESS = '[Community] Delete image success',
  DELETE_COMMUNITY_IMAGE_ERROR = '[Community] Delete image error',

  DELETE_COMMUNITY = '[Community] Delete',
  DELETE_COMMUNITY_SUCCESS = '[Community] Delete success',
  DELETE_COMMUNITY_ERROR = '[Community] Delete error',

  CHANGE_USER_ROLE = '[Community] change user role',
  CHANGE_USER_ROLE_SUCCESS = '[Community] change user role success',
  CHANGE_USER_ROLE_ERROR = '[Community] change user role error',
}

export const getCommunities = createAction(
  CommunitiesActions.GET_COMMUNITIES,
  props<{ flag: boolean; navigateFromJoined: boolean }>()
);

export const getCommunitiesSuccess = createAction(
  CommunitiesActions.GET_COMMUNITIES_SUCCESS,
  props<{ communities: Community[]; flag: boolean; navigateFromJoined: boolean; hasMore: boolean }>()
);

export const getCommunitiesError = createAction(CommunitiesActions.GET_COMMUNITIES_ERROR, props<Error>());

export const resetCommunitiesState = createAction(CommunitiesActions.RESET_COMMUNITIES);
export const resetJoinedCommunitiesState = createAction(CommunitiesActions.RESET_JOINED_COMMUNITIES);
export const resetRemainingCommunitiesState = createAction(CommunitiesActions.RESET_REMAINING_COMMUNITIES);

export const getCommunityById = createAction(CommunitiesActions.GET_COMMUNITY_BY_ID, props<{ id: string }>());
export const getCommunityByIdSuccess = createAction(
  CommunitiesActions.GET_COMMUNITY_BY_ID_SUCCESS,
  props<{ community: Community }>()
);
export const getCommunityByIdError = createAction(CommunitiesActions.GET_COMMUNITY_BY_ID_ERROR, props<Error>());

export const joinCommunity = createAction(CommunitiesActions.JOIN_COMMUNITY, props<{ id: string }>());
export const joinCommunitySuccess = createAction(
  CommunitiesActions.JOIN_COMMUNITY_SUCCESS,
  props<{ community: Community }>()
);
export const joinCommunityError = createAction(CommunitiesActions.JOIN_COMMUNITY_ERROR, props<Error>());

export const leaveCommunity = createAction(CommunitiesActions.LEAVE_COMMUNITY, props<{ id: string }>());
export const leaveCommunitySuccess = createAction(
  CommunitiesActions.LEAVE_COMMUNITY_SUCCESS,
  props<{ community: Community }>()
);
export const leaveCommunityError = createAction(CommunitiesActions.LEAVE_COMMUNITY_ERROR, props<Error>());

export const createCommunity = createAction(
  CommunitiesActions.CREATE_COMMUNITY,
  props<{ community: CommunityPostDto }>()
);

export const patchCommunity = createAction(
  CommunitiesActions.PATCH_COMMUNITY,
  props<{ community: CommunityPostDto; communityId: string }>()
);

export const createCommunitySuccess = createAction(
  CommunitiesActions.CREATE_COMMUNITY_SUCCESS,
  props<{ community: Community }>()
);
export const createCommunityError = createAction(CommunitiesActions.CREATE_COMMUNITY_ERROR, props<Error>());
export const createCommunityNameError = createAction(CommunitiesActions.CREATE_COMMUNITY_NAME_ERROR, props<Error>());
export const createCommunityWithSample = createAction(
  CommunitiesActions.CREATE_COMMUNITY_WITH_SAMPLE,
  props<{ community: CommunityPostDto; sample: string }>()
);
export const createCommunityWithSampleSuccess = createAction(
  CommunitiesActions.CREATE_COMMUNITY_WITH_SAMPLE_SUCCESS,
  props<{ community: Community }>()
);
export const createCommunityWithSampleError = createAction(
  CommunitiesActions.CREATE_COMMUNITY_WITH_SAMPLE_ERROR,
  props<Error>()
);
export const createCommunityWithSampleNameError = createAction(
  CommunitiesActions.CREATE_COMMUNITY_WITH_SAMPLE_NAME_ERROR,
  props<Error>()
);
export const patchCommunityBackgroundImage = createAction(
  CommunitiesActions.PATCH_COMMUNITY_BACKGROUND_IMAGE,
  props<{ id: string; picture: string }>()
);
export const patchCommunityBackgroundImageSuccess = createAction(
  CommunitiesActions.PATCH_COMMUNITY_BACKGROUND_IMAGE_SUCCESS,
  props<CommunityPostDto>
);
export const patchCommunityBackgroundImageError = createAction(
  CommunitiesActions.PATCH_COMMUNITY_BACKGROUND_IMAGE_ERROR,
  props<Error>
);
export const getCommunityMembers = createAction(
  CommunitiesActions.GET_COMMUNITY_MEMBERS,
  props<{ id: string; count: number }>()
);
export const getCommunityMembersSuccess = createAction(
  CommunitiesActions.GET_COMMUNITY_MEMBERS_SUCCESS,
  props<{ members: Member[] }>()
);
export const getCommunityMembersError = createAction(CommunitiesActions.GET_COMMUNITY_MEMBERS_ERROR, props<Error>());
export const uploadCommunityImage = createAction(
  CommunitiesActions.UPLOAD_COMMUNITY_IMAGE,
  props<{ id: string; file: File; pictureType: CommunityImagesEndpoints }>()
);
export const uploadCommunityImageSuccess = createAction(
  CommunitiesActions.UPLOAD_COMMUNITY_IMAGE_SUCCESS,
  props<Community>
);
export const uploadCommunityImageError = createAction(CommunitiesActions.UPLOAD_COMMUNITY_IMAGE_ERROR, props<Error>);
export const deleteCommunityImage = createAction(
  CommunitiesActions.DELETE_COMMUNITY_IMAGE,
  props<{ id: string; pictureType: CommunityImagesEndpoints }>()
);
export const deleteCommunityImageSuccess = createAction(
  CommunitiesActions.DELETE_COMMUNITY_IMAGE_SUCCESS,
  props<Community>
);
export const deleteCommunityImageError = createAction(CommunitiesActions.DELETE_COMMUNITY_IMAGE_ERROR, props<Error>);

export const deleteCommunity = createAction(CommunitiesActions.DELETE_COMMUNITY, props<{ id: string }>());
export const deleteCommunitySuccess = createAction(CommunitiesActions.DELETE_COMMUNITY_SUCCESS, props<Community>);
export const deleteCommunityError = createAction(CommunitiesActions.DELETE_COMMUNITY_ERROR, props<Error>);

export const changeUserRole = createAction(
  CommunitiesActions.CHANGE_USER_ROLE,
  props<{ communityId: string; userId: string; role: UserRole }>()
);
export const changeUserRoleSuccess = createAction(
  CommunitiesActions.CHANGE_USER_ROLE_SUCCESS,
  props<RoleChangeResponse>()
);
export const changeUserRoleError = createAction(CommunitiesActions.CHANGE_USER_ROLE_ERROR, props<Error>());
