import { ModuleWithProviders, NgModule } from '@angular/core';
import { BASE_URL } from './constants/constants';
import { VirtualScrollerDefaultOptions } from 'ngx-virtual-scroller';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MsalLoginModule } from '@core/components/msal/msal-login.module';
import { DestroyBaseComponent } from '@core/components/destroy-base/destroy-base.component';
import { MsalModule } from '@azure/msal-angular';
import { msalConfig } from '@core/configs/msal-auth-config';
import ImageCompress from 'quill-image-compress';
import Quill from 'quill';
import * as WebForm from 'webfontloader';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ImageResize from 'quill-image-resize';
import { ErrorInterceptorService } from '@core/interceptors/error-interceptor.service';
import { RequestsStateInterceptor } from '@core/interceptors/requests-state.interceptor';
import { DuplicateRequestsInterceptorService } from '@core/interceptors/duplicate-requests-interceptor.service';

Quill.register('modules/imageCompress', ImageCompress);
Quill.register('modules/imageResize', ImageResize);

WebForm.load({
  custom: {
    families: ['Material Icons', 'Material Symbols Rounded'],
  },
});

export function vsDefaultOptionsFactory(): VirtualScrollerDefaultOptions {
  return {
    scrollThrottlingTime: 2000,
    scrollDebounceTime: 2000,
    scrollAnimationTime: 750,
    checkResizeInterval: 2000,
    resizeBypassRefreshThreshold: 5,
    modifyOverflowStyleOfParentScroll: true,
    stripedTable: false,
  };
}

@NgModule({
  declarations: [DestroyBaseComponent],
  imports: [MsalModule, MsalLoginModule.forRoot(msalConfig)],
})
export class CoreModule {
  static forRoot(apiUrl: string): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: DuplicateRequestsInterceptorService,
          multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: RequestsStateInterceptor, multi: true },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ErrorInterceptorService,
          multi: true,
        },
        { provide: 'virtual-scroller-default-options', useFactory: vsDefaultOptionsFactory },
        { provide: BASE_URL, useValue: apiUrl },
      ],
    };
  }
}
