<div [ngClass]="(darkMode$ | async) ? 'dark-mode-search' : ''">
  <div class="search-input" [ngClass]="contentClass" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
    <app-search-input [control]="control"></app-search-input>
  </div>

  <ng-template
    (overlayOutsideClick)="close($event)"
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayPositions]="positions"
    [cdkConnectedOverlayOpen]="!!control.value?.trim()"
    cdkConnectedOverlay
  >
    <mat-card
      *ngIf="search$ | async as search"
      style="
        min-width: 18rem;
        max-height: 24rem;
        min-height: 5.5rem;
        overflow: auto;
        box-shadow: rgba(50, 50, 93, 0.25) 0 6px 12px -2px, rgba(0, 0, 0, 0.3) 0 3px 7px -3px;
      "
    >
      <mat-card-header *ngIf="header"
        ><h1>{{ header }}</h1></mat-card-header
      >

      <mat-card-content>
        <ng-container *ngIf="(loading$ | async) === false; else displayLoading">
          <ul *ngIf="search.count > 0; else noResults" style="padding: 0; margin: 0; list-style-type: none">
            <li
              *ngFor="let item of search.items"
              (click)="itemClick.emit(item); control.reset('')"
              [class.dark-items]="darkMode$ | async"
              style="cursor: pointer"
            >
              <ng-container [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{ $implicit: item }">
              </ng-container>
            </li>
            <li *ngIf="search.hasMore && showMore" (click)="redirectToUser()" class="show-more">Show more results</li>
          </ul>
        </ng-container>
      </mat-card-content>
    </mat-card>
  </ng-template>
</div>

<ng-template #noResults>
  <div class="no-result">
    <mat-icon>report</mat-icon>
    <span>No result found!</span>
  </div>
</ng-template>

<ng-template #displayLoading>
  <app-loading loaderSize="small" style="padding: 0.5rem"></app-loading>
</ng-template>
