import { Injectable } from '@angular/core';
import { FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

@Injectable()
export class FormValidator {
  static emptySpaceValidator(): ValidatorFn {
    return (group) => {
      const textControl = (<FormGroup>group).controls['text'];
      const imageControl = (<FormGroup>group).controls['image'];
      const isWhiteSpace = textControl.value
        ? !textControl.value.trim().length && !imageControl.value
        : !imageControl.value;

      return isWhiteSpace ? { isWhitespace: isWhiteSpace } : null;
    };
  }

  static innerHtmlMaxLength(maxLength: number): ValidatorFn {
    return (control): ValidationErrors | null => {
      if (control.value) {
        const html = control.value || '';
        const text = html.replace(/<[^>]*>/g, '');
        const textLength = text.length;

        return textLength > maxLength ? { maxLength: true } : null;
      }
      return null;
    };
  }

  static get innerHtmlRequired(): ValidatorFn {
    return (control): ValidationErrors | null => {
      const html = control.value || '';
      const text = html.replace(/<[^>]*>/g, '');

      return text ? null : { required: true };
    };
  }

  static get lessToday(): ValidatorFn {
    return (control): ValidationErrors | null => {
      return new Date(control.value as Date).getTime() > Date.now() ? null : { lessThanToday: true };
    };
  }

  static endGreaterStart(start: string, end: string): ValidatorFn {
    return (control) => {
      const startControl = control.get(start);
      const endControl = control.get(end);

      if (!startControl || !endControl) return null;

      if (new Date(startControl.value).toISOString() < new Date(endControl.value).toISOString()) {
        endControl.setErrors(null);
        return null;
      } else {
        const error = { lessThanStart: true };
        endControl.setErrors(error);
        return error;
      }
    };
  }

  static spaceAndMinlengthValidator(minlength: number): ValidatorFn {
    return (control) => {
      if (FormValidator.isPresent(Validators.required(control))) {
        return null;
      }
      if (control.value.trim().length === 0) {
        control.setValue('');
      }
      if (control.value.trim().length < minlength) {
        return { minlength: { requiredLength: minlength } };
      }
      return null;
    };
  }

  static get noWhitespaceValidator(): ValidatorFn {
    return (control): ValidationErrors | null => {
      return (control.value || '').trim().length ? null : { whitespace: true };
    };
  }

  private static isPresent(obj: ValidationErrors | null): boolean {
    return obj !== undefined && obj !== null;
  }
}
