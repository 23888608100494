import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalLoginPermissionGuard } from '@core/guards/msal-login-permission.guard';
import { MsalGuard } from '@azure/msal-angular';
import { MsalPermissionGuard } from '@core/guards/msal-permission.guard';
import { PostRedirectComponent } from '@features/notifications/components/post-redirect/post-redirect.component';
import { LayoutComponent } from '@shared/components/layout/layout.component';
import { permissionsGuard } from '@core/guards/permissions.guard';
import { Permission } from '@core/enums/permission.enum';
import { noPermissionsWarnMsg } from '@core/constants/messages';
import { PageNotFoundComponent } from '@shared/components/page-not-found/page-not-found.component';

const isIframe = window !== window.parent && !window.opener;

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'feed' },
  {
    path: 'login',
    loadChildren: () => import('@features/login/login.module').then((m) => m.LoginModule),
    canMatch: [MsalLoginPermissionGuard],
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [MsalPermissionGuard, MsalGuard],
    children: [
      {
        path: 'feed',
        loadChildren: () => import('@features/feed/feed.module').then((m) => m.FeedModule),
      },
      {
        path: 'profile',
        loadChildren: () => import('@features/profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'people',
        loadChildren: () => import('@features/people/people.module').then((m) => m.PeopleModule),
      },
      {
        path: 'bookmarks',
        loadChildren: () => import('@features/bookmarks/bookmarks.module').then((m) => m.BookmarksModule),
      },
      {
        path: 'content',
        canActivate: [permissionsGuard([Permission.CONTENT_CREATOR], noPermissionsWarnMsg)],
        loadChildren: () => import('@features/content/content.module').then((m) => m.ContentModule),
      },
      {
        path: 'inbox',
        loadChildren: () => import('@features/inbox/inbox.module').then((m) => m.InboxModule),
      },
      {
        path: 'notifications',
        loadChildren: () => import('@features/notifications/notifications.module').then((m) => m.NotificationsModule),
      },
      {
        path: 'event',
        title: 'Event - echo',
        loadChildren: () => import('@features/event/event.module').then((m) => m.EventModule),
      },
      {
        path: 'article',
        loadChildren: () => import('@features/article/article.module').then((m) => m.ArticleModule),
      },
      {
        path: 'application-review',
        canActivate: [permissionsGuard([Permission.ADMIN], noPermissionsWarnMsg)],
        loadChildren: () =>
          import('@features/application-review/application-review.module').then((m) => m.ApplicationReviewModule),
      },
      {
        path: 'users-feedback',
        canActivate: [permissionsGuard([Permission.ADMIN], noPermissionsWarnMsg)],
        loadChildren: () => import('@features/users-feedback/users-feedback.module').then((m) => m.UsersFeedbackModule),
      },
      {
        path: 'post/:postId',
        component: PostRedirectComponent,
      },
      {
        path: 'page-not-found',
        title: 'Page not found',
        component: PageNotFoundComponent,
      },
    ],
  },
  { path: '**', pathMatch: 'full', redirectTo: 'feed' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: !isIframe ? 'enabledBlocking' : 'disabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
