<div
  [ngClass]="{ 'dark-side-menu': darkMode$ | async }"
  [@menuAnimation]="isMenuOpened ? 'open' : 'closed'"
  class="side-menu"
>
  <div class="header-container">
    <img
      [src]="(darkMode$ | async) ? lightLogo : darkLogo"
      alt="Echo logo"
      class="side-menu__logo"
      *ngIf="isMenuOpened"
    />
    <div
      class="side-menu__toggle-menu"
      (click)="toggleMenu()"
      [style.transform]="isMenuOpened ? 'rotate(0deg)' : 'rotate(180deg)'"
      [matTooltip]="isMenuOpened ? 'Close side menu' : 'Open side menu'"
      [matTooltipPosition]="isMenuOpened ? 'left' : 'right'"
    >
      <mat-icon>keyboard_arrow_left</mat-icon>
    </div>
  </div>

  <app-search
    *ngIf="isMenuOpened"
    (itemClick)="redirectToProfile($event.userId)"
    [data$]="users$"
    [showMore]="true"
    header="People"
    style="padding: 1rem 0.75rem"
  >
    <ng-template let-item #item>
      <div
        style="
          display: grid;
          grid-template-rows: repeat(2, 1fr);
          grid-template-columns: 2.5rem 1fr;
          column-gap: 0.5rem;
          align-items: center;
          justify-items: flex-start;
          padding: 0.5rem;
        "
      >
        <app-profile-image
          [photo]="item.profilePhotoThumbnail!"
          [name]="item.displayName"
          [userId]="item.userId"
          [disableAllActions]="true"
          style="grid-row: 1/-1"
        ></app-profile-image>
        <app-user-name
          [isContentCreator]="item.isContentCreator!"
          [userName]="item.displayName"
          [userId]="item.userId"
          [disableRedirect]="true"
          style="grid-row-start: 1"
        >
        </app-user-name>
        <span class="position">{{ item.jobTitle }}</span>
      </div></ng-template
    >
  </app-search>
  <div class="menu-container" [ngStyle]="{ 'margin-top': isMenuOpened ? '0' : '74px' }">
    <div
      class="side-menu__group-tabs"
      *ngFor="let groups of menuGroup | keyvalue; let last = last"
      [style.border]="last && 0"
    >
      <ng-container *ngFor="let tab of groups.value">
        <ng-container *ngIf="(tab.permission | async) === null || (tab.permission | async)">
          <div
            #menuItem="routerLinkActive"
            class="nav-button-container"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            [routerLink]="tab.route"
          >
            <div
              [matTooltip]="tab.title"
              [matTooltipDisabled]="isMenuOpened"
              matTooltipPosition="right"
              class="nav-button-container__button"
            >
              <div *ngIf="tab.icon" class="tab-icon">
                <mat-icon [class.mat-icon-fill]="menuItem.isActive">{{ tab.icon }}</mat-icon>
              </div>

              <span class="tab-title" *ngIf="isMenuOpened">{{ tab.title }}</span>

              <ng-container *ngIf="tab['notificationCount'] | async as count">
                <span
                  *ngIf="count > 0"
                  [ngClass]="
                    isMenuOpened
                      ? 'nav-button-container__button--badge'
                      : 'nav-button-container-closed__button-closed--badge-closed'
                  "
                >
                  {{ count }}
                </span>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="user$ | async as user">
    <div class="nav-profile-container">
      <div class="profile-container">
        <app-profile-image
          [photo]="user.profilePhotoThumbnail!"
          [name]="user.displayName"
          [userId]="user.userId"
          [matTooltipDisabled]="isMenuOpened"
          matTooltip="Go to my profile"
          matTooltipPosition="right"
        ></app-profile-image>

        <div class="nav-profile-container__user-info" *ngIf="isMenuOpened">
          <app-user-name
            [isContentCreator]="user.isContentCreator!"
            [userName]="user.displayName"
            [userId]="user.userId"
          ></app-user-name>
          <span class="nav-profile-container__user-info--job-title"> {{ user.jobTitle }}</span>
        </div>

        <button
          *ngIf="isMenuOpened"
          mat-icon-button
          (click)="toggle()"
          [style.transform]="isExpendedSignOut ? 'rotate(180deg)' : 'rotate(0deg)'"
        >
          <mat-icon class="blank-thumbs-up">keyboard_arrow_up</mat-icon>
        </button>
      </div>

      <div class="action-buttons">
        <div class="sign-out-container" [@collapse]="isExpendedSignOut ? 'closed' : 'open'" *ngIf="isMenuOpened">
          <div class="sign-out" *ngIf="isExpendedSignOut" (click)="logOut()" [@fadeInOut]>
            <div class="tab-icon">
              <mat-icon class="sign-out-icon">logout</mat-icon>
            </div>
            Sign Out
          </div>
        </div>
        <div class="button-container" [@collapse]="isExpendedSignOut ? 'closed' : 'open'" *ngIf="isMenuOpened">
          <div class="button" matTooltip="Leave your Feedback" (click)="openFeedbackDialog()">
            <mat-icon class="light-mode-icon">feedback</mat-icon>
          </div>
        </div>
        <div class="button-container" [@collapse]="isExpendedSignOut ? 'closed' : 'open'" *ngIf="isMenuOpened">
          <div class="button" *ngIf="isExpendedSignOut" (click)="toggleMode()" [@fadeInOut]>
            <mat-icon *ngIf="darkMode$ | async; else refDarkMode" class="light-mode-icon">light_mode</mat-icon>
            <ng-template #refDarkMode>
              <mat-icon class="dark-mode-icon">dark_mode</mat-icon>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
