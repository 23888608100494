import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, catchError, finalize, throwError } from 'rxjs';
import { CustomHttpParams, HandleLoadingService } from '@core/services/helpers/handle-loading/handle-loading.service';

@Injectable()
export class RequestsStateInterceptor implements HttpInterceptor {
  constructor(private readonly loadingStatusService: HandleLoadingService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request.clone()).pipe(
      finalize(() => {
        this.loadingStatusService.removeRequest((<CustomHttpParams>request.params).key);
      }),
      catchError((error: unknown) => throwError(() => error))
    );
  }
}
