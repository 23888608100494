<div class="comment-menu-options">
  <button
    (click)="openMatMenu()"
    [class.menu-opened]="menuOpen"
    mat-icon-button
    class="comment-menu-options__button-option"
  >
    <i (menuClosed)="setMenuState()" (menuOpened)="setMenuState()" [mat-menu-trigger-for]="optionDropDown"></i>
    <mat-icon class="icon-1rem">more_horiz</mat-icon>
  </button>

  <mat-menu #optionDropDown="matMenu" backdropClass="mat-menu-options" xPosition="after">
    <div class="menu-comment-option">
      <button (click)="edit.emit()" mat-flat-button class="option-button">
        <mat-icon>edit_square</mat-icon>
        Edit
      </button>

      <button (click)="openDeleteDialog()" mat-flat-button class="option-button">
        <mat-icon>delete</mat-icon>
        Delete
      </button>
    </div>
  </mat-menu>
</div>
