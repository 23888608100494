<ng-container *ngIf="post && user$ | async as currentUser">
  <mat-card class="post">
    <mat-card-title style="padding-bottom: 1rem" *ngIf="redirect">
      <button (click)="goBack()" mat-stroked-button style="border-radius: 24px">
        <mat-icon> keyboard_backspace </mat-icon>
        <b>Go back</b>
      </button>
    </mat-card-title>
    <header>
      <app-profile-image
        [photo]="
          currentUser.userId === post.user.userId
            ? currentUser.profilePhotoThumbnail!
            : post.user.profilePhotoThumbnail!
        "
        [name]="currentUser.userId === post.user.userId ? currentUser.displayName : post.user.displayName"
        [userId]="currentUser.userId === post.user.userId ? currentUser.userId : post.user.userId"
      ></app-profile-image>

      <div class="post-details">
        <div #author class="post-details__author">
          <app-user-name
            (userIdEmitter)="onUserNameMouseover($event)"
            [userId]="post.user.userId"
            [userName]="post.user.displayName"
            [isContentCreator]="post.user.isContentCreator"
          ></app-user-name>

          <ng-container *ngIf="!communityFeed && post.community">
            <span> posted in </span>

            <app-community-name [community]="post.community"></app-community-name>
          </ng-container>
        </div>

        <div class="post-details__date">
          <mat-icon class="icon-1rem">schedule</mat-icon>
          <span [matTooltip]="postCreatedDate">
            {{ post.creationDate | timeAgo }}
          </span>
          <span *ngIf="isPostEdited">
            <mat-icon class="mat-icon-fill dot">fiber_manual_record</mat-icon>
            Edited</span
          >
        </div>
      </div>

      <ng-container *ngIf="loading$ | async">
        <app-loading loaderSize="small"></app-loading>
      </ng-container>

      <mat-icon *ngIf="isPinned" class="mat-icon-fill">push_pin</mat-icon>

      <button *ngIf="post.isPrivate" mat-icon-button matTooltip="This post is only visible to the community members">
        <mat-icon>lock</mat-icon>
      </button>

      <button
        *ngIf="currentUser.userId && currentUser.userId !== post.user.userId"
        mat-icon-button
        (click)="bookmarkPost(post.id)"
        class="bookmark"
      >
        <mat-icon [class.mat-icon-fill]="isPostBookmarked">bookmark</mat-icon>
      </button>

      <ng-container *ngIf="currentUser.userId === post.user.userId || canUpdate">
        <button
          mat-icon-button
          class="post-options"
          [matMenuTriggerFor]="menu"
          [ngClass]="(darkMode$ | async) ? 'dark-mode-white' : ''"
        >
          <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #menu="matMenu" backdropClass="mat-menu-options">
          <div class="menu-post-option">
            <button mat-flat-button (click)="editPost(post)" class="option-button">
              <mat-icon>edit_square</mat-icon>
              Edit
            </button>

            <button mat-flat-button (click)="deletePost(post.id)" class="option-button">
              <mat-icon>delete</mat-icon>
              Delete
            </button>
            <button
              *ngIf="canPinPost"
              mat-flat-button
              (click)="updatePinState(post.id, communityFeed ? pinPath.communities : pinPath.user)"
              class="option-button"
            >
              <mat-icon class="mat-icon-fill">push_pin</mat-icon>
              {{ isPinned ? 'Unpin' : 'Pin' }}
            </button>
          </div>
        </mat-menu>
      </ng-container>
    </header>

    <div class="post__post-content">
      <ng-container *ngIf="post.text && post.text !== '<p><br></p>'">
        <p class="post__post-content--text" [innerHTML]="post.text | truncateText : 400"></p>
      </ng-container>

      <app-url-preview *ngIf="post.postUrl" [url]="post.postUrl.urlPreviewLink" [postUrlPreview]="post.postUrl" />

      <app-gallery-preview *ngIf="post.images.length" [images]="post.images"></app-gallery-preview>

      <ng-container *ngIf="post.sharedPost && !post.isSharedPostDeleted">
        <div class="space-above">
          <app-shared-post [post]="post"></app-shared-post>
        </div>
      </ng-container>

      <ng-container *ngIf="post.sharedArticle && !post.isSharedArticleDeleted">
        <div class="space-above">
          <app-shared-post [post]="post"></app-shared-post>
        </div>
      </ng-container>

      <ng-container *ngIf="post.isSharedPostDeleted || post.isSharedArticleDeleted">
        <div class="not-available">
          <mat-icon>warning</mat-icon>
          <b>This content is no longer available.</b>
        </div>
      </ng-container>

      <ng-container *ngIf="post.documents.length > 0">
        <app-attached-files [readonlyDocs]="post.documents"></app-attached-files>
      </ng-container>
    </div>

    <ng-container *ngIf="canInteract; else cannotInteract">
      <app-reactions
        [feedElement]="post"
        [type]="feedElementType.post"
        (actionResponse)="setActionEvent($event)"
      ></app-reactions>

      <ng-container *ngIf="actionEvent[actionType.comment]">
        <app-comment-box
          [feedElement]="post"
          [type]="feedElementType.post"
          [user]="currentUser"
          (countComments)="countComments($event)"
        ></app-comment-box>
      </ng-container>
    </ng-container>
  </mat-card>
</ng-container>

<ng-template #userInfoPreviewTemplate>
  <app-user-info-preview [userId]="hoveredUserId"></app-user-info-preview>
</ng-template>

<ng-template #cannotInteract>
  <span style="text-align: center; font-weight: bolder">
    Only members of this community can interact with this post
  </span>
</ng-template>
