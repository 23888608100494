import { Injectable } from '@angular/core';
import { FeedElementBaseService } from '@core/services/api/feed-element-base/feed-element-base.service';
import { DraftPost, Post } from '@core/models/post.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PostsService extends FeedElementBaseService<Post> {
  draft$ = new Subject<DraftPost>();

  constructor() {
    super('posts');
  }
}
