import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DestroyBaseComponent } from '@core/components/destroy-base/destroy-base.component';
import { RequestActions, RequestKey } from '@core/enums/request-keys';
import { Comment } from '@core/models/comment.model';
import { User } from '@core/models/user.model';
import { CommentsService } from '@shared/services/comments/comments.service';
import { ConvertorService } from '@core/services/helpers/file-convertor/convertor.service';
import { DarkModeService } from '@shared/services/dark-mode.service';
import { FormValidator } from '@core/validators/form-validator';
import { BehaviorSubject, EMPTY, catchError, filter, takeUntil, throwError } from 'rxjs';
import { FeedElementType } from '@core/models/feed-element.model';
import { Publication } from '@core/models/publication.model';
import { CommonModule } from '@angular/common';
import { NgRequestKeyDirective } from '@shared/directives/ng-request-key.directive';
import { LoadingComponent } from '@shared/components/loading/loading.component';
import { MatIconModule } from '@angular/material/icon';
import { ProfileImageComponent } from '@shared/components/profile-image/profile-image.component';
import { CommentEditorComponent } from '@shared/components/comments/comment-editor/comment-editor.component';
import { CommentItemComponent } from '@shared/components/comments/comment-item/comment-item.component';
import { CommentReplyComponent } from '@shared/components/comments/comment-reply/comment-reply.component';

@Component({
  selector: 'app-comment-box',
  standalone: true,
  templateUrl: './comment-box.component.html',
  styleUrls: ['./comment-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgRequestKeyDirective,
    LoadingComponent,
    MatIconModule,
    ProfileImageComponent,
    CommentEditorComponent,
    CommentItemComponent,
    CommentReplyComponent,
  ],
})
export class CommentBoxComponent extends DestroyBaseComponent implements OnInit {
  @Output() countComments = new EventEmitter<number>();
  @Input() user: User;
  @Input() feedElement: Publication;
  @Input() type: FeedElementType;

  commentsForm = new FormGroup(
    {
      text: new FormControl(null, [Validators.maxLength(1500)]),
      image: new FormControl(null),
      commentList: this.fb.array([]),
    },
    { validators: FormValidator.emptySpaceValidator() }
  );
  hasMore = false;
  loadMoreComment = false;
  requestKey: RequestKey;
  isLoading$ = new BehaviorSubject<boolean>(false);
  darkMode$ = inject(DarkModeService).isDarkMode;

  constructor(
    private readonly comments: CommentsService,
    private readonly cdr: ChangeDetectorRef,
    private readonly fb: FormBuilder,
    private readonly convertor: ConvertorService
  ) {
    super();
  }

  ngOnInit(): void {
    this._initCommentsForm();

    this._initCommentSection(this.feedElement.id);
  }

  public addComment(): void {
    if (this.isLoading$.value) return;

    const { text, image } = this.commentsForm.controls;

    this.isLoading$.next(true);
    if (text.valid || image.valid) {
      this.comments
        .create({
          feedElementId: this.feedElement.id,
          feedElementType: this.type,
          text: text.value ?? '',
          image: image.value ? this.convertor.convertToFile(image.value) : undefined,
        })
        .pipe(catchError(() => (this.isLoading$.next(false), EMPTY)))
        .subscribe(() => {
          this.isLoading$.next(false);
          this.resetInputControls();
          this.sortComments();
        });
    }
  }

  public removeEditing(id: string): void {
    this.getCommentArray.controls.map((comment) => {
      comment.patchValue({ isEditing: comment.value.id === id });
    });
  }

  private resetInputControls(): void {
    this.commentsForm.controls['text'].reset();
    this.commentsForm.controls['image'].reset();
  }

  private _initCommentSection(feedElementId: string): void {
    this.requestKey = {
      action: RequestActions.GET_COMMENTS,
      data: { id: this.feedElement.id, elementsCount: this.getCommentArray.length, feedElementType: this.type },
    };

    this.comments.joinSection(feedElementId);

    this.getComments(2);

    this._listenAddedComment(feedElementId);
    this._listenEditComment(feedElementId);
    this._listenDeletedComment(feedElementId);
  }

  public getComments(countEl = 6, onScroll = false): void {
    this.loadMoreComment = onScroll;

    this.comments
      .getAllPaged(this.feedElement.id, this.type, this.getCommentArray.length, countEl)
      .pipe(catchError((error: unknown) => throwError(() => ((this.loadMoreComment = false), error))))
      .subscribe(({ hasMore, items }) => {
        this.loadMoreComment = false;
        this.hasMore = hasMore;

        items.forEach((comment) => {
          if (!comment.isDeleted) {
            const commentFormGroup = this._commentFormGroup();

            commentFormGroup.patchValue({ ...comment, originalText: comment.text, originalImage: comment.image });
            this.getCommentArray.push(commentFormGroup);
          }
        });
      });
  }

  private _initCommentsForm(): void {
    this.commentsForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.cdr.detectChanges();
    });
  }

  private _listenAddedComment(feedElementId: string): void {
    this.comments
      .onCreate()
      .pipe(
        filter((value) => feedElementId === value.feedElementId),
        takeUntil(this.destroy$)
      )
      .subscribe((comment) => {
        const commentFormGroup = this._commentFormGroup();
        comment.reactions = [];
        comment.userReaction = null;
        commentFormGroup.patchValue({ ...comment, originalText: comment.text, originalImage: comment.image });
        this.getCommentArray.insert(0, commentFormGroup);

        this._countComments();
      });
  }

  private _listenDeletedComment(id: string): void {
    this.comments
      .onDelete()
      .pipe(
        filter(([feedElementId]) => id === feedElementId),
        takeUntil(this.destroy$)
      )
      .subscribe(([, itemId]) => {
        this.getCommentArray.removeAt(this.getCommentArray.value.findIndex(({ id }: { id: string }) => id === itemId));

        this._countComments(false);

        if (!this.getCommentArray.length && this.hasMore) {
          this.getComments(2, true);
        }
      });
  }

  private _listenEditComment(feedElementId: string): void {
    this.comments
      .onUpdate()
      .pipe(
        filter((value) => feedElementId === value.feedElementId),
        takeUntil(this.destroy$)
      )
      .subscribe((comment) => {
        const commentIndex = this.getCommentArray.value.findIndex(({ id }: { id: string }) => id === comment.id);

        this.getCommentArray
          .at(commentIndex)
          ?.patchValue({ ...comment, originalText: comment.text, originalImage: comment.image });
      });
  }

  private _commentFormGroup(): FormGroup {
    return this.fb.group(
      {
        text: [{ value: null, disabled: false }, [Validators.maxLength(1500)]],
        originalText: null,
        originalImage: null,
        isEditing: false,
        isDeleting: false,
        id: null,
        commentId: null,
        user: null,
        creationDate: null,
        lastEditDate: null,
        image: null,
        pin: false,
        replyCount: null,
        isThreadOpen: false,
        hasMoreReplies: false,
        userReaction: null,
        reactions: [],
        commentReplies: new FormArray([]),
        isReply: false,
      },
      { validators: FormValidator.emptySpaceValidator() }
    );
  }

  private _countComments(increment = true): void {
    let countComments = this.feedElement.commentsCount;

    this.countComments.emit(increment ? ++countComments : --countComments);
  }

  public updateCommentAfterPinAction(comment: Comment): void {
    this.commentsForm.controls.commentList.controls.map((ctrl: FormControl) => {
      ctrl.patchValue({ pin: ctrl.value.id === comment.id && comment.pin });
    });

    this.sortComments();
  }

  private sortComments(): void {
    const commentList = this.commentsForm.controls.commentList as FormArray;

    commentList.controls.sort((a, b) => {
      const feedElementPinSort = b.value.pin - a.value.pin;
      return feedElementPinSort !== 0
        ? feedElementPinSort
        : new Date(b.value.creationDate).getTime() - new Date(a.value.creationDate).getTime();
    });

    // sort the FormArray values based on the sorted controls
    const sortedValues = commentList.controls.map((control) => control.value);
    commentList.setValue(sortedValues);
  }

  public getFormGroupIndex(index: number): FormGroup {
    return <FormGroup>this.getCommentArray.controls[index];
  }

  public getRepliesFormArray(index: number): FormArray {
    return <FormArray>this.getFormGroupIndex(index).controls['commentReplies'];
  }

  public get getCommentArray(): FormArray {
    return this.commentsForm.controls.commentList;
  }
}
