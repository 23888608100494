<div [ngClass]="{ 'dark-mode': darkMode$ | async }" class="user-info-preview">
  <div *ngIf="isLoaded$ | async; else loading" class="user-info-preview__container">
    <div class="user-info-preview__upper-section">
      <div class="profile-photo">
        <ng-container *ngIf="user">
          <app-profile-image
            imageSize="mentioning"
            [photo]="user.profilePhotoThumbnail!"
            [name]="user.displayName"
            [userId]="user.userId"
            [redirectEnabled]="true"
          ></app-profile-image>
          <span class="user-status" [ngStyle]="{ 'background-color': user.isOnline ? '#98fb98' : '#ff4154' }"></span>
        </ng-container>
      </div>

      <div [ngClass]="{ 'dark-mode-details': darkMode$ | async }" class="details">
        <span class="name">{{ user.displayName }}</span>
        <span class="field">{{ user.jobTitle }}</span>
        <span class="field">{{ user.email }}</span>
        <span class="field" *ngIf="!user.isOnline && user.lastOnline !== null"
          >Last seen {{ user.lastOnline! | timeAgo }}</span
        >
      </div>
    </div>

    <div class="user-info-preview__lower-section">
      <div class="numbers">
        <span class="numbers__followers"> {{ user.followersCount }} </span>
        <span class="block-name">Followers</span>
      </div>

      <div class="numbers">
        <span class="numbers__following">
          {{ user.followingCount }}
        </span>
        <span class="block-name">Following</span>
      </div>

      <div class="numbers">
        <span class="numbers__posts">
          {{ user.postsCount }}
        </span>
        <span class="block-name">Posts</span>
      </div>

      <div class="numbers">
        <span class="numbers__posts">
          {{ user.articlesCount }}
        </span>
        <span class="block-name">Articles</span>
      </div>
    </div>
  </div>

  <ng-template #loading>
    <div class="loader">
      <app-loading loaderSize="large"></app-loading>
    </div>
  </ng-template>
</div>
