export const environment = {
  production: true,
  msal: {
    clientId: '6d79888a-e4e8-4dae-9eed-8c2c5d29fa00',
    authority: 'https://login.microsoftonline.com/544f8ac3-ce4c-47d1-9b72-284ac54b8d1c',
    redirectUri: 'https://echo.amdaris.com',
    postLogoutRedirectUri: 'https://echo.amdaris.com/login',
    cacheLocation: 'localStorage',
    msalInterceptor: {
      apiUrl: 'https://app-csn-api-prod-northeu-001.azurewebsites.net/api',
      scopes: ['api://89838b26-01e8-4d26-80a1-021e23dd72f4/User.Impersonation'],
    },
  },
  apiUrl: 'https://app-csn-api-prod-northeu-001.azurewebsites.net/api',
  wssHub: {
    comment: 'https://app-csn-api-prod-northeu-001.azurewebsites.net/comment-hub',
    notification: 'https://app-csn-api-prod-northeu-001.azurewebsites.net/notification-hub',
    inbox: 'https://app-csn-api-prod-northeu-001.azurewebsites.net/inbox-hub',
  },
};
