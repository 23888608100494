import { createReducer, on } from '@ngrx/store';
import {
  addPostError,
  addPostSuccess,
  patchPostError,
  patchPostSuccess,
  resetPostsState,
  sharePublicationError,
  sharePublicationSuccess,
} from '@store/posts-store/posts-store.actions';
import { PublicationType } from '@core/models/publication.model';

export const initialState = {
  error: null,
  addedPost: null,
  editedPost: null,
  sharingPublication: null,
};

export const postsReducer = createReducer(
  initialState,
  on(patchPostSuccess, (state, data: any) => {
    return {
      ...state,
      editedPost: data.payload,
    };
  }),
  on(patchPostError, (state, data: any) => ({
    ...state,
    error: data.error,
  })),
  on(addPostSuccess, (state, data: any) => {
    return {
      ...state,
      addedPost: data.payload,
    };
  }),
  on(addPostError, (state, data: any) => ({
    ...state,
    error: data.error,
  })),
  on(sharePublicationSuccess, (state, data: any) => {
    return {
      ...state,
      sharingPublication: { ...data.payload, publicationType: PublicationType.post },
    };
  }),
  on(sharePublicationError, (state, data: any) => ({
    ...state,
    error: data.error,
  })),
  on(resetPostsState, (state) => ({
    ...state,
    addedPost: null,
    editedPost: null,
    sharingPublication: null,
  }))
);
