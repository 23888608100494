import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, filter, firstValueFrom, map, throwError } from 'rxjs';
import { HttpTransportType, HubConnectionBuilder, IHttpConnectionOptions, LogLevel } from '@microsoft/signalr';
import * as signalR from '@microsoft/signalr';
import { MsalBroadcastService } from '@azure/msal-angular';
import { EventType } from '@azure/msal-browser';
import { AuthenticationResult } from '@azure/msal-common';

@Injectable()
export abstract class SignalrBaseService {
  hubConnection: signalR.HubConnection;
  hubConnected$ = new BehaviorSubject<boolean>(false);

  private readonly options: IHttpConnectionOptions = {
    skipNegotiation: true,
    transport: HttpTransportType.WebSockets,
    accessTokenFactory: (): Promise<string> => this.accessToken(),
  };
  private readonly msalSubject$ = inject(MsalBroadcastService).msalSubject$;
  private readonly accessToken = (): Promise<string> =>
    firstValueFrom(
      this.msalSubject$.pipe(
        filter(({ eventType }) => eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        map(({ payload }) => (payload as AuthenticationResult).accessToken)
      )
    );

  protected constructor(hubUrl = '') {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(hubUrl, this.options)
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Critical)
      .build();
  }

  async startHub(): Promise<void> {
    try {
      await this.hubConnection.start().then(() => this.hubConnected$.next(true));
    } catch (error) {
      throwError(() => error);
    }
  }

  async stopHub(): Promise<void> {
    try {
      if (this.hubConnection) {
        await this.hubConnection.stop();
      }
    } catch (error) {
      throwError(() => error);
    }
  }

  on<T>(methodName: string, arrResponse = false): Observable<T> {
    return new Observable<T>((observer) => {
      this.hubConnection.on(methodName, (...data) => observer.next(arrResponse ? data : data[0]));
    });
  }

  invoke(methodName: string, arg: any): void {
    this.hubConnection.invoke(methodName, arg).catch((error) => throwError(() => error));
  }
}
