import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  inject,
} from '@angular/core';
import {
  EMPTY,
  Observable,
  catchError,
  delay,
  delayWhen,
  filter,
  first,
  fromEvent,
  iif,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { User } from '@core/models/user.model';
import { DestroyBaseComponent } from '@core/components/destroy-base/destroy-base.component';
import { ActionType } from '@core/models/action.model';
import { currentUser } from '@store/user/user.selectors';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import {
  CloseScrollStrategy,
  FlexibleConnectedPositionStrategy,
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ConfirmationDialogComponent } from '@shared/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { DateTimeConvertService } from '@core/services/helpers/date-time-convert/date-time-convert.service';
import { DarkModeService } from '@shared/services/dark-mode.service';
import { MentionType } from '@core/enums/mention-type';
import { MentionPreviewComponent } from '@shared/components/mention-preview/mention-preview.component';
import { CreateOverlayService } from '@shared/services/create-overlay.service';
import { Article } from '@core/models/article.model';
import { Router } from '@angular/router';
import { ArticlesService } from '@shared/services/articles/articles.service';
import { FeedElementType } from '@core/models/feed-element.model';
import { BookmarksService } from '@shared/services/bookmarks/bookmarks.service';
import { SharePostDialogComponent } from '@shared/components/dialogs/share-post-dialog/share-post-dialog.component';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { ProfileImageComponent } from '@shared/components/profile-image/profile-image.component';
import { UserNameComponent } from '@shared/components/user-name/user-name.component';
import { CommunityNameComponent } from '@shared/components/community-name/community-name.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TimeAgoPipe } from '@shared/pipes/time-ago.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { AttachedFilesComponent } from '@shared/components/attached-files/attached-files.component';
import { ReactionsComponent } from '@shared/components/reactions/reactions.component';
import { CommentBoxComponent } from '@shared/components/comments/comment-box/comment-box.component';
import { UserInfoPreviewComponent } from '@shared/components/user-info-preview/user-info-preview.component';
import { Storage } from '@core/enums/storage';
import { decrementUserStats } from '@store/user/user.actions';

@Component({
  selector: 'app-article',
  standalone: true,
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatCardModule,
    ProfileImageComponent,
    UserNameComponent,
    CommunityNameComponent,
    MatIconModule,
    MatTooltipModule,
    TimeAgoPipe,
    MatButtonModule,
    MatMenuModule,
    AttachedFilesComponent,
    ReactionsComponent,
    CommentBoxComponent,
    UserInfoPreviewComponent,
  ],
})
export class ArticleComponent extends DestroyBaseComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('author') author: ElementRef<HTMLElement>;
  @ViewChild('userInfoPreviewTemplate') userInfoPreviewTemplate: TemplateRef<any>;

  @Input() article: Article;
  @Input() communityFeed?: boolean;
  @Input() canUpdate?: boolean;
  @Input() canInteract = true;
  @Input() emitUnbookmark = false;
  @Input() enableResized = false;
  @Output() articleUpdate = new EventEmitter<void>();
  @Output() delete = new EventEmitter<string>();
  @Output() resized = new EventEmitter<void>();
  @Output() updateArticle = new EventEmitter<Article>();

  isArticleEdited: boolean;
  isArticleBookmarked: boolean;
  articleCreatedDate: string;
  actionEvent: Record<string, boolean> = { [ActionType.comment]: false };
  actionType = ActionType;
  overlayRef: OverlayRef;
  isPreviewDisplayed = false;
  hoveredUserId: string;
  feedElementType = FeedElementType;
  resizeObserver = new ResizeObserver(() => this.resized.next());
  darkMode$ = inject(DarkModeService).isDarkMode;

  constructor(
    private readonly articles: ArticlesService,
    private readonly bookmarks: BookmarksService,
    private readonly dialog: MatDialog,
    private readonly store: Store,
    private readonly toastService: ToastrService,
    private readonly dateConvert: DateTimeConvertService,
    private readonly renderer: Renderer2,
    private readonly overlay: Overlay,
    private readonly el: ElementRef,
    private readonly overlayPositionBuilder: OverlayPositionBuilder,
    private readonly injector: Injector,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly createOverlayService: CreateOverlayService,
    private readonly router: Router
  ) {
    super();
  }

  public ngOnInit(): void {
    this.getIsArticleEdited();
    this.isArticleBookmarked = this.article?.isBookmarked;
    this.articleCreatedDate = this.dateConvert.convertToLocalTime(this.article?.creationDate);
  }

  override ngOnDestroy(): void {
    this.resizeObserver.unobserve(this.el.nativeElement);
    super.ngOnDestroy();
  }

  public removeMarkdownFromText(markdownText: string): string {
    return markdownText.replace(/<[^>]*>/g, '').replace(/&[a-z]+;/gi, '');
  }

  public truncContent(content: string): string {
    content = this.removeMarkdownFromText(content);
    return content.length
      ? content.length > 200
        ? content.substring(0, 200).concat('...')
        : content.concat('...')
      : '';
  }

  public ngAfterViewInit(): void {
    this.addMentionListener();
  }

  public onUserNameMouseover(userId: string): void {
    this.hoveredUserId = userId;

    this.createOverlayService.showEventPreview(
      this.hoveredUserId,
      this.author.nativeElement,
      this.userInfoPreviewTemplate,
      this.viewContainerRef
    );
  }

  public onCloseUserPreview(): void {
    this.createOverlayService.closeEventPreview();
    this.hoveredUserId = '';
  }

  public onMentionMouseover(event: MouseEvent): void {
    const target: HTMLElement = event.target as HTMLElement;
    if (
      (String(target).includes(MentionType.Profile) && target.hasAttribute('href')) ||
      target.classList.contains('mention')
    ) {
      this.showOverlay(target, MentionType.Profile);
    } else if (
      (String(target).includes(MentionType.Community) && target.hasAttribute('href')) ||
      target.classList.contains('mention')
    ) {
      this.showOverlay(target, MentionType.Community);
    }
  }

  public onMentionMouseout(event: MouseEvent): void {
    if (this.overlayRef) {
      const target: HTMLElement = event.target as HTMLElement;

      if (
        (String(target).includes(MentionType.Profile) && target.hasAttribute('href')) ||
        (String(target).includes(MentionType.Community) && target.hasAttribute('href'))
      ) {
        this.closeOverlayWithDelay();
      } else {
        this.isPreviewDisplayed = false;
        this.overlayRef.detach();
        this.overlayRef.outsidePointerEvents().subscribe(() => {
          this.overlayRef.detach();
        });
      }
    }
  }

  public closeOverlayWithDelay(): void {
    of(null)
      .pipe(
        delayWhen(() =>
          fromEvent(this.overlayRef.overlayElement, 'mouseleave').pipe(
            delay(1000),
            tap(() => (this.isPreviewDisplayed = false))
          )
        )
      )
      .subscribe(() => this.overlayRef.detach());
  }

  public showOverlay(target: HTMLElement, mentionType: string): void {
    if (this.isPreviewDisplayed) return;
    this.isPreviewDisplayed = true;
    const positionStrategy: FlexibleConnectedPositionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(target)
      .withPositions([
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
          offsetY: 0,
        },
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
        },
      ]);

    const scrollStrategy: CloseScrollStrategy = this.overlay.scrollStrategies.close();
    const overlayRef: OverlayRef = this.overlay.create({ positionStrategy, scrollStrategy });

    const injector: Injector = Injector.create({
      parent: this.injector,
      providers: [
        {
          provide: 'MENTION_ID',
          useValue: { id: target.getAttribute('href')?.split('/').pop() ?? '', type: mentionType },
        },
      ],
    });
    overlayRef.attach(new ComponentPortal(MentionPreviewComponent, null, injector));

    this.overlayRef = overlayRef;
  }

  public viewArticle(): void {
    void this.router.navigate(['article/overview', this.article.id]);
  }

  public edit(): void {
    localStorage.setItem(Storage.route_to_compose_article, this.router.url);
    void this.router.navigate(['article/composer', this.article.id]);
  }

  public deleteArticle(): void {
    this.dialog
      .open(ConfirmationDialogComponent, {
        width: '400px',
        autoFocus: false,
        data: {
          title: 'Delete',
          text: 'Are you sure you want to delete this article?',
          confirmBtnText: 'Delete',
          cancelBtnText: 'Cancel',
        },
      })
      .afterClosed()
      .pipe(
        first(),
        filter(Boolean),
        switchMap(() => this.articles.delete(this.article.id))
      )
      .subscribe(() => {
        this.delete.emit(this.article.id);
        this.store.dispatch(decrementUserStats({ key: 'articlesCount' }));
        this.toastService.success('Article was deleted successfully');
      });
  }

  public bookmarkArticle(id: string): void {
    iif(
      () => this.isArticleBookmarked,
      this.bookmarks.delete(id, { feedElementId: id, feedElementType: FeedElementType.article }),
      this.bookmarks.create({ feedElementId: id, feedElementType: FeedElementType.article })
    )
      .pipe(
        catchError(() => {
          const err = this.isArticleBookmarked
            ? 'Unable to bookmark article.'
            : 'Unable to remove article from bookmarks.';
          this.toastService.error(err);
          return EMPTY;
        })
      )
      .subscribe(() => {
        if (!this.isArticleBookmarked && this.emitUnbookmark) this.delete.emit(id);

        this.toastService.success(`Bookmark ${this.isArticleBookmarked ? 'added' : 'removed'} successfully!`);
      });

    this.isArticleBookmarked = !this.isArticleBookmarked;
  }

  public getIsArticleEdited(): void {
    const createdDate = new Date(this.article?.creationDate).getTime();
    const editedDate = new Date(this.article?.lastEditDate).getTime();
    this.isArticleEdited = createdDate !== editedDate;
  }

  public countComments(commentsCount: number): void {
    this.article = {
      ...this.article,
      commentsCount,
    };

    this.updateArticle.emit(this.article);
  }

  public setActionEvent(event: ActionType): void {
    this.actionEvent[event] = true;
    if (this.enableResized) this.resizeObserver.observe(this.el.nativeElement);

    if (event === this.actionType.share) {
      this.dialog
        .open(SharePostDialogComponent, {
          width: '600px',
          data: {
            publicationType: this.article.publicationType,
            text: this.article.text,
            creationDate: this.article.creationDate,
            user: this.article.user,
            id: this.article.id,
            articleTitle: this.article.title,
            articlePreview: this.article.preview,
            documents: this.article.documents,
            isArticleEdited: this.isArticleEdited,
          },
        })
        .afterClosed()
        .subscribe();
    }
  }

  private addMentionListener(): void {
    this.renderer.listen(this.el.nativeElement, 'mouseover', (event: MouseEvent) => {
      this.onMentionMouseover(event);
    });
    this.renderer.listen(this.el.nativeElement, 'mouseout', (event: MouseEvent) => {
      this.onMentionMouseout(event);
      this.onCloseUserPreview();
    });
  }

  public get user$(): Observable<User> {
    return this.store.select(currentUser);
  }
}
