import { Observable } from 'rxjs';

export interface TabsGroupModel {
  icon: string;
  title: MenuNavTitle;
  route?: string;
  type?: TabType;
  group: string;
  notificationCount?: Observable<number>;
  permission?: Observable<boolean>;
}

export enum MenuNavTitle {
  homeFeed = 'Feed',
  people = 'People',
  communities = 'Communities',
  bookmarks = 'Bookmarks',
  content = 'Content',
  contentSearch = 'Content Search',
  inbox = 'Inbox',
  notifications = 'Notifications',
  event = 'Event',
  applicationReview = 'Application Review',
  userFeedback = 'User Feedback',
}

export enum TabType {
  template = 'template',
  basic = 'basic',
}
