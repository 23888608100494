import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { DarkModeService } from '@shared/services/dark-mode.service';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-search-input',
  standalone: true,
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatInputModule,
    NgClass,
    ReactiveFormsModule,
    AsyncPipe,
    NgIf,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { subscriptSizing: 'dynamic' },
    },
  ],
})
export class SearchInputComponent {
  @Input() control: FormControl;
  @Input() placeholder = 'Search people';

  darkMode$ = inject(DarkModeService).isDarkMode;
}
