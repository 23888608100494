import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { UserDisplayDto } from '@core/models/user.model';
import { DarkModeService } from '@shared/services/dark-mode.service';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ProfileImageComponent } from '@shared/components/profile-image/profile-image.component';
import { UserNameComponent } from '@shared/components/user-name/user-name.component';

@Component({
  selector: 'app-mutual-dialog',
  standalone: true,
  templateUrl: './mutual-dialog.component.html',
  styleUrls: ['./mutual-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatIconModule, ProfileImageComponent, UserNameComponent, MatDialogModule],
})
export class MutualDialogComponent {
  protected readonly darkMode$ = inject(DarkModeService).isDarkMode;
  protected readonly data: { userId: string; userName: string; mutuals: UserDisplayDto[] } = inject(MAT_DIALOG_DATA);
}
