import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { UsersService } from '@shared/services/users/users.service';
import { ToastrService } from 'ngx-toastr';
import { filter, map, switchMap, tap } from 'rxjs';
import {
  deleteUserImage,
  deleteUserImageSuccess,
  getContentCreatorStatus,
  getUser,
  patchUserBackgroundImage,
  patchUserBackgroundImageSuccess,
  patchUserProfileImage,
  patchUserProfileImageSuccess,
  uploadUserImage,
  uploadUserImageSuccess,
  UserActionsEnum,
} from './user.actions';
import { ContentCreatorService } from '@shared/services/users/content-creator.service';
import { Action, Store } from '@ngrx/store';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventType } from '@azure/msal-browser';

@Injectable()
export class UserEffects implements OnInitEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly users: UsersService,
    private readonly toastr: ToastrService,
    private readonly contentCreator: ContentCreatorService,
    private readonly store: Store,
    private readonly msalBroadcast: MsalBroadcastService,
    private readonly msal: MsalService
  ) {}

  getUser$: any = createEffect(() =>
    this.actions$.pipe(
      ofType(
        getUser,
        uploadUserImageSuccess,
        patchUserBackgroundImageSuccess,
        patchUserProfileImageSuccess,
        deleteUserImageSuccess
      ),
      switchMap(() =>
        this.users.currentAuth.pipe(
          map((payload) => ({
            type: UserActionsEnum.GET_USER_SUCCESS,
            payload,
          }))
        )
      )
    )
  );

  getContentCreator$: any = createEffect(() =>
    this.actions$.pipe(
      ofType(getContentCreatorStatus),
      switchMap(() =>
        this.contentCreator.latest.pipe(
          map((payload) => ({
            type: UserActionsEnum.GET_CONTENT_CREATOR_STATUS_SUCCESS,
            payload,
          }))
        )
      )
    )
  );

  uploadUserImage$: any = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadUserImage),
      switchMap(({ file, pictureType }) => {
        return this.users
          .uploadProfilePicture(file, pictureType)
          .pipe(map((payload) => ({ type: UserActionsEnum.UPLOAD_USER_IMAGE_SUCCESS, payload })));
      })
    )
  );

  patchUserProfile$: any = createEffect(() =>
    this.actions$.pipe(
      ofType(patchUserProfileImage),
      switchMap(({ picture }) => {
        return this.users
          .updateProfilePicture(picture)
          .pipe(map((payload) => ({ type: UserActionsEnum.PATCH_USER_PROFILE_IMAGE_SUCCESS, payload })));
      })
    )
  );

  patchUserBackground$: any = createEffect(() =>
    this.actions$.pipe(
      ofType(patchUserBackgroundImage),
      switchMap(({ picture }) => {
        return this.users
          .updateBackgroundPicture(picture)
          .pipe(map((payload) => ({ type: UserActionsEnum.PATCH_USER_BACKGROUND_IMAGE_SUCCESS, payload })));
      })
    )
  );

  deleteUserImage: any = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteUserImage),
      switchMap(({ pictureType }) => {
        return this.users
          .deleteProfilePicture(pictureType)
          .pipe(map((payload) => ({ type: UserActionsEnum.DELETE_USER_IMAGE_SUCCESS, payload })));
      })
    )
  );

  displaySuccess$: any = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          uploadUserImageSuccess,
          patchUserBackgroundImageSuccess,
          deleteUserImageSuccess,
          patchUserProfileImageSuccess
        ),
        tap((err) => this.toastr.success(err.type))
      ),
    {
      dispatch: false,
    }
  );

  ngrxOnInitEffects(): Action {
    this.initAccount();

    return {
      type: '',
    };
  }

  private initAccount(): void {
    this.msalBroadcast.msalSubject$
      .pipe(filter(({ eventType }) => eventType === EventType.HANDLE_REDIRECT_END))
      .subscribe(() => {
        const { name, localAccountId } = this.msal.instance.getAllAccounts()[0];

        this.store.dispatch(getContentCreatorStatus());
        this.store.dispatch(getUser({ displayName: name ?? '', userId: localAccountId }));
      });
  }
}
