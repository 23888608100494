<div class="comment-reply">
  <ng-container *ngFor="let r of repliesFormArray.controls; let i = index; let last = last">
    <app-comment-item
      [itemFormGroup]="getFormGroupIndex(i)"
      [user]="user"
      [isThreadItem]="true"
      [displayThreadLine]="
        !last || formGroup.value['isThreadOpen'] || formGroup.value['hasMoreReplies'] || oldRepliesLength === 0
      "
      (replyInput)="formGroup.controls['isThreadOpen'].setValue(true)"
    ></app-comment-item>
  </ng-container>

  <div
    *ngIf="(oldRepliesLength === 0 && formGroup.value['replyCount'] > 0) || formGroup.value['hasMoreReplies']"
    class="load-more-replies"
  >
    <i *ngIf="formGroup.value['isThreadOpen']" class="straight-line"></i>
    <i class="curvy-line"></i>

    <button (click)="getReplies(true)" class="load-more-replies__load-more link">
      <mat-icon class="icon-1rem">reply</mat-icon>
      {{ formGroup.value['replyCount'] - oldRepliesLength }} replies

      <ng-container *ngIf="loadMoreReplies" [ngTemplateOutlet]="displayLoading"></ng-container>
    </button>
  </div>

  <div *ngIf="formGroup.value['isThreadOpen']" class="reply-input-block">
    <span class="thread-image-block">
      <i class="curvy-line"></i>

      <app-profile-image
        imageSize="small"
        [photo]="user.profilePhotoThumbnail!"
        [name]="user.displayName"
        [userId]="user.userId"
      ></app-profile-image>
    </span>

    <app-comment-editor
      [editorFormGroup]="replyCommentForm"
      [loading$]="isLoading$"
      (handleComment)="addReplyComment()"
    ></app-comment-editor>
  </div>
</div>

<ng-template #displayLoading>
  <app-loading loaderSize="xsmall"></app-loading>
</ng-template>
