import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-destroy-base',
  template: '',
})
export class DestroyBaseComponent implements OnDestroy {
  destroy$ = new Subject<boolean>();

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    // eslint-disable-next-line rxjs/no-subject-unsubscribe
    this.destroy$.unsubscribe();
  }
}
