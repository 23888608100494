<button
  (click)="addReaction(predefinedEmojis[0], true)"
  [ngClass]="{
    selected: formGroup.value['userReaction'],
    active: popupOpen
  }"
  cdkOverlayOrigin
  #overlayOrigin="cdkOverlayOrigin"
  class="select-reaction active"
  >React</button
>
<app-popup
  (open)="popupOpen = true"
  (close)="popupOpen = false"
  [overlayOrigin]="overlayOrigin"
  [hideDelay]="75"
  [showDelay]="75"
  [offsetY]="-5"
  position="top"
>
  <ul class="reactions-popup" [ngClass]="{ 'dark-mode-popup': (darkMode$ | async) }">
    <li
      *ngFor="let emoji of predefinedEmojis"
      [class.selected-reaction]="emoji === formGroup.value['userReaction']?.reaction"
    >
      <ngx-emoji (click)="addReaction(emoji)" [emoji]="emoji" [size]="24" [isNative]="true"></ngx-emoji>
    </li>
  </ul>
</app-popup>
