import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgIf, NgStyle } from '@angular/common';

@Component({
  selector: 'app-image-dialog',
  standalone: true,
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, NgStyle],
})
export class ImageDialogComponent {
  protected readonly data = inject(MAT_DIALOG_DATA);
}
