import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userInitials',
  standalone: true,
})
export class UserInitialsPipe implements PipeTransform {
  transform(name: string): string {
    return name
      .split(' ')
      .slice(0, 3)
      .map((element) => element.charAt(0))
      .join('')
      .toUpperCase();
  }
}
