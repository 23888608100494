import { Injectable } from '@angular/core';
import { FeedElementBaseService } from '@core/services/api/feed-element-base/feed-element-base.service';
import { Article, ArticleDrafts } from '@core/models/article.model';
import { Observable } from 'rxjs';
import { PagedResponse } from '@core/models/paged-response.model';
import { buildUrl } from '@core/utils/buildUrl';
import { Params } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ArticlesService extends FeedElementBaseService<Article> {
  constructor() {
    super('articles');
  }

  getArticleDrafts(
    elementsCount: number,
    requestedElements: number,
    params?: Params
  ): Observable<PagedResponse<ArticleDrafts>> {
    const url = buildUrl(`${this.baseUrl}/current-user/minimised`, {
      elementsCount,
      requestedElements,
      params,
    });

    return this.http.get<PagedResponse<ArticleDrafts>>(url);
  }

  publish(id: string): Observable<Article> {
    return this.http.patch<Article>(`${this.baseUrl}/publish/${id}`, id);
  }

  views(id: string): Observable<{ views: number }> {
    return this.http.patch<{ views: number }>(`${this.baseUrl}/${id}/views`, id);
  }
}
