import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DestroyBaseComponent } from '@core/components/destroy-base/destroy-base.component';
import { ActionType, IAction } from '@core/models/action.model';
import { GroupedReactions, PublicationReactions } from '@core/models/common-publication-interfaces.model';
import { EmojiData } from '@ctrl/ngx-emoji-mart/ngx-emoji/data/data.interfaces';
import { Store } from '@ngrx/store';
import { ReactionsDialogComponent } from '@shared/components/dialogs/reactions-dialog/reactions-dialog.component';
import { DarkModeService } from '@shared/services/dark-mode.service';
import { currentUserId } from '@store/user/user.selectors';
import { takeUntil } from 'rxjs';
import { ReactionsService } from '@shared/services/reactions/reactions.service';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FeedElementType } from '@core/models/feed-element.model';
import { Publication } from '@core/models/publication.model';
import { EmojiPickerComponent } from '@shared/components/emoji-picker/emoji-picker.component';

@Component({
  selector: 'app-reactions',
  standalone: true,
  templateUrl: './reactions.component.html',
  styleUrls: ['./reactions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    EmojiModule,
    NgIf,
    NgClass,
    AsyncPipe,
    NgForOf,
    MatIconModule,
    MatButtonModule,
    PickerModule,
    MatTooltipModule,
    EmojiPickerComponent,
  ],
})
export class ReactionsComponent extends DestroyBaseComponent implements OnInit {
  @Input() feedElement: Publication;
  @Input() type: FeedElementType;
  @Output() actionResponse: EventEmitter<ActionType> = new EventEmitter();
  @ViewChild('emoji', { static: false, read: ElementRef }) emoji: ElementRef;

  defaultReactions: Array<string> = ['+1', 'heart', 'smile', 'astonished'];
  actions: IAction[] = [
    {
      type: ActionType.comment,
      icon: 'mode_comment',
      text: 'Comment',
    },
    {
      type: ActionType.share,
      icon: 'share_windows',
      text: 'Share',
    },
  ];
  actionType: typeof ActionType = ActionType;
  isChosen = false;
  isEmojiMartVisible = false;
  isReactionsMenuVisible = false;
  chosenSkinTone: any = 1;
  reactionsCount = 0;
  chosenReaction = '';
  selectedReaction = '';
  sortedFeedElementReactions: GroupedReactions[];
  darkMode$ = inject(DarkModeService).isDarkMode;
  isProcessing = false;
  private feedElementReactions: GroupedReactions[];
  private feedElementReactionsCopy: GroupedReactions[];
  private selectedFeedElementReactions: { [key: string]: string } = {};
  private currentUserId: string;
  private reaction = '';
  private skinTone: any = 1;
  private timer: number;
  private isUserReactionApplied = false;
  private isReactButtonHovered = false;

  constructor(
    private readonly reactions: ReactionsService,
    private readonly dialogService: MatDialog,
    private readonly store: Store,
    private readonly cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.feedElementReactions = this.feedElement.reactions;

    this.countReactions();
    this.sortReactions();

    if (this.feedElement.userReaction) {
      this.chosenReaction = this.feedElement.userReaction.reaction;
      this.chosenSkinTone = this.feedElement.userReaction.skinTone;
      this.isChosen = true;
    }

    this.store
      .select(currentUserId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((id) => {
        this.currentUserId = id;
      });
  }

  @HostListener('click', ['$event'])
  onClick(event: any): void {
    if (event.target?.attributes.getNamedItem('alt')?.nodeValue === 'More Reactions') {
      if (event.y > window.innerHeight - 357) {
        this.emoji.nativeElement.classList.add('emoji-above');
      }
    }
  }

  public openEmojiPicker(): void {
    this.isEmojiMartVisible = !this.isEmojiMartVisible;
    this.setSelectedFeedElementReactions();
    !this.isEmojiMartVisible ? this.unmarkChosenReaction() : this.markChosenReaction();
  }

  public addReaction(reactionData?: EmojiData | any): void {
    if (reactionData === undefined) {
      this.isEmojiMartVisible = false;
      this.isReactionsMenuVisible = false;
      this.unmarkChosenReaction();
    } else {
      this.setChosenReactionData(reactionData);

      if (this.reaction === this.chosenReaction && this.skinTone === this.chosenSkinTone) {
        this.resetReaction();
      } else {
        if (this.reaction && this.skinTone) {
          this.setSelectedFeedElementReactions();

          this.reactions
            .add({
              feedElementId: this.feedElement.id,
              reaction: this.reaction,
              skinTone: this.skinTone,
              feedElementType: this.type,
            })
            .subscribe();

          if (this.feedElementReactions.length) {
            if (this.isChosen) {
              this.resetPreviousReaction();
            }
            this.applyUserReaction();
          } else {
            this.feedElementReactionsCopy = [...this.feedElementReactions];
            this.feedElementReactionsCopy.push({
              reaction: this.reaction,
              skinTone: this.skinTone,
              count: 1,
            });
          }
          this.feedElementReactions = this.feedElementReactionsCopy;
          this.chosenReaction = this.reaction;
          this.chosenSkinTone = this.skinTone;
          this.isChosen = true;
          this.isEmojiMartVisible = false;
          this.isReactionsMenuVisible = false;

          this.unmarkChosenReaction();
        }
        this.countReactions();
        this.sortReactions();
      }
    }
  }

  public checkChosenReaction(reactionData: string): string {
    let result = '';

    switch (true) {
      case (!this.defaultReactions.includes(reactionData) && this.isChosen) ||
        (reactionData === this.chosenReaction && 1 !== this.chosenSkinTone):
        result = 'more-reactions';
        break;
      case reactionData === this.chosenReaction && 1 === this.chosenSkinTone:
        result = reactionData;
        break;
      default:
        result = '';
    }

    return result;
  }

  public reactButton(): void {
    '' === this.chosenReaction ? this.addReaction('+1') : this.resetReaction();
  }

  public mouseEnter(): void {
    if (!this.isReactButtonHovered) {
      this.isReactButtonHovered = true;

      this.timer = window.setTimeout(() => {
        this.isReactionsMenuVisible = true;
        this.cdr.detectChanges();
      }, 260);
    }
  }

  public mouseLeave(): void {
    if (!this.isEmojiMartVisible) {
      this.isReactionsMenuVisible = this.isReactButtonHovered = false;
    }

    clearTimeout(this.timer);
    this.cdr.detectChanges();
  }
  private resetReaction(): void {
    if (this.isChosen) {
      this.resetPreviousReaction();
      this.unmarkChosenReaction();

      this.reactions.delete({ feedElementId: this.feedElement.id, feedElementType: this.type }).subscribe();

      this.feedElementReactions = this.feedElementReactionsCopy;
      this.reaction = this.chosenReaction = '';
      this.skinTone = this.chosenSkinTone = 1;
      this.isChosen = false;
      this.isReactionsMenuVisible = false;
      this.isEmojiMartVisible = false;
    } else {
      this.addReaction('+1');
      this.isChosen = true;
    }

    this.countReactions();
    this.sortReactions();
  }

  // TODO: change any to specific type
  private setChosenReactionData(reactionData: EmojiData | any): void {
    this.reaction = '';
    this.skinTone = 1;

    if (typeof reactionData === 'string') {
      this.reaction = reactionData;
    } else if (this.isEmojiData(reactionData.emoji)) {
      const textBetweenColonsRegEx = /(?<=:)(.*?)(?=:)/g;
      const digitRegEx = '\\d';
      const reactionWithColons = reactionData.emoji.colons;
      const reactionMatch = [...reactionWithColons.matchAll(textBetweenColonsRegEx)];

      this.reaction = reactionMatch[0] !== undefined ? reactionMatch[0][0] : '';

      if (reactionMatch[2] !== undefined) {
        const skinToneMatch: RegExpMatchArray | null = reactionMatch[2][0].match(digitRegEx);
        this.skinTone = skinToneMatch !== null ? parseInt(skinToneMatch[0]) : 1;
      }
    }
  }

  private isEmojiData(object: EmojiData | any): object is EmojiData {
    return (<EmojiData>object).id !== undefined;
  }

  private applyUserReaction(): void {
    if (Object.keys(this.feedElementReactionsCopy).length) {
      this.feedElementReactionsCopy.forEach((item, index) => {
        if (item.reaction === this.reaction && item.skinTone === this.skinTone) {
          this.isUserReactionApplied = true;
          let reactionCount: number = item.count;
          reactionCount++;
          this.feedElementReactionsCopy[index] = {
            reaction: item.reaction,
            skinTone: item.skinTone,
            count: reactionCount,
          };
        }
      });

      if (!this.isUserReactionApplied) {
        this.feedElementReactionsCopy.push({
          reaction: this.reaction,
          skinTone: this.skinTone,
          count: 1,
        });
      }
      this.isUserReactionApplied = false;
    } else {
      this.feedElementReactionsCopy = [
        {
          reaction: this.reaction,
          skinTone: this.skinTone,
          count: 1,
        },
      ];
    }
  }

  public expandFeedElementReactions(): void {
    if (this.isProcessing) return;
    this.isProcessing = true;
    this.reactions
      .getAll(this.feedElement.id, this.type)
      .subscribe((feedElementReactionsData: PublicationReactions[]) => {
        this.dialogService
          .open(ReactionsDialogComponent, {
            width: '600px',
            height: '570px',
            data: {
              currentUser: this.currentUserId,
              feedElementReactionsData: feedElementReactionsData,
              groupedFeedElementReactions: this.feedElementReactions,
            },
            panelClass: 'reactions-dialog-container',
          })
          .afterClosed()
          .subscribe(() => {
            this.isProcessing = false;
          });
      });
  }

  private countReactions(): void {
    this.reactionsCount = 0;

    if (this.feedElementReactions.length === 1) {
      this.reactionsCount = this.feedElementReactions[0].count;
    } else if (this.feedElementReactions.length > 1) {
      this.feedElementReactions.forEach((item: GroupedReactions) => {
        this.reactionsCount = this.reactionsCount + item.count;
      });
    }
  }

  private sortReactions(): void {
    this.feedElementReactionsCopy = [...this.feedElementReactions];
    this.feedElementReactionsCopy.sort(function (a, b) {
      if (a.count < b.count) return 1;
      if (a.count > b.count) return -1;
      return 0;
    });

    this.feedElementReactions = this.feedElementReactionsCopy;
    this.sortedFeedElementReactions = this.feedElementReactions.filter(function (value, index) {
      return index <= 2;
    });
  }

  private resetPreviousReaction(): void {
    this.feedElementReactionsCopy = [...this.feedElementReactions];
    this.feedElementReactionsCopy.forEach((item, index) => {
      if (item.reaction === this.chosenReaction && item.skinTone === this.chosenSkinTone) {
        let reactionCount: number = item.count;
        if (item.count > 1) {
          reactionCount--;
          this.feedElementReactionsCopy[index] = {
            reaction: item.reaction,
            skinTone: item.skinTone,
            count: reactionCount,
          };
        } else {
          this.feedElementReactionsCopy.splice(index, 1);
        }
      }
    });
  }

  private markChosenReaction(): void {
    const chosenReaction = this.chosenReaction === '+1' ? 'thumbsup' : this.chosenReaction;
    const style = document.createElement('style');
    style.innerHTML = `
            [aria-label~="${chosenReaction}"] {
                  border: 3px solid #45a29e;
                  border-radius: 18%;
            }
          `;
    document.head.appendChild(style);
  }

  private unmarkChosenReaction(): void {
    if (Object.keys(this.selectedFeedElementReactions).length) {
      Object.keys(this.selectedFeedElementReactions).forEach((item: string) => {
        const style: HTMLStyleElement = document.createElement('style');
        style.innerHTML = `
              [aria-label~="${item}"] {
                border: none;
              }
            `;
        document.head.appendChild(style);
      });
    }
  }

  private setSelectedFeedElementReactions(): void {
    this.selectedReaction = this.chosenReaction === '+1' ? 'thumbsup' : this.chosenReaction;
    this.selectedFeedElementReactions[this.selectedReaction] = this.selectedReaction;
  }

  public isShareInactive(action: IAction): boolean {
    return this.feedElement.isPrivate && action.type === ActionType.share;
  }
}
