<mat-card [ngClass]="(darkMode$ | async) ? 'dark-mode-share-post' : ''" class="share-post mat-elevation-z0">
  <div class="flex-end">
    <h2 class="title">{{ data.post && data.isPostEdited ? 'Update post' : 'Create a post' }}</h2>
    <mat-icon class="close-icon" mat-dialog-close="true">close</mat-icon>
  </div>
  <form [formGroup]="postFormGroup" class="share-post__form">
    <div [ngClass]="(darkMode$ | async) ? 'dark-mode-share-post__form--label' : ''" class="share-post__form--label"
      >Share thoughts, ideas or updates</div
    >
    <div
      [ngClass]="(darkMode$ | async) ? 'dark-mode-share-post__form--input-container' : ''"
      class="share-post__form--input-container"
    >
      <span
        #postTextarea
        [ngClass]="(darkMode$ | async) ? 'dark-mode-share-post__form--post-input' : ''"
        class="share-post__form--post-input"
        contenteditable
        data-placeholder="Your thoughts..."
        formControlName="text"
      ></span>
      <span [ngClass]="(darkMode$ | async) ? 'dark-mode-counter' : ''" class="counter first" *ngIf="characterCounter">
        {{ characterCounter }} / {{ MAX_POST_SYMBOLS }}
      </span>
    </div>
    <div [ngClass]="(darkMode$ | async) ? 'dark-mode-insert' : ''" class="insert">
      <app-emoji-picker (selectedEmoji)="addReaction($event)" (click)="getCursorPosition()"></app-emoji-picker>
    </div>
  </form>

  <div class="share-post__content">
    <header>
      <app-profile-image
        [photo]="data.user.profilePhotoThumbnail!"
        [name]="data.user.displayName"
        [userId]="data.user.userId"
      ></app-profile-image>

      <div class="post-details">
        <div class="post-details__author">
          <app-user-name
            [userId]="data.user.userId"
            [userName]="data.user.displayName"
            [isContentCreator]="data.user.isContentCreator"
          ></app-user-name>

          <ng-container *ngIf="!data.communityFeed && data.community">
            <span> posted in </span>

            <app-community-name [community]="data.community"></app-community-name>
          </ng-container>
        </div>

        <div class="post-details__date">
          <mat-icon class="icon-1rem">schedule</mat-icon>
          {{ data.creationDate | timeAgo }}
          <span *ngIf="data.isPostEdited">
            <mat-icon class="mat-icon-fill dot">fiber_manual_record</mat-icon>
            Edited</span
          >
        </div>
      </div>
    </header>

    <div *ngIf="data.publicationType === publicationType.post" class="post__post-content">
      <p [innerHTML]="data.text" class="post__post-content--text"></p>

      <app-gallery-preview *ngIf="data.images?.length" [images]="data.images!"></app-gallery-preview>
    </div>

    <ng-container *ngIf="data.documents?.length">
      <app-attached-files [readonlyDocs]="data.documents!"></app-attached-files>
    </ng-container>
  </div>

  <div *ngIf="data.publicationType === publicationType.article" class="article__article-content">
    <ng-container *ngIf="data.articlePreview">
      <p
        class="article__article-content--text article__article-content--description"
        [innerHTML]="data.articlePreview"
      ></p>
    </ng-container>

    <div class="article-wrapper">
      <p class="article__article-content--text article__article-content--title" [innerHTML]="data.articleTitle"></p>
      <p
        class="article__article-content--text article__article-content--preview"
        [innerHTML]="truncContent(data.text)"
      ></p>
    </div>

    <div *ngIf="data.documents?.length">
      <app-attached-files [readonlyDocs]="data.documents!"></app-attached-files>
    </div>
  </div>

  <mat-dialog-actions [ngClass]="(darkMode$ | async) ? 'dark-mode-actions' : 'dialog-actions'" align="end">
    <button mat-button [mat-dialog-close]="false">Cancel</button>
    <button (click)="share()" [disabled]="isFormInvalid" [mat-dialog-close]="true" mat-stroked-button color="primary">
      {{ data.post && data.isPostEdited ? 'Update' : 'Share' }}
    </button>
  </mat-dialog-actions>
</mat-card>
