import { UserRole } from '@core/models/community.model';
import { Permission } from '@core/enums/permission.enum';

export enum UserImagesEndpoints {
  profilePhoto = 'profile-picture',
  backgroundPhoto = 'background-picture',
}

export enum UserImagesType {
  profilePhoto = 'profilePhoto',
  backgroundPhoto = 'backgroundPhoto',
}

export interface User extends UserImages {
  userId: string;
  jobTitle?: string;
  mail?: string;
  displayName: string;
  companyName?: string;
  department?: string;
  mobilePhone?: string;
  followingCount?: number;
  followersCount?: number;
  postsCount?: number;
  articlesCount?: number;
  isFollowing?: boolean;
  searchUserInformation?: SearchUserInformation;
  isOnline?: boolean;
  lastOnline?: string;
  email?: string;
  profilePhotoThumbnail?: string;
  birthday?: string;
  officeCity?: string;
  appRoles?: Permission[];
  isContentCreator?: boolean;
  isDeleted?: boolean;
}

export interface PublicationUser extends UserImages {
  userId: string;
  displayName: string;
  profilePhotoThumbnail?: string;
  isCurrentUserFollowing?: boolean;
  isContentCreator: boolean;
  isDeleted: boolean;
}

export interface Mention {
  id?: number;
  value: string;
  link: string;
  avatar: string;
  jobTitle?: string;
}

export interface SearchUserInformation {
  followersCount: number;
  isFollowing: boolean;
  postsCount?: number;
  articlesCount?: number;
}

export interface UserImages {
  [UserImagesType.profilePhoto]?: string;
  [UserImagesType.backgroundPhoto]?: string;
}

export interface UserDisplayDto {
  userId: string;
  [UserImagesType.profilePhoto]?: string;
  profilePhotoThumbnail?: string;
  displayName: string;
  isContentCreator?: boolean;
}

export interface Member extends UserDisplayDto {
  jobTitle?: string;
  mail?: string;
  role: UserRole;
  isContentCreator: boolean;
}

export interface UserSuggestionDto extends UserDisplayDto {
  mail?: string;
  jobTitle?: string;
}

export interface Group {
  id: string;
  displayName: string;
  mail: string;
}
