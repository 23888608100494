<ng-container *ngIf="article && user$ | async as currentUser">
  <mat-card class="article">
    <header>
      <ng-container *ngIf="!article.user.isDeleted; else defaultPhoto">
        <app-profile-image
          [photo]="
            currentUser.userId === article.user.userId
              ? currentUser.profilePhotoThumbnail!
              : article.user.profilePhotoThumbnail!
          "
          [name]="currentUser.userId === article.user.userId ? currentUser.displayName : article.user.displayName"
          [userId]="currentUser.userId === article.user.userId ? currentUser.userId : article.user.userId"
        ></app-profile-image>
      </ng-container>
      <div class="article-details">
        <div #author class="article-details__author">
          <ng-container *ngIf="!article.user.isDeleted; else defaultName">
            <app-user-name
              (userIdEmitter)="onUserNameMouseover($event)"
              [userId]="article.user.userId"
              [userName]="article.user.displayName"
              [isContentCreator]="article.user.isContentCreator"
            ></app-user-name>
          </ng-container>
          <ng-container *ngIf="!communityFeed && article.community">
            <span> published in </span>

            <app-community-name [community]="article.community"></app-community-name>
          </ng-container>
        </div>

        <div class="article-details__date">
          <mat-icon class="icon-1rem">schedule</mat-icon>
          <span [matTooltip]="articleCreatedDate">
            {{ article.creationDate | timeAgo }}
          </span>
          <span *ngIf="isArticleEdited">
            <mat-icon class="mat-icon-fill dot">fiber_manual_record</mat-icon>
            Edited</span
          >
        </div>
      </div>

      <button
        *ngIf="article.isPrivate"
        mat-icon-button
        matTooltip="This article is only visible to the community members"
      >
        <mat-icon>lock</mat-icon>
      </button>

      <button
        *ngIf="currentUser.userId && currentUser.userId !== article.user.userId"
        (click)="bookmarkArticle(article.id)"
        mat-icon-button
        class="bookmark"
      >
        <mat-icon [class.mat-icon-fill]="isArticleBookmarked">bookmark</mat-icon>
      </button>

      <ng-container *ngIf="currentUser.userId === article.user.userId || canUpdate">
        <button
          mat-icon-button
          class="article-options"
          [matMenuTriggerFor]="menu"
          [ngClass]="(darkMode$ | async) ? 'dark-mode-white' : ''"
        >
          <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #menu="matMenu" backdropClass="mat-menu-options">
          <div class="menu-article-option">
            <button mat-flat-button (click)="edit()" class="option-button">
              <mat-icon>edit</mat-icon>
              Edit
            </button>

            <button mat-flat-button (click)="deleteArticle()" class="option-button">
              <mat-icon>delete</mat-icon>
              Delete
            </button>
          </div>
        </mat-menu>
      </ng-container>
    </header>

    <div class="article__article-content">
      <button matTooltip="Click to view article" (click)="viewArticle()">
        <ng-container *ngIf="article.preview">
          <p
            class="article__article-content--text article__article-content--description"
            [innerHTML]="article.preview"
          ></p>
        </ng-container>

        <div class="article-wrapper">
          <ng-container *ngIf="article.title">
            <p class="article__article-content--text article__article-content--title" [innerHTML]="article.title"></p>
          </ng-container>

          <ng-container *ngIf="article.text">
            <p
              class="article__article-content--text article__article-content--preview"
              [innerHTML]="truncContent(article.text)"
            ></p>
          </ng-container>
        </div>
      </button>

      <ng-container *ngIf="article?.documents?.length">
        <app-attached-files [readonlyDocs]="article.documents"></app-attached-files>
      </ng-container>
    </div>
    <ng-container *ngIf="canInteract; else cannotInteract">
      <app-reactions
        [feedElement]="article"
        [type]="feedElementType.article"
        (actionResponse)="setActionEvent($event)"
      ></app-reactions>

      <ng-container *ngIf="actionEvent[actionType.comment]">
        <app-comment-box
          [feedElement]="article"
          [type]="feedElementType.article"
          [user]="currentUser"
          (countComments)="countComments($event)"
        ></app-comment-box>
      </ng-container>
    </ng-container>
  </mat-card>
</ng-container>

<ng-template #userInfoPreviewTemplate>
  <app-user-info-preview [userId]="hoveredUserId"></app-user-info-preview>
</ng-template>

<ng-template #cannotInteract>
  <span style="text-align: center; font-weight: bolder">
    Only members of this community can interact with this post
  </span>
</ng-template>

<ng-template #defaultPhoto>
  <mat-icon class="mat-icon-fill default-photo">person</mat-icon>
</ng-template>

<ng-template #defaultName>
  <span style="font-weight: 800; color: #2b4257; cursor: default">Unknown</span>
</ng-template>
