/* eslint-disable @ngrx/prefer-concat-latest-from */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  changeUserRole,
  changeUserRoleError,
  changeUserRoleSuccess,
  CommunitiesActions,
  createCommunity,
  createCommunityError,
  createCommunitySuccess,
  createCommunityWithSample,
  createCommunityWithSampleError,
  createCommunityWithSampleSuccess,
  deleteCommunity,
  deleteCommunityError,
  deleteCommunityImage,
  deleteCommunityImageError,
  deleteCommunityImageSuccess,
  deleteCommunitySuccess,
  getCommunities,
  getCommunitiesError,
  getCommunityById,
  getCommunityMembers,
  getCommunityMembersError,
  joinCommunity,
  joinCommunityError,
  joinCommunitySuccess,
  leaveCommunity,
  leaveCommunityError,
  leaveCommunitySuccess,
  patchCommunity,
  patchCommunityBackgroundImage,
  patchCommunityBackgroundImageError,
  patchCommunityBackgroundImageSuccess,
  uploadCommunityImage,
  uploadCommunityImageError,
  uploadCommunityImageSuccess,
} from '@store/communities/communities.actions';
import { catchError, combineLatestWith, concatMap, first, map, mergeMap, of, switchMap, tap } from 'rxjs';
import { CommunityService } from '@shared/services/community.service';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { currentUser } from '@store/user/user.selectors';
import { Router } from '@angular/router';
import { RoleChangeResponse } from '@core/models/community.model';
import { selectJoinedCommunities, selectRemainingCommunities } from './communities.selectors';

@Injectable()
export class CommunitiesEffects {
  getCommunities$: any = createEffect(() => {
    return this.actions$.pipe(
      ofType(getCommunities),
      concatMap((action) => {
        const selectedCommunities = action.flag ? selectJoinedCommunities : selectRemainingCommunities;

        return this.store.select(selectedCommunities).pipe(
          first(),
          switchMap(({ items }) => this.communityService.getAllCommunities(items.length, 8, action.flag)),
          map((communities) => ({
            type: CommunitiesActions.GET_COMMUNITIES_SUCCESS,
            communities: communities.items,
            flag: action.flag,
            navigateFromJoined: action.navigateFromJoined,
            hasMore: communities.hasMore,
          })),
          catchError((error: unknown) => of({ type: CommunitiesActions.GET_COMMUNITIES_ERROR, payload: error }))
        );
      })
    );
  });
  getCommunityById$: any = createEffect(() => {
    return this.actions$.pipe(
      ofType(getCommunityById),
      switchMap(({ id }) =>
        this.communityService.getCommunityById(id).pipe(
          map((community) => ({ type: CommunitiesActions.GET_COMMUNITY_BY_ID_SUCCESS, payload: community })),
          catchError((error: unknown) => of({ type: CommunitiesActions.GET_COMMUNITY_BY_ID_ERROR, payload: error }))
        )
      )
    );
  });
  joinCommunity$: any = createEffect(() => {
    return this.actions$.pipe(
      ofType(joinCommunity),
      combineLatestWith(this.store.select(currentUser)),
      switchMap(([action, currentUser]) =>
        this.communityService.joinCommunity(action.id).pipe(
          map((joinedCommunity) => {
            return {
              type: CommunitiesActions.JOIN_COMMUNITY_SUCCESS,
              payload: {
                community: joinedCommunity,
                user: { ...currentUser, role: joinedCommunity.currentUserRole },
              },
            };
          }),
          catchError((error: unknown) => {
            return of({ type: CommunitiesActions.JOIN_COMMUNITY_ERROR, payload: error });
          })
        )
      )
    );
  });
  leaveCommunity$: any = createEffect(() => {
    return this.actions$.pipe(
      ofType(leaveCommunity),
      combineLatestWith(this.store.select(currentUser)),
      switchMap(([action, user]) =>
        this.communityService.leaveCommunity(action.id).pipe(
          map((community) => {
            return {
              type: CommunitiesActions.LEAVE_COMMUNITY_SUCCESS,
              payload: { community, user },
            };
          }),
          catchError((error: unknown) => {
            return of({ type: CommunitiesActions.LEAVE_COMMUNITY_ERROR, payload: error });
          })
        )
      )
    );
  });
  patchCommunity$: any = createEffect(() => {
    return this.actions$.pipe(
      ofType(patchCommunity),
      switchMap((action) => {
        return this.communityService.patchCommunity(action.community, action.communityId).pipe(
          map((patchedCommunity) => {
            this.toaster.success('Successfully updated community!');
            return {
              type: CommunitiesActions.PATCH_COMMUNITY,
              payload: patchedCommunity,
            };
          }),
          catchError((error: HttpErrorResponse) => {
            return of({
              type: CommunitiesActions.PATCH_COMMUNITY_ERROR,
              error,
            });
          })
        );
      })
    );
  });
  createCommunity$: any = createEffect(() => {
    return this.actions$.pipe(
      ofType(createCommunity),
      switchMap((action) => {
        return this.communityService.createCommunity(action.community).pipe(
          map((createdCommunity) => {
            return {
              type: CommunitiesActions.CREATE_COMMUNITY_SUCCESS,
              payload: createdCommunity,
            };
          }),
          catchError((error: HttpErrorResponse) => {
            if (error.error === 'Community name already exists') {
              return of({
                type: CommunitiesActions.CREATE_COMMUNITY_NAME_ERROR,
                error,
              });
            }
            return of({
              type: CommunitiesActions.CREATE_COMMUNITY_ERROR,
              error,
            });
          })
        );
      })
    );
  });
  createCommunityWithSample$: any = createEffect(() => {
    return this.actions$.pipe(
      ofType(createCommunityWithSample),
      mergeMap((action) =>
        this.communityService.createCommunityWithSample(action.community, action.sample).pipe(
          map((createdCommunity) => {
            return {
              type: CommunitiesActions.CREATE_COMMUNITY_WITH_SAMPLE_SUCCESS,
              payload: createdCommunity,
            };
          }),
          catchError((error: HttpErrorResponse) => {
            if (error.error === 'Community name already exists') {
              return of({
                type: CommunitiesActions.CREATE_COMMUNITY_WITH_SAMPLE_NAME_ERROR,
                error,
              });
            }
            return of({
              type: CommunitiesActions.CREATE_COMMUNITY_WITH_SAMPLE_ERROR,
              error,
            });
          })
        )
      )
    );
  });
  patchCommunityBackground$: any = createEffect(() => {
    return this.actions$.pipe(
      ofType(patchCommunityBackgroundImage),
      switchMap(({ id, picture }) => {
        return this.communityService.updateBackgroundPicture(id, picture).pipe(
          map((community) => ({
            type: CommunitiesActions.PATCH_COMMUNITY_BACKGROUND_IMAGE_SUCCESS,
            payload: community,
          })),
          catchError((error: unknown) =>
            of({
              type: CommunitiesActions.PATCH_COMMUNITY_BACKGROUND_IMAGE_ERROR,
              payload: error,
            })
          )
        );
      })
    );
  });
  uploadCommunityImage: any = createEffect(() => {
    return this.actions$.pipe(
      ofType(uploadCommunityImage),
      switchMap(({ id, file, pictureType }) => {
        return this.communityService.uploadPicture(id, file, pictureType).pipe(
          map((community) => ({
            type: CommunitiesActions.UPLOAD_COMMUNITY_IMAGE_SUCCESS,
            payload: community,
          })),
          catchError((error: unknown) =>
            of({
              type: CommunitiesActions.UPLOAD_COMMUNITY_IMAGE_ERROR,
              payload: error,
            })
          )
        );
      })
    );
  });
  deleteCommunityImage: any = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteCommunityImage),
      switchMap(({ id, pictureType }) => {
        return this.communityService.deletePicture(id, pictureType).pipe(
          map((community) => ({ type: CommunitiesActions.DELETE_COMMUNITY_IMAGE_SUCCESS, payload: community })),
          catchError((error: unknown) => of({ type: CommunitiesActions.DELETE_COMMUNITY_IMAGE_ERROR, error }))
        );
      })
    );
  });
  getCommunityMembers$: any = createEffect(() => {
    return this.actions$.pipe(
      ofType(getCommunityMembers),
      switchMap(({ id, count }) => {
        return this.communityService.getMembers(id, count).pipe(
          map((members) => ({
            type: CommunitiesActions.GET_COMMUNITY_MEMBERS_SUCCESS,
            payload: members,
          })),
          catchError((error: unknown) =>
            of({
              type: CommunitiesActions.GET_COMMUNITY_MEMBERS_ERROR,
              payload: error,
            })
          )
        );
      })
    );
  });
  deleteCommunity: any = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteCommunity),
      switchMap(({ id }) => {
        return this.communityService.deleteCommunity(id).pipe(
          map((community) => {
            this.router.navigateByUrl('communities');
            return { type: CommunitiesActions.DELETE_COMMUNITY_SUCCESS, payload: community };
          }),
          catchError((error: unknown) => of({ type: CommunitiesActions.DELETE_COMMUNITY_ERROR, error }))
        );
      })
    );
  });
  changeUserRole: any = createEffect(() => {
    return this.actions$.pipe(
      ofType(changeUserRole),
      switchMap(({ communityId, userId, role }) => {
        return this.communityService.changeUserRole(communityId, userId, role).pipe(
          map((response: RoleChangeResponse) => {
            return { type: CommunitiesActions.CHANGE_USER_ROLE_SUCCESS, payload: response };
          }),
          catchError((error: unknown) => of({ type: CommunitiesActions.CHANGE_USER_ROLE_ERROR, error }))
        );
      })
    );
  });

  displayErrors$: any = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          getCommunitiesError,
          joinCommunityError,
          leaveCommunityError,
          createCommunityError,
          createCommunityWithSampleError,
          patchCommunityBackgroundImageError,
          getCommunityMembersError,
          uploadCommunityImageError,
          deleteCommunityImageError,
          deleteCommunityError,
          changeUserRoleError
        ),
        tap((err) => this.toaster.error(err.type))
      );
    },
    {
      dispatch: false,
    }
  );

  displaySuccess$: any = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          joinCommunitySuccess,
          leaveCommunitySuccess,
          createCommunitySuccess,
          createCommunityWithSampleSuccess,
          patchCommunityBackgroundImageSuccess,
          uploadCommunityImageSuccess,
          deleteCommunityImageSuccess,
          deleteCommunitySuccess,
          changeUserRoleSuccess
        ),
        tap((err) => this.toaster.success(err.type))
      );
    },
    {
      dispatch: false,
    }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly communityService: CommunityService,
    private readonly store: Store,
    private readonly toaster: ToastrService,
    private readonly router: Router
  ) {}
}
