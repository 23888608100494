<div class="cards" *ngIf="loading$ | async; else skeletonSuggestions">
  <ng-container *ngIf="suggestions$ | async as suggestions">
    <ng-container *ngIf="suggestions.length > 0; else placeholderContent">
      <app-suggestion-card
        *ngFor="let suggestion of suggestions; trackBy: identify"
        [suggestion]="suggestion"
        (removeSuggestion)="removeSuggestion($event)"
      ></app-suggestion-card>
    </ng-container>
  </ng-container>
</div>

<ng-template #placeholderContent>
  <ng-container *ngIf="requestFailed; then suggestionsError; else noSuggestions"> </ng-container>
</ng-template>

<ng-template #noSuggestions>
  <h4 class="placeholder-title mat-mdc-card">
    There are no suggestions available at the moment. However, you can
    <span class="link" (click)="loadSuggestions()">load more</span>
  </h4>
</ng-template>

<ng-template #suggestionsError>
  <h4 class="placeholder-title mat-mdc-card">
    <mat-icon>report_problem</mat-icon>
    Oops, we encountered an issue loading your suggestions. Please
    <span class="link" (click)="loadSuggestions()">try again</span>
  </h4>
</ng-template>

<ng-template #skeletonSuggestions>
  <section class="skeleton-block">
    <mat-card *ngFor="let i of [].constructor(4)" class="skeleton-block__skeleton-card">
      <i class="skeleton-block__skeleton-card--image skeleton-loader"></i>
      <span class="skeleton-block__skeleton-card--name skeleton-loader"></span>
      <span class="skeleton-block__skeleton-card--job skeleton-loader"></span>
      <span class="skeleton-block__skeleton-card--mutuals skeleton-loader"></span>
    </mat-card>
  </section>
</ng-template>
