import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Suggestion } from '@core/models/suggestion.model';
import { SuggestionsService } from '@shared/services/suggestions/suggestions.service';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { CommonModule } from '@angular/common';
import { SuggestionCardComponent } from '@shared/components/suggestion-card/suggestion-card.component';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-suggestions',
  standalone: true,
  templateUrl: './suggestions.component.html',
  styleUrls: ['./suggestions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, SuggestionCardComponent, MatIconModule, MatCardModule],
})
export class SuggestionsComponent implements OnInit {
  suggestions$ = new BehaviorSubject<Suggestion[]>([]);

  private _skeLoading = new BehaviorSubject<boolean>(true);
  public requestFailed: boolean;

  constructor(private readonly suggestions: SuggestionsService) {}

  ngOnInit(): void {
    this.loadSuggestions();
  }

  public loadSuggestions(): void {
    this._skeLoading.next(false);

    this.suggestions
      .getAll()
      .pipe(
        catchError((error: unknown) => {
          this._skeLoading.next(true);
          this.requestFailed = true;
          return throwError(() => error);
        })
      )
      .subscribe(
        (suggestions) => (
          this._skeLoading.next(true), (this.requestFailed = false), this.suggestions$.next(suggestions)
        )
      );
  }

  public removeSuggestion(userId: string): void {
    this.suggestions$.next(this.suggestions$.value.filter((suggestion) => suggestion.recommendedUser.userId != userId));
  }

  public get loading$(): Observable<boolean> {
    return this._skeLoading.asObservable();
  }

  public identify(index: number, item: Suggestion): string {
    return item.recommendedUser.userId;
  }
}
