import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { IImage } from '@core/models/common-publication-interfaces.model';
import { MatDialog } from '@angular/material/dialog';
import { GalleryDialogComponent } from '@shared/components/dialogs/gallery-dialog/gallery-dialog.component';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { AnimateLoadingDirective } from '@shared/directives/animate-loading.directive';

@Component({
  selector: 'app-gallery-preview',
  standalone: true,
  templateUrl: './gallery-preview.component.html',
  styleUrls: ['./gallery-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, AnimateLoadingDirective, NgOptimizedImage],
})
export class GalleryPreviewComponent {
  @Input() images: IImage[];
  @Input() gap = 5;

  private readonly dialog = inject(MatDialog);

  public openCarousel(index: number): void {
    this.dialog.open(GalleryDialogComponent, {
      data: { images: this.images, index: index },
      width: '720px',
      height: '600px',
    });
  }

  public get sliceImages(): IImage[] {
    return this.images.slice(0, 4);
  }
}
