import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { DarkModeService } from '@shared/services/dark-mode.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-timed-button',
  standalone: true,
  templateUrl: './timed-button.component.html',
  styleUrls: ['./timed-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
})
export class TimedButtonComponent {
  @Input()
  disabled: boolean;
  @Input()
  color: 'red' | 'amber' | 'blue' = 'blue';

  @Output()
  timerFinish: EventEmitter<void> = new EventEmitter<void>();

  public decreaseTime: number | null = null;
  public increaseTime: number;
  darkMode$ = inject(DarkModeService).isDarkMode;
  private timeLeft = 1000;

  public stopClick(): void {
    if (this.decreaseTime) {
      clearInterval(this.decreaseTime);

      this.decreaseTime = null;

      this.increaseTime = window.setInterval(() => {
        if (this.timeLeft > 0) this.timeLeft += 4;

        if (this.timeLeft == 1000) clearInterval(this.increaseTime);
      });
    }
  }

  public startClick(): void {
    this.decreaseTime = window.setInterval(() => {
      if (this.increaseTime) clearInterval(this.increaseTime);

      if (this.timeLeft == 0) {
        this.timerFinish.emit();

        clearInterval(this.increaseTime);

        if (this.decreaseTime) clearInterval(this.decreaseTime);
      } else this.timeLeft -= 4;
    });
  }
}
