import { ChangeDetectionStrategy, Component, EventEmitter, Output, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { DarkModeService } from '@shared/services/dark-mode.service';
import { MatIconModule } from '@angular/material/icon';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-emoji-picker',
  standalone: true,
  templateUrl: './emoji-picker.component.html',
  styleUrls: ['./emoji-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, PickerModule, MatButtonModule, MatMenuModule, MatIconModule],
})
export class EmojiPickerComponent {
  @Output() selectedEmoji = new EventEmitter<any>();
  darkMode$ = inject(DarkModeService).isDarkMode;
  selected = false;
  emoji: any;

  onSelectedEmoji(emoji: any): void {
    this.selected = true;
    this.emoji = emoji;
  }

  onMenuClosed(): void {
    if (this.selected) {
      this.selected = false;
    } else {
      this.emoji = undefined;
    }
    this.selectedEmoji.emit(this.emoji);
  }
}
