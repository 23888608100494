import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DestroyBaseComponent } from '@core/components/destroy-base/destroy-base.component';
import { CommunityMention } from '@core/models/community.model';
import { CreatePost, EditPost } from '@core/models/post.model';
import { Mention, User } from '@core/models/user.model';
import { Store } from '@ngrx/store';
import { CommunityService } from '@shared/services/community.service';
import { ConvertorService } from '@core/services/helpers/file-convertor/convertor.service';
import { DarkModeService } from '@shared/services/dark-mode.service';
import { UsersService } from '@shared/services/users/users.service';
import { addPost, patchPost } from '@store/posts-store/posts-store.actions';
import { base64ToFile } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, catchError, map, of, switchMap, throwError } from 'rxjs';

import * as QuillNamespace from 'quill';
import MagicUrl from 'quill-magic-url';
import 'quill-mention';
import { Document } from '@core/models/document.model';
import { MAX_POST_SYMBOLS } from '@core/constants/constants';
import { UrlPreviewService } from '@shared/services/url-preview/url-preview.service';
import { UrlPreview } from '@core/models/url-preview.model';
import { FormControl, FormGroup, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { UrlPreviewComponent } from '@shared/components/url-preview/url-preview.component';
import { DocumentsInputComponent } from '@shared/components/inputs/documents-input/documents-input.component';
import { MediaInputComponent } from '@shared/components/inputs/media-input/media-input.component';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { AttachedFilesComponent } from '@shared/components/attached-files/attached-files.component';
import { PostImagesPreviewComponent } from '@shared/components/post-images-preview';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EmojiPickerComponent } from '@shared/components/emoji-picker/emoji-picker.component';

const Quill: any = QuillNamespace;
Quill.register('modules/magicUrl', MagicUrl);

@Component({
  selector: 'app-post-input-dialog',
  standalone: true,
  templateUrl: './post-input-dialog.component.html',
  styleUrls: ['./post-input-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatDialogModule,
    UrlPreviewComponent,
    DocumentsInputComponent,
    MediaInputComponent,
    MatButtonModule,
    MatButtonToggleModule,
    FormsModule,
    AttachedFilesComponent,
    PostImagesPreviewComponent,
    MatTooltipModule,
    PickerModule,
    EmojiPickerComponent,
  ],
})
export class PostInputDialogComponent extends DestroyBaseComponent implements OnInit, AfterViewInit {
  @ViewChild('editor', { static: true }) editor: ElementRef;
  @ViewChild('urlContainer', { static: true }) urlContainer: ElementRef;

  postFormGroup = new FormGroup({
    id: new FormControl(''),
    communityId: new FormControl(''),
    text: new FormControl(''),
    existingImages: new FormControl<string[]>([]),
    newImages: new FormControl<File[]>([]),
    imageAlbum: new FormControl<File[]>([]),
    document: new FormControl<File | null>(null),
    newDocument: new FormControl<File | null>(null),
    isPrivate: new FormControl(false),
    deleteExistsDocument: new FormControl(false),
    urlPreviewLink: new FormControl(''),
    urlPreviewTitle: new FormControl(''),
    urlPreviewDescription: new FormControl(''),
    urlPreviewImage: new FormControl(''),
  });
  darkMode$ = inject(DarkModeService).isDarkMode;

  public attachment = new FormControl<Document[]>([]);
  public isFileAttached = false;
  public imageCounter = 0;
  public isPrivateControl = false;
  public quill: QuillNamespace.Quill;

  public url = '';
  public urlPreview?: UrlPreview;
  public isPreviewActive = false;

  private uploadedImages$ = new BehaviorSubject<string[]>([]);
  private _users = new BehaviorSubject<User[]>([]);
  private _communities = new BehaviorSubject<CommunityMention[]>([]);

  private readonly userPlaceholder =
    'https://www.istar.ac.uk/wp-content/themes/iSTARMB/library/img/headshot_placeholder.jpg';
  private readonly communityPlaceholder = 'https://www.freeiconspng.com/uploads/community-icon-1.png';
  protected readonly maxImageCount = 5;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      id?: string;
      text?: string;
      communityId?: string;
      images?: string[];
      documents: Document[];
      isPrivate?: boolean;
      isFileAttached?: boolean;
      isEditDialog?: boolean;
    },
    private dialogRef: MatDialogRef<PostInputDialogComponent>,
    private readonly toastService: ToastrService,
    private readonly store: Store,
    private readonly convertor: ConvertorService,
    private readonly urlPreviewService: UrlPreviewService,
    private readonly cdr: ChangeDetectorRef,
    private userService: UsersService,
    private communityService: CommunityService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.data.images) {
      this.uploadedImages$.next(this.data.images);
      this.imageCounter = this.data.images.length;
    }

    if (this.data.documents) {
      this.attachment.setValue(this.data.documents);
    }

    if (this.data.isPrivate) {
      this.isPrivateControl = this.data.isPrivate;
    }

    if (this.data.isFileAttached) {
      this.isFileAttached = this.data.isFileAttached;
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.quill = new Quill('#editor', {
        theme: 'bubble',
        placeholder: 'Share thoughts, ideas or updates. You can also use @ to tag people or ^ to tag a community',
        modules: {
          magicUrl: true,
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ color: [] }, { background: [] }],
            ['clean'],
            ['link'],
          ],
          mention: {
            allowedChars: /^[A-Za-z0-9_ ]*$/,
            mentionDenotationChars: ['@', '^'],
            linkTarget: '_self',
            isolateCharacter: false,
            minChars: 1,
            positioningStrategy: 'fixed',
            source: this.getMentions.bind(this),
            renderItem: this.renderItem,
            renderLoading: this.renderLoading,
          },
        },
      });

      this.quill.addContainer(this.urlContainer.nativeElement as HTMLElement);

      this.quill.on('text-change', (delta, oldContents) => {
        const deltaOperation = delta.ops?.find((op) => op.attributes && op.attributes['link']);

        const firstUrl = this.quill.getContents().ops?.find((op) => op.attributes && op.attributes['link']);

        const previousFirstUrl = oldContents.ops?.find((op) => op.attributes && op.attributes['link']);

        this.url = firstUrl?.attributes ? firstUrl?.attributes['link'] : '';

        if (firstUrl?.attributes && !this.urlPreview) this.getUrlPreview(this.url);

        if (firstUrl?.attributes && previousFirstUrl?.attributes) {
          const differentUrl = firstUrl.attributes['link'] !== previousFirstUrl.attributes['link'];

          if (deltaOperation && differentUrl) this.getUrlPreview(this.url);
        }
      });

      if (this.data.text) {
        this.editor.nativeElement.firstChild.innerHTML = this.data.text;
        this.cdr.detectChanges();
      }

      setTimeout(() => {
        const contentLength = this.editor.nativeElement.firstChild.firstChild.innerHTML.length;
        if (contentLength) {
          this.quill.setSelection(contentLength, contentLength);
        } else this.quill.focus();
      }, 300);
    }, 0);
  }

  public closeUrlPreview(event: boolean): void {
    if (event) {
      this.url = '';
      this.urlPreview = undefined;
      this.isPreviewActive = false;
    }
  }

  public closeWindow(): void {
    if (this.dialogRef.getState() !== 0) {
      this.uploadedImages$.subscribe((images) => {
        this.dialogRef.close({
          text: this.editor.nativeElement.firstChild.innerHTML.trim(),
          images,
          isPrivate: this.isPrivateControl,
        });
        const isEmptyString =
          !this.editor.nativeElement.firstChild.innerText ||
          /^\s*$/.test(this.editor.nativeElement.firstChild.innerText as string);
        this.dialogRef.close({
          text: isEmptyString ? '' : this.editor.nativeElement.firstChild.innerHTML,
          images,
          isPrivate: this.isPrivateControl,
        });
      });
    }
  }

  public post(): void {
    if (this._validateFormData()) {
      this.data?.id
        ? this.editPostDto$.subscribe((postDto) => this._editPost(postDto))
        : this.createPostDto$.subscribe((postDto) => this._addPost(postDto));

      this.dialogRef.close({ text: '', images: [], document: null, isPrivate: false });
    }
  }
  public uploadImage(image: string): void {
    if (this.imageCounter >= 5) return;
    this.imageCounter++;
    this.uploadedImages$.next([...this.uploadedImages$.getValue(), image]);
  }

  public discardImage(index: number): void {
    const images = this.uploadedImages$.getValue();

    this.imageCounter--;
    this.uploadedImages$.next([...images.slice(0, index), ...images.slice(index + 1)]);
  }

  private loadUsersMentions(searchTerm: string): void {
    const loadElementsCount = 10;

    this.userService
      .searchMention(searchTerm, 0, loadElementsCount)
      .pipe(catchError((error: unknown) => throwError(() => error)))
      .subscribe(({ items }) => this._users.next([...items]));
  }

  private loadCommunitiesMentions(searchTerm: string): void {
    this.communityService
      .searchMention(searchTerm)
      .pipe(catchError((error: unknown) => throwError(() => error)))
      .subscribe((communities: CommunityMention[]) => this._communities.next([...communities]));
  }

  private renderLoading(): string {
    return `<div class="loading-spinner"></div>`;
  }

  private getMentions(
    searchTerm: string,
    renderList: (mentions: Mention[], searchQuery: string) => void,
    mentionChar: string
  ): void {
    const source$ = of(mentionChar).pipe(
      switchMap((char: string) => {
        switch (char) {
          case '@':
            this.loadUsersMentions(searchTerm);
            return this.users$;
          case '^':
            this.loadCommunitiesMentions(searchTerm);
            return this.communities$;
          default:
            return of([]);
        }
      })
    );

    source$.subscribe((items: (User | CommunityMention)[]) => {
      const mentions = items.map((item) => {
        if (mentionChar === '@') {
          const user = item as User;
          return {
            value: user.displayName.replace(/ /g, '\u00A0'),
            link: `/profile/${user.userId}`,
            avatar: user.profilePhoto ?? this.userPlaceholder,
            jobTitle: user.jobTitle ?? '',
          };
        } else {
          const community = item as CommunityMention;
          return {
            value: community.name,
            link: `/communities/${community.id}`,
            avatar: community.profilePhoto ?? this.communityPlaceholder,
          };
        }
      });
      renderList(mentions, searchTerm);
    });
  }

  private renderItem(item: Mention): string {
    const div = document.createElement('div');
    div.classList.add('mention-item');
    div.innerHTML = `
      <img src="${item.avatar}" class="mention-avatar" alt="avatar"/>
      <div class="mention-details">
        <div class="mention-value">${item.value}</div>
        ${item.jobTitle ? `<div class="mention-job-title">${item.jobTitle}</div>` : ''}
      </div>
    `;
    return div.outerHTML;
  }

  private _validateFormData(): boolean {
    if (this.characterCounter > MAX_POST_SYMBOLS) {
      this.toastService.error(`Maximum of characters allowed is ${MAX_POST_SYMBOLS}`);
      return false;
    }
    return true;
  }

  private _addPost(post: CreatePost): void {
    this.store.dispatch(addPost(post));
  }

  private _editPost(post: EditPost): void {
    this.store.dispatch(patchPost(post));
  }

  private getUrlPreview(url: string): void {
    this.urlPreviewService.getByUrl(url).subscribe((preview) => {
      if (preview !== null) {
        this.isPreviewActive = true;
        this.urlPreview = preview;
        this.cdr.detectChanges();
      }
    });
  }

  private isBase64(string: string): boolean {
    try {
      base64ToFile(string);
      return true;
    } catch {
      return false;
    }
  }

  private partition(images: string[]): { existingImages: string[]; newImages: File[] } {
    const existingImages: string[] = [];
    const newImages: File[] = [];

    images.forEach((image) =>
      !this.isBase64(image) ? existingImages.push(image) : newImages.push(this.convertor.convertToFile(image))
    );

    return { existingImages, newImages };
  }

  public addReaction(event: any): void {
    if (event != undefined) {
      const reaction = event.emoji.native as string;
      setTimeout(() => {
        this.quill.focus();
        const currentCursorPosition = this.quill.getSelection()?.index ?? 0;

        if (currentCursorPosition !== undefined) {
          this.quill.insertText(currentCursorPosition, reaction, 'user');
          this.quill.setSelection(currentCursorPosition + reaction.length, 0, 'user');
        }
      });
    }
  }

  public get uploadedImages(): Observable<string[]> {
    return this.uploadedImages$.asObservable();
  }

  public get isEdited(): boolean {
    return (
      (this.data.images !== this.uploadedImages$.getValue() ||
        this.data.documents?.length !== this.attachment.value?.length ||
        this.editor?.nativeElement.firstChild?.innerHTML !== this.data.text ||
        this.data.isPrivate !== this.isPrivateControl ||
        !this.isPreviewActive) &&
      (this.imageCounter > 0 || this.editor?.nativeElement.firstChild?.textContent.length > 0)
    );
  }

  public get postTooltip(): string {
    if (this.characterCounter > MAX_POST_SYMBOLS) {
      return 'Character limit exceeded. Please make the text shorter';
    }

    if (!this.imageCounter && !this.editor?.nativeElement.firstChild?.textContent.length) {
      return 'Post cannot be empty. Please write a message or upload an image first';
    }

    return 'No edits detected. Please make some updates first';
  }

  public get MAX_POST_SYMBOLS(): number {
    return MAX_POST_SYMBOLS;
  }

  public get characterCounter(): number {
    const matchesLength =
      this.editor?.nativeElement.firstChild?.innerHTML.match(/<[^>]+\bclass="mention"/gi)?.length ?? 0;

    return this.editor?.nativeElement.firstChild?.textContent.length
      ? String(this.editor.nativeElement.firstChild.textContent).length - matchesLength * 2
      : 0;
  }

  private get createPostDto$(): Observable<CreatePost> {
    return this.uploadedImages.pipe(
      switchMap((images) => of(images.map((image) => this.convertor.convertToFile(image)))),
      map((files) => ({
        ...this.documents,
        text: this.editor.nativeElement.firstChild.innerHTML.trim(),
        communityId: this.data.communityId,
        imageAlbum: files,
        isPrivate: this.isPrivateControl,
        urlPreviewLink: this.url,
        urlPreviewTitle: this.urlPreview?.title ?? '',
        urlPreviewDescription: this.urlPreview?.description ?? '',
        urlPreviewImage: this.urlPreview?.image ?? '',
      }))
    );
  }

  private get editPostDto$(): Observable<any> {
    return this.uploadedImages.pipe(
      map((files) => {
        const { existingImages, newImages } = this.partition(files);
        return {
          ...this.documents,
          id: this.data.id!,
          text: this.editor.nativeElement.firstChild.innerHTML
            ? this.editor.nativeElement.firstChild.innerHTML.trim()
            : '',
          communityId: this.data.communityId ?? '',
          existingImages: existingImages,
          newImages: newImages,
          isPrivate: this.isPrivateControl,
          deleteExistsDocument: !this.attachment.value?.length,
          urlPreviewLink: this.url,
          urlPreviewTitle: this.urlPreview?.title ?? '',
          urlPreviewDescription: this.urlPreview?.description ?? '',
          urlPreviewImage: this.urlPreview?.image ?? '',
        };
      })
    );
  }

  private get documents(): Record<string, unknown[]> {
    return this.attachment.value!.reduce(
      (acc: Record<string, unknown[]>, curr) => (
        curr.document ? acc['document'].push(curr.document) : acc['existingDocument'].push(curr.url), acc
      ),
      { document: [], existingDocument: [] }
    );
  }

  private get users$(): Observable<User[]> {
    return this._users.asObservable();
  }

  private get communities$(): Observable<CommunityMention[]> {
    return this._communities.asObservable();
  }
}
