import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { Router } from '@angular/router';
import { DarkModeService } from '@shared/services/dark-mode.service';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-user-name',
  standalone: true,
  templateUrl: './user-name.component.html',
  styleUrls: ['./user-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, NgClass, MatIconModule, NgIf],
})
export class UserNameComponent {
  @Input() userName = '';
  @Input() userId = '';
  @Input() disableRedirect = false;
  @Input() isContentCreator = false;
  @Input() nameSize: 'small' | 'medium' | 'large' = 'medium';
  @Output() userIdEmitter = new EventEmitter<string>();
  darkMode$ = inject(DarkModeService).isDarkMode;

  constructor(private readonly router: Router) {}

  public redirectToProfile(): void {
    if (this.isAlreadyOnProfilePage || this.disableRedirect) {
      return;
    }

    void this.router.navigate(['profile', this.userId]);
  }

  public onMouseOver(): void {
    this.userIdEmitter.emit(this.userId);
  }

  public get isAlreadyOnProfilePage(): boolean {
    return this.router.url === `/profile/${this.userId}`;
  }
}
