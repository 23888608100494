import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  Renderer2,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[contenteditable][formControlName], [contenteditable][formControl], [contenteditable][ngModel]',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContenteditableDirective),
      multi: true,
    },
  ],
})
export class ContenteditableDirective implements ControlValueAccessor, AfterViewInit, OnDestroy {
  private observer: MutationObserver = new MutationObserver(() => {
    setTimeout(() => {
      this.onChange(ContenteditableDirective.processValue(this.elementRef.nativeElement.innerHTML));
    });
  });

  /*
   * null is treated as empty string to prevent IE11 outputting 'null',
   * also single <br> is replaced with empty string when passed to the control
   */
  private static processValue(value: unknown): string {
    const processed = String(value == null ? '' : value);

    return processed.trim() === '<br>' ? '' : processed;
  }

  /*
   * onTouch callback that marks control as touched and allows FormHooks use
   */
  private onTouched = (): void => {
    return;
  };

  /*
   * onChange callback that writes value to control and allows FormHooks use
   */
  private onChange: (value: string) => void = () => {
    return;
  };

  constructor(
    @Inject(ElementRef) private readonly elementRef: ElementRef,
    @Inject(Renderer2) private readonly renderer: Renderer2
  ) {}

  /*
   * To support IE11 MutationObserver is used to monitor changes to the content
   */
  ngAfterViewInit(): void {
    this.observer.observe(this.elementRef.nativeElement, {
      characterData: true,
      childList: true,
      subtree: true,
    });

    setTimeout(() => {
      this.elementRef.nativeElement.focus();
    });
  }

  /*
   * Disconnect MutationObserver IE11 fallback on destroy
   */
  ngOnDestroy(): void {
    this.observer.disconnect();
  }

  /*
   * Listen to input events to write innerHTML value into control,
   * also disconnect MutationObserver as it is not needed if this
   * event works in current browser
   */
  @HostListener('input')
  onInput(): void {
    this.observer.disconnect();
    this.onChange(ContenteditableDirective.processValue(this.elementRef.nativeElement.innerHTML));
  }

  /*
   * Listen to blur event to mark control as touched
   */
  @HostListener('blur')
  onBlur(): void {
    this.onTouched();
  }

  /*
   * Reacts to external change
   *
   * @see {@link ControlValueAccessor#writeValue}
   */
  writeValue(value: string | null): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', ContenteditableDirective.processValue(value));
  }

  /*
   * Registers onChange callback
   *
   * @see {@link ControlValueAccessor#registerOnChange}
   */
  registerOnChange(onChange: (value: string) => void): void {
    this.onChange = onChange;
  }

  /*
   * Registers onTouch callback
   *
   * @see {@link ControlValueAccessor#registerOnTouched}
   */
  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  /*
   * Sets disabled state by setting contenteditable attribute to true/false
   *
   * @see {@link ControlValueAccessor#setDisabledState}
   */
  setDisabledState(disabled: boolean): void {
    this.renderer.setAttribute(this.elementRef.nativeElement, 'contenteditable', String(!disabled));
  }
}
